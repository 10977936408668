import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

export const OfferPage = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 850px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            {
                isDesktopOrLaptop &&
                <Container className="">
                    <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/offers_image/15August2022_end.png"} style={{ width: "400px" }} className="img-fluid pb-5" alt={"WhatsTool Tech"} /></p>
                    <div className="p-4 fixed-bottom" style={{ background: "#EE5829", display: "flex", width: "400px", margin: "auto" }}>
                        <div className="text-white fw-bold" style={{ flex: 1, fontSize: "11px" }}>
                            Sorry! You missed it.<br />
                            Today 75 people got the offer.
                        </div>
                        <div className="text-white text-uppercase" style={{ fontSize: "11px" }}>
                            Offer will be Live <br />
                            Tomorrow 14-15th Aug
                        </div>
                    </div>
                </Container>
            }
            {
                isTabletOrMobile &&
                <>
                    <Container className="ps-0 pe-0" fluid>
                        <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/offers_image/15August2022_end.png"} className="img-fluid pb-5" alt={"WhatsTool Tech"} /></p>
                        <div className="fixed-bottom p-4" style={{ background: "#EE5829", display: "flex" }}>
                            <div className="text-white fw-bold" style={{ flex: 1, fontSize: "11px" }}>
                                Sorry! You missed it.<br />
                                Today 75 people got the offer.
                            </div>
                            <div className="text-white text-uppercase" style={{ fontSize: "11px" }}>
                                Offer will be Live <br />
                                Tomorrow 14-15th Aug
                            </div>
                        </div>
                    </Container>
                </>
            }
        </>
    )
}
