import React from 'react'
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { getColour } from '../constants/Colour'
import { getUrl } from '../constants/Urls'
import { Close } from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })


// Homepage Pricing Feature input Dialog
export function Homepagepricing({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <Container className="mb-5 pb-5">
                {/* <h3 className="theme text-center mt-5 ">Pay ₹899/$10.89 to Get Desktop App</h3> */}
                {/* <p className="text-center theme mb-5">for Premium Users Only</p> */}
                <p className="theme text-center mb-2 mt-5">for Indian Users</p>
                <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                <hr />
                <div style={{ display: "none" }}>
                    <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                    <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                    <hr />
                </div>
                <Row className="mt-5">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                        <h4 style={{ color: "#635BFF" }} className="mt-2 text-center">Pay using Credit/Debit Card</h4>
                        <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/pb_stripe.png"} alt="WhatsTool Tech" width="100px" /></p>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}><p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/stripe.png"} alt="WhatsTool Tech" width="200px" /></p></Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                            <h4 className="fw-bold">WhatsTool Premium</h4>
                            <h6 className="fw-bold text-muted">Yearly</h6>
                            <Link to={{ pathname: "https://buy.stripe.com/6oE8wTfpt7ojcww4gh" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                        </div>
                    </Col>
                    <Col>
                        <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                            <h4 className="fw-bold">WhatsTool Premium</h4>
                            <h6 className="fw-bold text-muted">Lifetime</h6>
                            <Link to={{ pathname: "https://buy.stripe.com/aEUbJ50uz0ZV0NO5km" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Dialog>
    )
}
// Homepage Pricing Feature input Dialog

// Homepage APK Dialog Box
export function Apidialogbox({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }
    return (
        <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <Container style={{ marginBottom: '45px' }}>
                <hr />
                <Row className="text-center" style={{ marginTop: '45px' }}>
                    <Col>
                        <div>
                            <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: getColour.BaseColor }}>Download from APK</Link>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: getColour.BaseColor }}>Download from Playstore</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
            <hr />
        </Dialog>
    )
}
// Homepage APK Dialog Box

// WhatsApp API Dialog
export function WhatsAppAPIDialog({ open, onClose }) {
    const onCloseHere = (confirm) => { if (onClose) onClose(confirm) }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={"sm"} onClose={() => onCloseHere(false)} TransitionComponent={Transition}>
            <DialogTitle style={{ background: '#fff', color: getColour.DescriptionTextColour }}>
                <Close className="float-end hand" onClick={() => onCloseHere(false)} />
            </DialogTitle>
            <DialogContent>
                <img src={getUrl.imageBaseURL + '/conver_img.svg'} className='img-fluid' alt='Wtb' />
                <p className="ms-5 me-5 text-center" style={{ color: getColour.DescriptionTextColour, fontSize: '20px' }}>You'll receive a call from our WhatsTool Business Expert Team within 2-3 days.</p>
                <br />
            </DialogContent>
        </Dialog>
    )
}
// End of WhatsApp API Dialog

