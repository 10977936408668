import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Clients } from "../../UtilsBox/GrowthBusiness";
import Carousel from 'react-bootstrap/Carousel';
import { getString } from "../../constants/Strings";
import { getColour } from "../../constants/Colour";
import { BlogViewHome, Faq, Testimonials, WhatsToolYoutubesectionTwo } from "./HomeApp";
import { isMobile } from "react-device-detect";
import { WtEvent } from "../../basetool/Analttics";
import { getAnalyticsKey } from "../../constants/Analyticskeys";
import { Homepagepricing } from "../../basetool/Dialog";
import { getUrl } from "../../constants/Urls";


export const WtHome = () => {

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <div>
            <Container><HomeLayoutOne /></Container>
            <Clients />
            <WtHomeFive />
            <WtHomeOne />
            <WtHomeTwo />
            <WtHomeThree />
            <WtHomeFour />
            <WtHomeSix />
            <WtHomeSeven />
            <WtHomeEight />
            <WtHomeNine />
            <WhatsToolYoutubesectionTwo />
            <Container><BlogViewHome /> </Container>
            <Testimonials />
            <Faq atr_val={'all'} />
        </div>
    )
}

export const HomeLayoutOne = () => {

    const [openDialog, setOpenDialog] = useState({ open: false, onClose: null })
    const openDialogBox = () => {
        WtEvent(getAnalyticsKey.HomePlanpricingClicked)
        setOpenDialog({ open: true, onClose: () => setOpenDialog(false) })
    }

    const CarousalSchema = [{ img: getUrl.imageBaseURL + '/media/bulkmeassage_2.png', Url: '/features/bulk-whatsapp-sender-android-app', info: 'Send Bulk Message', title: 'on WhatsApp', describe: "In WhatsTool Bulk Automatic Messaging you have full control with all features of Whom you send,What you send &How you send", btn: 'Explore' }]

    return (
        <Container className="pt-4 pb-5 C_One" fluid>
            <Row className="mt-5">
                <Col className="left-view" xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <div className="main-text">
                            <h1><b>Limitless <br /> Possibilities</b><br /> <p className="for-you-on">for you on</p> <br /> <p className="blink fw-bold">WhatsApp</p></h1>
                        </div>
                        <div className="app-download mt-4">
                            <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.imageBaseURL + "/media/playstore_1.png"} className="img-fluid" width="165px" alt="Download WhatsTool" /></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link to={{ pathname: getUrl.wtdPaymentUrl }} target="_blank"><span className="hand"><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="img-fluid" width="165px" alt="Download WhatsTool " /></span></Link>
                            <div onClick={() => openDialogBox()}>
                                <img src={getUrl.bannerimgUrl} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-9 mt-2" />
                            </div>
                        </div>
                        {openDialog.open && <Homepagepricing open={openDialog.open} onClose={openDialog.onClose} />}
                    </div>
                </Col >
                <Col className="right-view" xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="fw-semibold mb-4" style={{ fontSize: '18px', color: getColour.LightTextColour }}>WhatsTool solutions can make your work faster with WhatsApp</p>
                    <div style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: '8px', padding: '30px' }}>
                        <Carousel slide={true}>
                            {
                                CarousalSchema.map((v, i) => {
                                    return (
                                        <Carousel.Item key={i}>
                                            <div>
                                                <div style={{ display: 'flex' }} className="Wthomedisplay">
                                                    <div>
                                                        <h1 style={{ color: getColour.TextColour, fontSize: isMobile ? '24px' : '' }}>{v.info}</h1>
                                                        <p style={{ color: getColour.DescriptionTextColour, marginTop: '-8px' }}>{v.title}</p>
                                                        <p style={{ color: getColour.TextColour, paddingTop: '18px' }}>{v.describe}</p>
                                                        <Link to={{ pathname: v.Url }}>
                                                            <Button style={{ background: getColour.ConnectColour, fontSize: '18px', padding: '5px 28px', marginTop: '40px', border: 'none' }} className="btn btn-sm">{v.btn}</Button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <p className="text-center">
                                                            <img src={v.img} alt="whatstool" style={{ height: '245px' }} className="img-fluid" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel>
                    </div>
                </Col>
            </Row >
        </Container >
    )
}

export const WtHomeOne = () => {
    return (
        <section style={{ padding: '42px' }} className="Wthomepadding">
            <Container>
                <div style={{ paddingTop: '28px' }}>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-center">
                                <img src={getUrl.WtamediaBaseURL + 'wtone.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                            <div>
                                <h2 style={{ paddingTop: '65px', color: getColour.TextColour }}>Direct Chat with an Unsaved Number </h2>
                                <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>WhatsTool enables users to message on WhatsApp to a new number, search profile in WhatsApp, and check the details of new/missed call numbers by searching the number directly without saving their contact.</h6>
                                <Link to={'/features/direct-chat-with-an-unsaved-number'}><h3 role="button" className="float-end" style={{ color: getColour.ConnectColour, paddingTop: '75px' }}><u>Try now</u></h3></Link>
                            </div>
                        </Col>
                    </Row >
                </div>
            </Container >
        </section>
    )
}

export const WtHomeTwo = () => {
    return (
        <section style={{ background: getColour.LightbackColour, marginTop: '24px', padding: '42px' }} className="Wthomepadding">
            <Container>
                <Row>
                    <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                        <div>
                            <h2 style={{ paddingTop: '65px', color: getColour.TextColour }} className="Wthomepaddingtop">Send Funny Messages on WhatsApp</h2>
                            <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>Check out these funny messages about friends that you use for your status or when you feel like sending your friend’s funny messages about how awesome they are.</h6>
                            <Link to={'/features/funny-messages'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '60px' }} className="Wthomepading"><u>Try now</u></h3></Link>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div className="text-center">
                            <img src={getUrl.WtamediaBaseURL + 'wttwo.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                        </div>
                    </Col>
                </Row >
            </Container >
        </section>
    )
}

export const WtHomeThree = () => {
    return (
        <section style={{ padding: '42px' }} className="Wthomepadding">
            <Container>
                <div style={{ paddingTop: '28px' }}>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-center">
                                <img src={getUrl.WtamediaBaseURL + 'wtthree.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                            <div>
                                <h2 style={{ paddingTop: '65px', color: getColour.TextColour }}>Send Awesome Caption Messages</h2>
                                <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>WhatsApp Status is a great way to express yourself. It is an expression explicitly written and in a precise way to reveal one's views, thoughts, and emotions in a creative style. WhatsApp status displays how uniquely and ingeniously you can put your thoughts in words.</h6>
                                <Link to={'/features/whatsapp-images-videos-status-downloader'}><h3 role="button" className="float-end" style={{ color: getColour.ConnectColour, paddingTop: '75px' }}><u>Try now</u></h3></Link>
                            </div>
                        </Col>
                    </Row >
                </div>
            </Container >
        </section>
    )
}

export const WtHomeFour = () => {
    return (
        <section style={{ background: getColour.LightbackColour, marginTop: '24px', padding: '42px' }} className="Wthomepadding">
            <Container>
                <Row>
                    <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                        <div>
                            <h2 style={{ paddingTop: '65px', color: getColour.TextColour }} className="Wthomepaddingtop">Send Quick Reply to Friends</h2>
                            <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>This app lets users enable Quick Reply options that allow you to message on WhatsApp by creating custom messages and use them as quick replies to save your time.</h6>
                            <Link to={'/features/bulk-whatsapp-sender-android-app'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '60px' }} className="Wthomepading"><u>Try now</u></h3></Link>
                        </div>
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div className="text-center">
                            <img src={getUrl.WtamediaBaseURL + 'wtfour.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                        </div>
                    </Col>
                </Row >
            </Container >
        </section>
    )
}

export const WtHomeFive = () => {
    return (
        <section style={{ background: getColour.LightbackColour, padding: '42px' }} className="Wthomepadding">
            <Container>
                <div style={{ paddingTop: '28px' }}>
                    <Row>
                        <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                            <div>
                                <h2 style={{ paddingTop: '65px', color: getColour.TextColour }}>Bulk Message</h2>
                                <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>Send automatic WhatsApp Bulk Messages to as many contacts you want. This helps in Business promotion and also when you want to do campaign. </h6>
                                <Link to={'/features/best-captions-for-instagram-post-and-whatsapp-status'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '75px' }}><u>Try now</u></h3></Link>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-center">
                                <img src={getUrl.WtamediaBaseURL + 'wt_five.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                            </div>
                        </Col>
                    </Row >
                </div>
            </Container >
        </section>
    )
}

export const WtHomeSix = () => {
    return (
        <section style={{ marginTop: '24px', padding: '42px' }} className="Wthomepadding">
            <Container>
                <Row>
                    <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div className="text-center">
                            <img src={getUrl.WtamediaBaseURL + 'wt_six.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                        </div>
                    </Col>
                    <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                        <div>
                            <h2 style={{ paddingTop: '65px', color: getColour.TextColour }} className="Wthomepaddingtop">Recover Deleted WhatsApp Chat</h2>
                            <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>Get notified 👀 of deleted messages in WhatsApp chat & recover them with the help of WhatsTool.</h6>
                            <Link to={'/features/recover-deleted-whatsapp-chat'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '60px' }} className="Wthomepading float-end"><u>Try now</u></h3></Link>
                        </div>
                    </Col>
                </Row >
            </Container >
        </section>
    )
}

export const WtHomeSeven = () => {
    return (
        <section style={{ background: getColour.LightbackColour, padding: '42px' }} className="Wthomepadding">
            <Container>
                <div style={{ paddingTop: '28px' }}>
                    <Row>
                        <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                            <div>
                                <h2 style={{ paddingTop: '65px', color: getColour.TextColour }}>Generate Chat Report</h2>
                                <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>With this facility, you can analyze how many conversations have taken place, which contact is‍ chatting the most.</h6>
                                <Link to={'/features/detailed-whatsapp-chat-report'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '75px' }}><u>Try now</u></h3></Link>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-center">
                                <img src={getUrl.WtamediaBaseURL + 'wt_seven.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                            </div>
                        </Col>
                    </Row >
                </div>
            </Container >
        </section>
    )
}

export const WtHomeEight = () => {
    return (
        <section style={{ marginTop: '24px', padding: '42px' }} className="Wthomepadding">
            <Container>
                <Row>
                    <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                        <div className="text-center">
                            <img src={getUrl.WtamediaBaseURL + 'wt_eight.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                        </div>
                    </Col>
                    <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                        <div>
                            <h2 style={{ paddingTop: '65px', color: getColour.TextColour }} className="Wthomepaddingtop">WhatsApp Status Saver</h2>
                            <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want.</h6>
                            <Link to={'/features/whatsapp-images-videos-status-downloader'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '60px' }} className="Wthomepading"><u>Try now</u></h3></Link>
                        </div>
                    </Col>
                </Row >
            </Container >
        </section>
    )
}

export const WtHomeNine = () => {
    return (
        <section style={{ background: getColour.LightbackColour, padding: '42px' }} className="Wthomepadding">
            <Container>
                <div style={{ paddingTop: '28px' }}>
                    <Row>
                        <Col xxl={9} xl={9} lg={9} md={6} sm={12} xs={12}>
                            <div>
                                <h2 style={{ paddingTop: '65px', color: getColour.TextColour }}>Video Spliter</h2>
                                <h6 style={{ paddingTop: '18px', color: getColour.DescriptionTextColour, fontSize: '18px' }}>This inbuilt tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses.</h6>
                                <Link to={'/features/whatsapp-status-video-spliter'}><h3 role="button" style={{ color: getColour.ConnectColour, paddingTop: '75px' }}><u>Try now</u></h3></Link>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                            <div className="text-center">
                                <img src={getUrl.WtamediaBaseURL + 'wt_nine.png'} style={{ height: '353px' }} alt={getString.SiteName} className="img-fluid" />
                            </div>
                        </Col>
                    </Row >
                </div>
            </Container >
        </section>
    )
}





