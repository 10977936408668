import React from "react";
import {  Snackbar, IconButton, Typography, Slide } from '@mui/material'
import { getUrl } from "../constants/Urls";
import { Close } from "@mui/icons-material";


function TransitionDown(props) { return <Slide {...props} direction="down" /> }
export const ShowSnackbar = ({ open, errorMsg, errorMsgBg, close }) => {
    return (
        <Snackbar open={open} autoHideDuration={2000} TransitionComponent={TransitionDown} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={close}>
            <div className="text-white ps-5 pt-2 pb-1" style={{ background: errorMsgBg, display: "flex", borderRadius: "6px" }} >
                <img src={getUrl.imageBaseUrl + "/wtb_green_round_images.png"} style={{ width: "24px", height: '24px', marginLeft: "-36px", marginTop: "3px", borderRadius: '50%' }} alt='wtb' />
                <Typography style={{ marginTop: "3px" }} className="ms-3">{errorMsg}</Typography>
                <IconButton className="ms-5 me-2" size="small" aria-label="close" color="inherit" onClick={close}><Close fontSize="small" /></IconButton>
            </div>
        </Snackbar>
    )
}