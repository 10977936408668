import React, { useState } from 'react'
import { Container, Row, Col, Navbar, Nav, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { getUrl } from '../constants/Urls';

export const Header = () => {
    return (
        <>

            <Navbar style={{ background: '#128C7E' }} expand="lg">
                <Container>
                    <Navbar.Brand><Link to="/"><img src={getUrl.WtamediaBaseURL + 'whatstool_logo.png'} style={{ height: '40px' }} alt="WT" className='img-fluid' />&nbsp;&nbsp;<img alt="WhatsToolX" src={getUrl.WtamediaBaseURL + 'wt_logo.png'} width="200px" className="img-fluid" /></Link></Navbar.Brand>
                    <Nav>
                        <Link to={"/premium"}><p style={{ color: '#fff' }} ><LocalOfferOutlinedIcon style={{ color: '#00FFFF', fontSize: '18px' }} />&nbsp;Plans & Pricing</p></Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}

export const Footer = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Container className="footer" fluid>
            <Container className="in p-5">
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4">Features</h5>
                        <div style={{ lineHeight: "2" }}>
                            <p><Link to={"/features/bulk-whatsapp-sender-android-app"} className="text-muted">Bulk WhatsApp Message</Link></p>
                            <p><Link to={"/features/direct-chat-with-an-unsaved-number"} className="text-muted">Direct Chat</Link></p>
                            <p><Link to={"/features/whatsapp-images-videos-status-downloader"} className="text-muted">Status Saver</Link></p>
                            <p><Link to={"/features/quick-reply"} className="text-muted">Quick Reply</Link></p>
                            <p><Link to={"/premium"} className="text-muted">WhatsTool Premium Pricing</Link></p>
                            <p><Link to={"/wt-payment-method"} className="text-muted">Payment Methods</Link></p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4">About</h5>
                        <div style={{ lineHeight: "2" }}>
                            <p><Link to={"/privacy-policy"} className="text-dark">Privacy Policy</Link></p>
                            <p><Link to={"/refund-policy"} className="text-dark">Refund Policy</Link></p>
                            <p><Link to={"/team"} className="text-dark">Team</Link></p>
                            <p><Link to={{ pathname: "https://blog.whatstool.in" }} target="_blank" className="text-dark">Blog</Link></p>
                            <p><Link to={"/our-happy-clients"} className="text-dark">Clients</Link></p>
                            <p><Link to={"/faq"} className="text-dark">FAQs</Link></p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                        <h5 className="fw-bold mb-3 mt-4">Download</h5>
                        <div style={{ display: 'grid' }}>
                            <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.imageBaseURL + "/media/playstore_1.png"} className="mb-2 shadow-lg dImg" width="145px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link>
                            <Link onClick={handleShow}><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="mb-2 shadow-lg dImg" width="145px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link>

                        </div>
                        <Modal className="modal-info-bulk-msg" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Body>
                                <Container className="mt-3 mb-3 pt-5 pb-5 ps-3 pe-3">
                                    <h4 className="text-center">If you don't have the License of <span className="theme">WhatsTool Desktop</span>,<br /> then apply for FREE TRIAL</h4><br /><br />
                                    <p className="text-center">
                                        <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSdIvVDtNyEecMC2smVNLncklwHQZCjT2BDT1COdFnL-27dDDQ/viewform?usp=sf_link" }} target="_blank" className="wbtn">Get FREE TRIAL <i className="fas fa-desktop"></i></Link>&nbsp;&nbsp;&nbsp;
                                        <Link to={"/premium"} className="wbtn">Buy Now <i className="fas fa-shopping-bag"></i></Link>
                                    </p><br /><br />
                                    <p className="text-center mb-4"><Link to={{ pathname: "https://whatstool.in/app/DesktopApp/WhatsToolSetup.msi" }} target="_blank" className="bulk-wbtn pt-3 pb-3 pe-4 ps-4" download><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></Link></p>
                                    <h5 className="text-center mt-5 mb-5">How to Use: <Link to={{ pathname: "https://youtu.be/UIpowWU9A9g" }} target="_blank">Watch Video</Link></h5>
                                    <p style={{ fontSize: "12px" }}><b>*Important:</b> One Email ID for 1 Device (is allowed).</p>
                                    <p style={{ fontSize: "12px" }}><b>*Instruction:</b> Extract the Zip File and Click the Icon to Open it. <br /><b>Preview:</b> How to Open the WhatsTool Desktop.</p>
                                    <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/instruction.png"} alt="WhatsTool Tech" className="img-fluid mt-3 shadow-lg" width="60%" /></p>
                                </Container>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>
            </Container>
            <hr style={{ backgroundColor: "#ccc", height: "1px" }} />
            <Container className="pt-2 pe-5 ps-5 in social">
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}><p className="float-start fw-bold" style={{ fontSize: "14px" }}>© WhatsTool Tech {new Date()?.getFullYear()}</p></Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                        <p className="float-end">
                            <Link to={{ pathname: "https://www.facebook.com/whatstooltech" }} target="_blank"><i className="fab fa-facebook-f fb" style={{ paddingLeft: "12px", paddingRight: "12px" }}></i></Link>
                            <Link to={{ pathname: "https://www.instagram.com/whatstool/" }} target="_blank"><i className="fab fa-instagram"></i></Link>
                            <Link to={{ pathname: "https://twitter.com/ToolsWhats" }} target="_blank"><i className="fab fa-twitter"></i></Link>
                            <Link to={{ pathname: "https://www.linkedin.com/company/whatstool" }} target="_blank"><i className="fab fa-linkedin-in"></i></Link>
                            <Link to={{ pathname: "https://www.youtube.com/c/WhatsToolTech" }} target="_blank"><i className="fab fa-youtube"></i></Link>
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container className="pb-2">&nbsp;</Container>
        </Container>
    )
}