class WtAnalytics {
    constructor() {

        this.HomePlanpricingClicked = 'Home buttons Price Clicked'
        this.WhatsAppAPIClicked = 'WhatsApp API - Request Button Clicked'


    }
}

export const getAnalyticsKey = new WtAnalytics()