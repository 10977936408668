import { getColour } from '../../constants/Colour'
import { getUrl } from '../../constants/Urls'

export const DownloadApp = () => {
    return (
        <section style={{ padding: '80px 8px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ background: '#fafafa', borderRadius: '10px', padding: '20px' }}>
                    <p className="text-center blink_me" style={{ color: 'red', fontSize: '18px' }}>How to Install?</p>
                    <p className="text-center" style={{ color: 'red' }}>Please uninstall the existing apk and then install this new apk of WhatsTool.</p>
                </div>
            </div>
            <p className='text-center' style={{ fontSize: '24px', color: getColour.BaseColor }}>Send Bulk WhatsApp Message</p>
            <p className='text-center' style={{ fontSize: '20px' }}>Download the Latest Version 4.1.5</p>
            <div style={{ padding: '30px 10px' }}>
                <p className="text-center">
                    <a className='btn btn-sm' href={getUrl.appDirectDownloadUrl} download style={{ color: '#fff', background: getColour.BaseColor, padding: '10px 60px', fontSize: '20px', borderRadius: '4px', whiteSpace: 'nowrap' }}>Download APK v.4.1.5</a>
                </p>
            </div>
            <p className="text-center" style={{ fontSize: '20px' }}>Install this Apk & start utilizing the bulk whatsapp messaging</p>
        </section>
    )
}