import React, { useState, useEffect } from 'react'
import './App.css'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Header, Footer } from './UtilsBox/UtilsTemplate'
import { Pricing } from './UtilsBox/UtilsHome'
import { FeatureHome, DirectChat, FunnyMessage, CaptionMsg, QuickReply, BulkMessaging, RecoverChat, ChatReport, StatusSaver, VideoSpliter, LiveChatSupport, AIChatBots, AutomateNotification, CRM, DesktopApp } from './UtilsBox/UtilsFeatures'
import { About, Clients, FAQ, Faq, Gallery, OurApps, Premium, PrivacyPolicy, RefundPolicy, TermsNCondition, ResellerProgram, Team, UpgradeInfo, Faq11, SipCalc, EmiCalc, InstagramTool, RetirmentPlan, PaymentMethod, DesktopLicenseKey, PaymentSuccessful } from './UtilsBox/UtilsStatic'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { MoreTools } from './UtilsBox/UtilsCommon'
import { BulkMsgModal } from './UtilsBox/UtilsCommon'
import ReactGA from 'react-ga'
import { OfferPage } from './view/Offers/ui'
import { WTGrowthBusiness } from './UtilsBox/GrowthBusiness'
import { WtDesktop } from './app/App/WtDesktop'
import { WtHome } from './app/App/WtHome'
import { HomeApp } from './app/App/HomeApp'
import { getUrl } from './constants/Urls'
import { DownloadApp } from './view/download-app/DownloadApp'
import { WhatsappAPI } from './app/App/WhatsappApi'

function App() {
  const siteName = "WhatsTool"
  const siteFullName = "WhatsTool Tech - Limitless Possibilities"
  const siteImgUrl = "https://whatstool.in/data_whatstool_web/navlogo.png"
  const address = "C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India."
  const pincode = "Pincode: 560017"
  const whatstoolAppLink = getUrl.appDirectDownloadUrl
  const whatssaveAppLink = "https://play.google.com/store/apps/details?id=com.whatstool.whatssave.exportcontact.autosave"
  const ebillAppLink = "https://play.google.com/store/apps/details?id=in.everybill.business"

  const siteDesc = "We are on Mission to make Limitless Possibilities for you on WhatsApp & Business. WhatsTool features of status saver, WhatsApp direct chat, WhatsApp bulk messaging, etc."
  const siteKeyword = "direct chat with unsaved numbers, automatic bulk messaging, bulk messaging on whatsapp, automatic bulk messaging on whatsapp, send funny messages, send funny messages on whatsapp, whatsapp funny messages"
  const commonKeyword = "whatstool tech, toolkit for whatsapp, whatsapp toolkit, best toolkit app for whatsapp, Limitless Possibilities, Limitless Possibilities for you on whatsapp"

  const GPSIcon_img = getUrl.imageBaseURL + "/media/playstore_1.png"


  const [pageURL, setPageURL] = useState()
  useEffect(() => {
    ReactGA.initialize('G-0KBJGD9F6B')
    ReactGA.pageview(window.location.href + window.location.search)
    console.log('pageURL==', pageURL)
    setPageURL(window.location.href)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

    // setOfferModal(true)
  }, [pageURL])


  const pageWithNoHeaderFooter = [
    '/reseller-program',
    '/payment-successful',
    '/offer/15-August-2022',
    '/growth-business',
    '/whatsapp-api'
  ]
  const hideHeaderFooter = () => {
    let page = pageWithNoHeaderFooter?.filter((v) => window.location.href?.includes(v))?.map((v) => v)?.length > 0 ? true : false
    if (page) return false
    return true
  }

  return (
    <>
      <Router>
        {/* Offer Modal */}
        {/* <OfferModal show={offerModal} GPSIcon_img={GPSIcon_img} whatstoolAppLink={whatstoolAppLink} siteName={siteName} onHide={() => setOfferModal(false)} /> */}
        {/* End of Offer Modal */}

        <Route exact path="/growth-business">
          <WTGrowthBusiness />
        </Route>
        {
          hideHeaderFooter() &&
          <Header siteImgUrl={siteImgUrl} siteName={siteName} whatstoolAppLink={whatstoolAppLink} GPSIcon_img={GPSIcon_img}
            ourApp="App Store" ourAppUrl="/apps" ourAppUrlType="p"
            blog="Blog" blogUrl="https://whatstool.in/blog/" blogUrlType="d"
            clients="Clients" clientsUrl="/our-happy-clients" clientsUrlType="p"
            features="Features" featuresUrl="/features" featuresUrltype="p"
            gallery="Gallery" galleryUrl="/gallery" galleryUrlType="p"
            pricing="WhatsTool PRO" pricingUrl="/premium" pricingUrlType="p"
            reseller="Reseller Program" resellerUrl="/reseller-program" resellerUrlType="p"
            team="Team" teamUrl="/team" teamUrlType="p" />

        }
        <Switch>
          <Route exact path="/">
            <Helmet
              title={siteFullName}
              meta={[
                { "property": "og:title", "content": siteFullName },
                { "property": "twitter:title", "content": siteFullName },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": siteDesc },
                { "property": "og:description", "content": siteDesc },
                { "name": "twitter:description", "content": siteDesc },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${pageURL}",
                "logo": "${siteImgUrl}"
              }`
              }]}
            />
            {/* <Container>
              <HomeLayoutOne GPSIcon_img={GPSIcon_img} whatstoolAppLink={whatstoolAppLink} chat_bot={chat_bot} notification={notification} crm={crm} bulk_img={bulk_img} chat_img={chat_img} reply_img={reply_img} />
            </Container> 
             <hr /> */}
            <HomeApp />
          </Route>


          <Route exact path="/wt-new-app">
            <Helmet
              title={'Download WhatsTool App'}
              meta={[
                { "property": "og:title", "content": 'Download WhatsTool App' },
                { "property": "twitter:title", "content": 'Download WhatsTool App' },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": siteDesc },
                { "property": "og:description", "content": siteDesc },
                { "name": "twitter:description", "content": siteDesc },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${pageURL}",
                "logo": "${siteImgUrl}"
              }`
              }]}
            />
            <DownloadApp />
          </Route>


          <Route exact path="/offer/15-August-2022">
            <OfferPage />
          </Route>

          <Route exact path="/wthome-App">
            <WtHome />
          </Route>

          <Route exact path="/desktop-App">
            <WtDesktop />
          </Route>

          <Route exact path={getUrl.WhatsAppapiURL}><WhatsappAPI /></Route>

          <Route exact path="/pricing">
            <Helmet
              title={siteFullName}
              meta={[
                { "property": "og:title", "content": siteFullName },
                { "property": "twitter:title", "content": siteFullName },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": siteDesc },
                { "property": "og:description", "content": siteDesc },
                { "name": "twitter:description", "content": siteDesc },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${pageURL}",
                "logo": "${siteImgUrl}"
              }`
              }]}
            />
            <Pricing />
          </Route>
          <Route exact path="/features/bulk-whatsapp-sender-android-app">
            <Helmet
              title={"Automatic Bulk Messaging - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Automatic Bulk Messaging - " + siteFullName },
                { "property": "twitter:title", "content": "Automatic Bulk Messaging - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Automatic Bulk Messaging - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <BulkMessaging />
            <MoreTools atr_val="bulk_msg" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
              <div className="features-faq mt-5"><h2>FAQ</h2><Faq atr_val="bulk_msg" /></div>
            </Container>
            <Container className="features-page bulk-message mb-5 pb-5 ps-4 pe-4">
              <BulkMsgModal />
            </Container>
          </Route>
          <Route exact path="/features/whatstool-desktop-app">
            <Helmet
              title={"WhatsTool Desktop App - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "WhatsTool Desktop App - " + siteFullName },
                { "property": "twitter:title", "content": "WhatsTool Desktop App - " + siteFullName },
                { "name": "keywords", "content": "WhatsTool Desktop App, desktop app, whatstool desktop, bulk messaging, WhatsTool Desktop App, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "WhatsTool Desktop App. Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "WhatsTool Desktop App. Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "WhatsTool Desktop App. Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "WhatsTool Desktop App - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <DesktopApp />
            <MoreTools atr_val="bulk_msg" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
              <div className="features-faq mt-5"><h2>FAQ</h2><Faq atr_val="bulk_msg" /></div>
            </Container>
            <Container className="features-page bulk-message mb-5 pb-5 ps-4 pe-4">
              <BulkMsgModal />
            </Container>
          </Route>
          <Route exact path="/features/live-chat-support">
            <Helmet
              title={"Live Chat Support - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Live Chat Support - " + siteFullName },
                { "property": "twitter:title", "content": "Live Chat Support - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Live Chat Support - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <LiveChatSupport />
          </Route>
          <Route exact path="/features/ai-chatbots">
            <Helmet
              title={"A.I. Chatbots - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "A.I. Chatbots - " + siteFullName },
                { "property": "twitter:title", "content": "A.I. Chatbots - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "A.I. Chatbots - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <AIChatBots />
          </Route>
          <Route exact path="/features/automate-notification">
            <Helmet
              title={"Automate Notification - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Automate Notification - " + siteFullName },
                { "property": "twitter:title", "content": "Automate Notification - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Automate Notification - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <AutomateNotification />
          </Route>
          <Route exact path="/features/in-built-crm">
            <Helmet
              title={"In-Built CRM - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "In-Built CRM - " + siteFullName },
                { "property": "twitter:title", "content": "In-Built CRM - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "In-Built CRM - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <CRM />
          </Route>
          <Route exact path="/how-to-get-activate-whatstool-desktop">
            <Helmet
              title={"Get WhatsTool Desktop Licence Key - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Get WhatsTool Desktop Licence Key - " + siteFullName },
                { "property": "twitter:title", "content": "Get WhatsTool Desktop Licence Key - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Get WhatsTool Desktop Licence Key - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <DesktopLicenseKey />
          </Route>
          <Route exact path="/payment-successful">
            <Helmet
              title={"Payment Successful - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Payment Successful - " + siteFullName },
                { "property": "twitter:title", "content": "Payment Successful - " + siteFullName },
                { "name": "keywords", "content": "bulk messaging, automatic bulk messaging, automatic bulk message, automatic bulk whatsapp messaging, automatic bulk whatsapp messages, automatic bulk messaging on whatsapp, automatic bulk messages on whatsapp, bulk messaging on whatsapp, send automatic bulk messages, free automatic bulk messages, best automatic bulk messaging, best app for automatic bulk messaging, top apps for automatic bulk messaging, whatsapp bulk sender, bulk sender in whatsapp, best whatsapp bulk messaging app, best free apps for bulk messaging on whatsapp, send free unlimited whatsapp bulk message from excel, bulk whatsapp sender android app, whatsapp business api alternative, whatsapp business api alternative hack" },
                { "name": "description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "name": "twitter:description", "content": "Bulk WhatsApp messaging can help business and users who are looking to send important message to their contacts by reducing the time. It is like Unlimited automatic forwarding." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Payment Successful - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <PaymentSuccessful />
          </Route>


          <Route exact path="/wt-payment-method">
            <Helmet
              title={"Payment Method"}
              meta={[
                { "property": "og:title", "content": "Payment Method" },
                { "property": "twitter:title", "content": "Payment Method" },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": siteDesc },
                { "property": "og:description", "content": siteDesc },
                { "name": "twitter:description", "content": siteDesc },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "${pageURL}",
                "logo": "${siteImgUrl}"
              }`
              }]}
            />
            <PaymentMethod />
          </Route>
          <Route exact path="/features">
            <Helmet
              title={"Features - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Features - " + siteFullName },
                { "property": "twitter:title", "content": "Features - " + siteFullName },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": siteDesc },
                { "property": "og:description", "content": siteDesc },
                { "name": "twitter:description", "content": siteDesc },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Features - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/text.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/caption.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/chat.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/reply.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report.png",
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <FeatureHome />
          </Route>
          <Route exact path="/features/direct-chat-with-an-unsaved-number">
            <Helmet
              title={"Direct Chat with Unsaved Numbers - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Direct Chat with Unsaved Numbers - " + siteFullName },
                { "property": "twitter:title", "content": "Direct Chat with Unsaved Numbers - " + siteFullName },
                { "name": "keywords", "content": "direct chat with unsaved numbers, direct chat, how to send message unsaved number without adding contact whatsapp,direct message on whatsapp without adding contact, Direct chat without saving number, direct chat on whatsapp, whatsapp without saving number,how to call on whatsapp without saving number,send a whatsapp message,how do i send a message to a new contact on whatsapp, direct message on whatsapp" },
                { "name": "description", "content": "Direct Chat with an unsaved number: WhatsTool enables users to message on WhatsApp to a new number, search profile in WhatsApp, and check the details of new/missed call numbers by searching the number directly without saving their contact." },
                { "property": "og:description", "content": "Direct Chat with an unsaved number: WhatsTool enables users to message on WhatsApp to a new number, search profile in WhatsApp, and check the details of new/missed call numbers by searching the number directly without saving their contact." },
                { "name": "twitter:description", "content": "Direct Chat with an unsaved number: WhatsTool enables users to message on WhatsApp to a new number, search profile in WhatsApp, and check the details of new/missed call numbers by searching the number directly without saving their contact." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Direct Chat with Unsaved Numbers - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/chat.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <DirectChat />
            <MoreTools atr_val="direct_chat" />
          </Route>
          <Route exact path="/features/funny-messages">
            <Helmet
              title={"Send Funny Messages - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Send Funny Messages - " + siteFullName },
                { "property": "twitter:title", "content": "Send Funny Messages - " + siteFullName },
                { "name": "keywords", "content": "best top funny whatsapp messages of 2021, send funny messages, send funny messages on whatsapp, whatsapp funny messages, WhatsApp, funny, sms, funny message, prank message, prank" },
                { "name": "description", "content": "Check out these funny messages about friends that you use for your status or when you feel like sending your friend’s funny messages about how awesome they are." },
                { "property": "og:description", "content": "Check out these funny messages about friends that you use for your status or when you feel like sending your friend’s funny messages about how awesome they are." },
                { "name": "twitter:description", "content": "Check out these funny messages about friends that you use for your status or when you feel like sending your friend’s funny messages about how awesome they are." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Send Funny Messages - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/text.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <FunnyMessage />
            <MoreTools atr_val="funny_message" />
          </Route>
          <Route exact path="/features/best-captions-for-instagram-post-and-whatsapp-status">
            <CaptionMsg siteFullName={siteFullName} pageURL={pageURL} />
            <MoreTools atr_val="caption" />
          </Route>
          <Route exact path="/features/quick-reply">
            <Helmet
              title={"Quick Reply to Friends - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Quick Reply to Friends - " + siteFullName },
                { "property": "twitter:title", "content": "Quick Reply to Friends - " + siteFullName },
                { "name": "keywords", "content": "send quick reply on whatspp, chat on whatsapp using quick reply, add your own quick reply on whatsapp, best quick reply messages for whatsapp business" },
                { "name": "description", "content": "This app lets users enable Quick Reply options that allow you to message on WhatsApp by creating custom messages and use them as quick replies to save your time." },
                { "property": "og:description", "content": "This app lets users enable Quick Reply options that allow you to message on WhatsApp by creating custom messages and use them as quick replies to save your time." },
                { "name": "twitter:description", "content": "This app lets users enable Quick Reply options that allow you to message on WhatsApp by creating custom messages and use them as quick replies to save your time." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Quick Reply to Friends - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/reply.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <QuickReply />
            <MoreTools atr_val="quick_reply" />
          </Route>

          <Route exact path="/features/recover-deleted-whatsapp-chat">
            <Helmet
              title={"Recover Deleted Messages - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Recover Deleted Messages - " + siteFullName },
                { "property": "twitter:title", "content": "Recover Deleted Messages - " + siteFullName },
                { "name": "keywords", "content": "how to restore deleted whatsapp messages, WhatsApp deleted messages, Recover Data, WhatsApp Tricks, WhatsApp backup, Google Play Store, WhatsApp chats deleted, WhatsApp chats, whatsapp, whatsapp news, whatsapp deleted messages, whatsapp tech tips, whatsapp deleted messages recovery app, how to do whatsapp backup, how to get back deleted messages, how to recover deleted messages on whatsapp, how to recover old messages on whatsapp" },
                { "name": "description", "content": "Get notified of deleted messages in WhatsApp chat &amp; recover them with the help of WhatsTool." },
                { "property": "og:description", "content": "Get notified of deleted messages in WhatsApp chat &amp; recover them with the help of WhatsTool." },
                { "name": "twitter:description", "content": "Get notified of deleted messages in WhatsApp chat &amp; recover them with the help of WhatsTool." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Recover Deleted Messages - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <RecoverChat />
            <MoreTools atr_val="recover_chat" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
            </Container>
          </Route>
          <Route exact path="/features/detailed-whatsapp-chat-report">
            <Helmet
              title={"Generate Whatsapp Chat Report - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Generate Whatsapp Chat Report - " + siteFullName },
                { "property": "twitter:title", "content": "Generate Whatsapp Chat Report - " + siteFullName },
                { "name": "keywords", "content": "generate report of whatsapp chat, detailed whatsapp chat report, how to generate whatsapp chat report, whatsapp chat report, whatsapp trick, chat report on whatsapp" },
                { "name": "description", "content": "With this facility, you can analyze how many conversations have taken place, which contact is chatting the most." },
                { "property": "og:description", "content": "With this facility, you can analyze how many conversations have taken place, which contact is chatting the most." },
                { "name": "twitter:description", "content": "With this facility, you can analyze how many conversations have taken place, which contact is chatting the most." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Generate Chat Report - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <ChatReport />
            <MoreTools atr_val="chat_report" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
            </Container>
          </Route>
          <Route exact path="/features/whatsapp-images-videos-status-downloader">
            <Helmet
              title={"Photo, Video Status Saver - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Photo, Video Status Saver - " + siteFullName },
                { "property": "twitter:title", "content": "Photo, Video Status Saver - " + siteFullName },
                { "name": "keywords", "content": "How to save WhatsApp Status of your friends, download whatsapp status, whatsapp status saver, save whatsapp status, video status saver, WhatsApp, WhatsApp update, WhatsApp tips, WhatsApp tricks, WhatsApp status, WhatsApp features" },
                { "name": "description", "content": "This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want." },
                { "property": "og:description", "content": "This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want." },
                { "name": "twitter:description", "content": "This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Photo, Video Status Saver - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <StatusSaver />
            <MoreTools atr_val="status_download" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
            </Container>
          </Route>
          <Route exact path="/features/whatsapp-status-video-spliter">
            <Helmet
              title={"Video Spliter - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Video Spliter - " + siteFullName },
                { "property": "twitter:title", "content": "Video Spliter - " + siteFullName },
                { "name": "keywords", "content": "create whatsapp status, edit status for whatsapp, WhatsApp Status, How to set WhatsApp Status, Delete WhatsApp Status, WhatsApp Status Privacy Settings, WhatsApp, For those who are struggling with updating and editing their WhatsApp status" },
                { "name": "description", "content": "For those who are struggling with updating and editing their WhatsApp status. This inbuilt tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses." },
                { "property": "og:description", "content": "For those who are struggling with updating and editing their WhatsApp status. This inbuilt tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses." },
                { "name": "twitter:description", "content": "For those who are struggling with updating and editing their WhatsApp status. This inbuilt tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${pageURL}"
                },
                "headline": "Video Spliter - ${siteFullName}",
                "image": [
                  "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor.png"
                ],
                "publisher": {
                  "@type": "Organization",
                  "name": "${siteFullName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${siteImgUrl}"
                  }
                }
              }`
              }]}
            />
            <VideoSpliter />
            <MoreTools atr_val="video_spliter" />
            <Container className="mb-5 pb-5">
              {/* <div className="testimonials mt-5"><h2>Testimonials</h2><Testimonials /></div> */}
            </Container>
          </Route>

          <Route exact path="/apps">
            <Helmet
              title={"App Store - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "App Store - " + siteFullName },
                { "property": "twitter:title", "content": "App Store - " + siteFullName },
                { "name": "keywords", "content": commonKeyword + ", " + siteKeyword },
                { "name": "description", "content": "WhatsTool App Store. Search and download apps for bussiness, productivity, social networking, fun, sales and more from the WhatsTool Tech." },
                { "property": "og:description", "content": "WhatsTool App Store. Search and download apps for bussiness, productivity, social networking, fun, sales and more from the WhatsTool Tech." },
                { "name": "twitter:description", "content": "WhatsTool App Store. Search and download apps for bussiness, productivity, social networking, fun, sales and more from the WhatsTool Tech." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "WhatsTool: Toolkit for WhatsApp",
                "operatingSystem": "ANDROID",
                "applicationCategory": "UtilitiesApplication",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.2",
                  "ratingCount": "5775"
                },
                "review": [
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Naveena N"
                    },
                    "reviewBody": "Nice app... Out of curiosity i have tested the recovery deleted message option.. Message are updated only for the messages which are delivered to the other person (double tick) but on the other hand if message doesn't deliver and deleted (single tick) it failed to update in their app... Overall nice one... Keep up the good work!"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Kaushal Shethia"
                    },
                    "reviewBody": "App has been constantly updated with better features. This has been one of the plus point to use the app. Certainly made the task easier."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Sttewon Auston"
                    },
                    "reviewBody": "Chat without saving number much better and interesting features application so I like to use this app."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rishitha R K"
                    },
                    "reviewBody": "Good app.. most importantly you get a good support from the team."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Olivia _ Addison"
                    },
                    "reviewBody": "WhatsTool this is the best working app whatsapp video status saver is amazingly designed and amazingly easy to use and very helpful app."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Oyetade Segun John"
                    },
                    "reviewBody": "Before:The update has TOOOOO MANY bugs . Pls fix it. I can't send bulk message at all NOW: The app is now THE BEST. KEEP IT UP"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Shri Karthikeya Financiers"
                    },
                    "reviewBody": "Excellent App to know how to got pics and videos"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Abhishek Anand"
                    },
                    "reviewBody": "Fantastic for bulk messages... Constantly improving on features smoothness"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Shweta Kumari"
                    },
                    "reviewBody": "Fantastic and very smooth app for status downloder, funny messages the best part of app i like is whatsapp chat report"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Vikrant Khandale"
                    },
                    "reviewBody": "It's an really useful aap also have so many features like chat without savimg no Recover delete msgs nd also use for whats aap status saver"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Deeraj Kumar"
                    },
                    "reviewBody": "This is the best app What's tool toolkit for.. is the lovely work successful application app the very good processing aap"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Soumya Soumya"
                    },
                    "reviewBody": "This app is very important to me....Every one download this app...Every one use this app.... Chat without saving Number"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "White polarbear"
                    },
                    "reviewBody": "Chat without saving number functions enavled in this smart app is working so much nice way so keep.your focus on the app working."
                  },
                ]
              }`
              }]}
            />
            <OurApps />
          </Route>

          <Route exact path="/our-happy-clients">
            <Helmet
              title={"Our Happy Clients - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Our Happy Clients - " + siteFullName },
                { "property": "twitter:title", "content": "Our Happy Clients - " + siteFullName },
                { "name": "keywords", "content": "clients, our happy clients, whatstool's clients, clients accross the world, happy customers of whatstool,  " + commonKeyword },
                { "name": "description", "content": "Happy Clients and lovely users of WhatsTool Tech." },
                { "property": "og:description", "content": "Happy Clients and lovely users of WhatsTool Tech." },
                { "name": "twitter:description", "content": "Happy Clients and lovely users of WhatsTool Tech." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <Clients />
          </Route>

          <Route exact path="/premium">
            <Helmet
              title={"WhatsTool PRO Pricing - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "WhatsTool PRO Pricing - " + siteFullName },
                { "property": "twitter:title", "content": "WhatsTool PRO Pricing - " + siteFullName },
                { "name": "keywords", "content": "WhatsTool PRO Pricing, whatstool premium, whatstool tech premium, premium whatstool, whatstool plan, whatstool pricing, premium pricing of whatstool, plan of whatstool" },
                { "name": "description", "content": "WhatsTool PRO Pricing. WhatsTool Tech provides a Bulk Manual Sender, Bulk Automatic Sender, Advance Chat Report, WhatsApp Anti-Ban, Unlimited Groups, Add Files in Bulk Sending, Import Contacts and many more." },
                { "property": "og:description", "content": "WhatsTool PRO Pricing. WhatsTool Tech provides a Bulk Manual Sender, Bulk Automatic Sender, Advance Chat Report, WhatsApp Anti-Ban, Unlimited Groups, Add Files in Bulk Sending, Import Contacts and many more." },
                { "name": "twitter:description", "content": "WhatsTool PRO Pricing. WhatsTool Tech provides a Bulk Manual Sender, Bulk Automatic Sender, Advance Chat Report, WhatsApp Anti-Ban, Unlimited Groups, Add Files in Bulk Sending, Import Contacts and many more." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <Premium />
          </Route>
          <Route exact path="/reseller-program">
            <Helmet
              title={"Reseller Program - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Reseller Program - " + siteFullName },
                { "property": "twitter:title", "content": "Reseller Program - " + siteFullName },
                { "name": "keywords", "content": "reseller program, reseller, reseller program of whatstool, whatstool reseller program" },
                { "name": "description", "content": "Reseller Program of WhatsTool App. Register yourself to become a Reseller of our WhatsTool App." },
                { "property": "og:description", "content": "Reseller Program of WhatsTool App. Register yourself to become a Reseller of our WhatsTool App." },
                { "name": "twitter:description", "content": "Reseller Program of WhatsTool App. Register yourself to become a Reseller of our WhatsTool App." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <ResellerProgram />
          </Route>
          <Route exact path="/team">
            <Helmet
              title={"Team - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Team - " + siteFullName },
                { "property": "twitter:title", "content": "Team - " + siteFullName },
                { "name": "keywords", "content": "whatstool team, leadership team of whatstool tech, whatstool tech team, team of whatstool tech" },
                { "name": "description", "content": "WhatsTool Tech's leadership team brings global perspective, deep industry experience and passion for innovation to their roles. View the team's profiles." },
                { "property": "og:description", "content": "WhatsTool Tech's leadership team brings global perspective, deep industry experience and passion for innovation to their roles. View the team's profiles." },
                { "name": "twitter:description", "content": "WhatsTool Tech's leadership team brings global perspective, deep industry experience and passion for innovation to their roles. View the team's profiles." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <Team />
          </Route>
          <Route exact path="/gallery">
            <Helmet
              title={"Gallery - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Gallery - " + siteFullName },
                { "property": "twitter:title", "content": "Gallery - " + siteFullName },
                { "name": "keywords", "content": "whatstool gallery, whatstool tech gallery gallery of whatstool, gallery of whatstool tech, video gallery of whatstool, photo gallery of whatstool" },
                { "name": "description", "content": "Photos and videos of our office and team members of WhatsTool Tech." },
                { "property": "og:description", "content": "Photos and videos of our office and team members of WhatsTool Tech." },
                { "name": "twitter:description", "content": "Photos and videos of our office and team members of WhatsTool Tech." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <Gallery />
          </Route>

          <Route exact path="/privacy-policy">
            <Helmet
              title={"Privacy Policy - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Privacy Policy - " + siteFullName },
                { "property": "twitter:title", "content": "Privacy Policy - " + siteFullName },
                { "name": "keywords", "content": "privacy policy of whatstool, privacy policy of whatstool tech" },
                { "name": "description", "content": "Learn about WhatsTool Tech Privacy Notice and how it deals with the information that it receives from its customers. WhatsTool Tech is sensitive to privacy issues on the Internet." },
                { "property": "og:description", "content": "Learn about WhatsTool Tech Privacy Notice and how it deals with the information that it receives from its customers. WhatsTool Tech is sensitive to privacy issues on the Internet." },
                { "name": "twitter:description", "content": "Learn about WhatsTool Tech Privacy Notice and how it deals with the information that it receives from its customers. WhatsTool Tech is sensitive to privacy issues on the Internet." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <PrivacyPolicy />
          </Route>
          <Route exact path="/refund-policy">
            <Helmet
              title={"Refund Policy - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Refund Policy - " + siteFullName },
                { "property": "twitter:title", "content": "Refund Policy - " + siteFullName },
                { "name": "keywords", "content": "refund policy of whatstool, refund policy of whatstool tech" },
                { "name": "description", "content": "Refund Policy of WhatsTool. Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange." },
                { "property": "og:description", "content": "Refund Policy of WhatsTool. Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange." },
                { "name": "twitter:description", "content": "Refund Policy of WhatsTool. Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <RefundPolicy />
          </Route>
          <Route exact path="/terms-and-condition">
            <Helmet
              title={"Terms & Conditions - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Terms & Conditions - " + siteFullName },
                { "property": "twitter:title", "content": "Terms & Conditions - " + siteFullName },
                { "name": "keywords", "content": "terms & condition of whatstool, terms and conditions of whatstool tech" },
                { "name": "description", "content": "Terms & Conditions of WhatsTool Tech" },
                { "property": "og:description", "content": "Terms & Conditions of WhatsTool Tech" },
                { "name": "twitter:description", "content": "Terms & Conditions of WhatsTool Tech" },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <TermsNCondition />
          </Route>
          <Route exact path="/about">
            <Helmet
              title={"About - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "About - " + siteFullName },
                { "property": "twitter:title", "content": "About - " + siteFullName },
                { "name": "keywords", "content": "whatstool about, about whatstool tech, about whatstool, about us of whatstool, whatstool about us" },
                { "name": "description", "content": "Read about WhatsTool Tech - What We Do, Our Customers, Leadership Team, News, Press Releases, Events, CSR activities etc." },
                { "property": "og:description", "content": "Read about WhatsTool Tech - What We Do, Our Customers, Leadership Team, News, Press Releases, Events, CSR activities etc." },
                { "name": "twitter:description", "content": "Read about WhatsTool Tech - What We Do, Our Customers, Leadership Team, News, Press Releases, Events, CSR activities etc." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
            />
            <About />
          </Route>

          <Route exact path="/faq">
            <FAQ siteFullName={siteFullName} commonKeyword={commonKeyword} pageURL={pageURL} />
          </Route>
          <Route exact path="/faq/how-to-enable-accessibility-service-for-whatstool-to-start-sending-automatically"><Faq11 /></Route>

          <Route exact path="/upgrade-info">
            <Helmet
              title={"Upgrade Information - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Upgrade Information - " + siteFullName },
                { "property": "twitter:title", "content": "Upgrade Information - " + siteFullName },
                { "name": "keywords", "content": "upgrade information of whatstool app, new updated features of whatstool, awesome features of whatstool" },
                { "name": "description", "content": "Here you'll find every single Upgrade Information of all apps of WhatsTool Tech" },
                { "property": "og:description", "content": "Here you'll find every single Upgrade Information of all apps of WhatsTool Tech" },
                { "name": "twitter:description", "content": "Here you'll find every single Upgrade Information of all apps of WhatsTool Tech" },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]}
              script={[{
                "type": "application/ld+json",
                "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "WhatsTool: Toolkit for WhatsApp",
                "operatingSystem": "ANDROID",
                "applicationCategory": "UtilitiesApplication",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.2",
                  "ratingCount": "5775"
                },
                "review": [
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Naveena N"
                    },
                    "reviewBody": "Nice app... Out of curiosity i have tested the recovery deleted message option.. Message are updated only for the messages which are delivered to the other person (double tick) but on the other hand if message doesn't deliver and deleted (single tick) it failed to update in their app... Overall nice one... Keep up the good work!"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Kaushal Shethia"
                    },
                    "reviewBody": "App has been constantly updated with better features. This has been one of the plus point to use the app. Certainly made the task easier."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Sttewon Auston"
                    },
                    "reviewBody": "Chat without saving number much better and interesting features application so I like to use this app."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rishitha R K"
                    },
                    "reviewBody": "Good app.. most importantly you get a good support from the team."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Olivia _ Addison"
                    },
                    "reviewBody": "WhatsTool this is the best working app whatsapp video status saver is amazingly designed and amazingly easy to use and very helpful app."
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Oyetade Segun John"
                    },
                    "reviewBody": "Before:The update has TOOOOO MANY bugs . Pls fix it. I can't send bulk message at all NOW: The app is now THE BEST. KEEP IT UP"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Shri Karthikeya Financiers"
                    },
                    "reviewBody": "Excellent App to know how to got pics and videos"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Abhishek Anand"
                    },
                    "reviewBody": "Fantastic for bulk messages... Constantly improving on features smoothness"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Shweta Kumari"
                    },
                    "reviewBody": "Fantastic and very smooth app for status downloder, funny messages the best part of app i like is whatsapp chat report"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Vikrant Khandale"
                    },
                    "reviewBody": "It's an really useful aap also have so many features like chat without savimg no Recover delete msgs nd also use for whats aap status saver"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Deeraj Kumar"
                    },
                    "reviewBody": "This is the best app What's tool toolkit for.. is the lovely work successful application app the very good processing aap"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Soumya Soumya"
                    },
                    "reviewBody": "This app is very important to me....Every one download this app...Every one use this app.... Chat without saving Number"
                  },
                  {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "White polarbear"
                    },
                    "reviewBody": "Chat without saving number functions enavled in this smart app is working so much nice way so keep.your focus on the app working."
                  },
                ]
              }`
              }]}
            />
            <UpgradeInfo />
          </Route>


          {/* Apps Pages */}
          <Route exact path="/tools-for-instagram">
            <Helmet
              title={"WhatsTool Im for Instagram - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "WhatsTool Im for Instagram - " + siteFullName },
                { "property": "twitter:title", "content": "WhatsTool Im for Instagram - " + siteFullName },
                { "name": "keywords", "content": "WhatsTool Im for Instagram, Download REEL, Photo & Video Downloader for Instagram, Repost Instagram app, save Instagram photos and videos, tools for Instagram" },
                { "name": "description", "content": "Download REEL, Photo & Video Downloader for Instagram and Repost Instagram app can help you save Instagram photos and videos in simple steps. It is a collection of tools for Instagram with more than 10 features. It is super fast and 100% free." },
                { "property": "og:description", "content": "Download REEL, Photo & Video Downloader for Instagram and Repost Instagram app can help you save Instagram photos and videos in simple steps. It is a collection of tools for Instagram with more than 10 features. It is super fast and 100% free." },
                { "name": "twitter:description", "content": "Download REEL, Photo & Video Downloader for Instagram and Repost Instagram app can help you save Instagram photos and videos in simple steps. It is a collection of tools for Instagram with more than 10 features. It is super fast and 100% free." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]} />
            <InstagramTool />
          </Route>
          <Route exact path="/sip-calculator">
            <Helmet
              title={"SIP Calculator - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "SIP Calculator - " + siteFullName },
                { "property": "twitter:title", "content": "SIP Calculator - " + siteFullName },
                { "name": "keywords", "content": "whatstool sip calculator, sip calculator, Invest via SIP, How do SIP calculators work, online financial tool, SIP investments, whats is SIP Calculator, how SIP calculator works, online sip calculator, mutual funds sahi hai, mutual fund sip return  calculator 2022, calculate returns, can i estimate my sip return online,lump sum sip calculator, sbi sip calculator, sip calculator online, mutual fund sip calculator, grow sip calculator, sip calculator finology, sip calculator hdfc, sip calculator icici, Systematic Investment Plan, full form of SIP," },
                { "name": "description", "content": "This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns." },
                { "property": "og:description", "content": "This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns." },
                { "name": "twitter:description", "content": "This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]} />
            <SipCalc />
          </Route>
          <Route exact path="/emi-calculator">
            <Helmet
              title={"EMI Calculator - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "EMI Calculator - " + siteFullName },
                { "property": "twitter:title", "content": "EMI Calculator - " + siteFullName },
                { "name": "keywords", "content": "whatstool emi calculator, emi calculator, emi calculator for bike, emi calculator sbi, personal loan emi calculator sbi, hdfc emi calculator, home loan emi calculator sbi, sbi car loan emi calculator, loan calculator, home loan emi calculator, loan tenure, calculate emi on home loans, calculate emi on car loans, calculate emi on personal loans" },
                { "name": "description", "content": "EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator." },
                { "property": "og:description", "content": "EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator." },
                { "name": "twitter:description", "content": "EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]} />
            <EmiCalc />
          </Route>
          <Route exact path="/retirement-plan">
            <Helmet
              title={"Retirement PLan - " + siteFullName}
              meta={[
                { "property": "og:title", "content": "Retirement PLan - " + siteFullName },
                { "property": "twitter:title", "content": "Retirement PLan - " + siteFullName },
                { "name": "keywords", "content": "retirement plan, retirement plan app, want to retire before 30, retire at 30, plan your retirement, retire at 30 with 2 crore rupees" },
                { "name": "description", "content": "Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you." },
                { "property": "og:description", "content": "Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you." },
                { "name": "twitter:description", "content": "Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you." },
                { "property": "og:url", "content": pageURL },
                { "name": "twitter:url", "content": pageURL }
              ]}
              link={[{ "rel": "canonical", "href": pageURL }]} />
            <RetirmentPlan />
          </Route>
          {/* End of Apps Pages */}
        </Switch>

        {
          hideHeaderFooter() &&
          <Footer siteName={siteName} address={address} pincode={pincode} GPSIcon_img={GPSIcon_img} whatstoolAppLink={whatstoolAppLink} whatssaveAppLink={whatssaveAppLink} ebillAppLink={ebillAppLink} />
        }

      </Router>
    </>
  );
}

export default App;
