import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment'
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import { isDesktop } from "react-device-detect";
import { Link } from "react-router-dom";
import { Faq } from "../app/App/HomeApp";
import { getUrl } from "../constants/Urls";

// start of Webpage in WTBGrowthBusiness
export const WTGrowthBusiness = () => {
	return (
		<div>
			<WhatstoolGrowBusinessInfo />
			<Clients />
			<SocialMedia />
			<WhatsToolYoutubesection />
			<WhatstoolGrowBusinessSection2 />
			<WhatstoolSent />
			<WhatstoolGrowBusinessSection4 />
			<Faq atr_val={'all'} />
		</div>
	)
}
export const WhatstoolGrowBusinessInfo = () => {

	const [remainingSeconds, setRemainingSeconds] = useState(parseInt(localStorage.getItem('uTimer')))

	useEffect(() => {
		if (remainingSeconds > 0) {
			const timer = setInterval(() => {
				localStorage.setItem('uTimer', JSON.parse(remainingSeconds))
				setRemainingSeconds((prevSeconds) => prevSeconds - 1)
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [remainingSeconds])

	const remainingTime = moment.duration(remainingSeconds, "seconds")

	useEffect(() => {
		if (!localStorage.getItem('uTimer') || localStorage.getItem('uTimer') === 'undefined') {
			localStorage.setItem('uTimer', JSON.parse(28800))
		}
	}, [])

	return (
		<section>
			<Container>
				<div className="mt-4 text-center">
					<Link to="/"><img src="https://user-images.githubusercontent.com/130449582/233764581-e852a2aa-9949-4ded-bb03-0630603c8280.jpg" style={{ height: '30px' }} alt="whatstool" className="img-fluid" /></Link>
				</div>
				<div className="text-center mt-5 mb-5">
					<h6 style={{ fontSize: isDesktop ? '38px' : '24px' }}>Grow your business with WhatsApp</h6>
					<p className="fw-semibold" style={{ fontSize: isDesktop ? '24px' : '21px', color: '#00a82d' }}>Send Bulk Message on WhatsApp </p>
				</div>
				<div style={{ paddingBottom: '25px' }}>
					<Row>
						<div style={{ display: isDesktop ? 'none' : 'block' }}>
							<p>
								<img src="https://user-images.githubusercontent.com/130449582/231387916-be7b773b-add6-4c30-85e2-fcdf26aaac9a.png" alt="grow_business" className="img-fluid" />
							</p>
						</div>
						<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
							<div>
								<div style={{ background: '#CDC2AC', padding: '40px 22px 18px 22px' }} className="text-center text-white">
									<p className="text-white pb-4" style={{ fontSize: '32px' }}>Check this Special Offer</p>
									<p className="pt-3" style={{ fontSize: '18px' }}>You are getting premium in just</p>
									<div style={{ background: '#fff', borderRadius: '5px', margin: '0 75px 40px 75px' }} className="BGmargin">
										<p className="BgfontSize" style={{ color: '#1D8C7E', fontSize: '46px', fontWeight: '500' }}>Rs. 749/Year</p>
									</div>
									<p style={{ fontSize: '24px' }} >This offer ends in</p>

									<div style={{ display: 'flex', justifyContent: 'center', fontWeight: '500' }}>
										<div style={{ background: 'white', borderRadius: '5px', padding: '0 6px' }}>
											<span className="text-dark" style={{ fontSize: '36px' }}>{parseInt(remainingTime.hours())}</span>
										</div>
										<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
										<div style={{ background: 'white', borderRadius: '5px', padding: '0 6px' }}>
											<span className="text-dark" style={{ fontSize: '36px' }}>{parseInt(remainingTime.minutes())}</span>
										</div>
										<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
										<div style={{ background: 'white', borderRadius: '5px', padding: '0 6px' }}>
											<span className="text-dark" style={{ fontSize: '36px' }}>{parseInt(remainingTime.seconds())}</span>
										</div>
									</div>
									<p style={{ color: '#d2e8e5', fontSize: '16px', padding: '12px' }} className="pt-3">Use this offer and get the premium in less price before it ends</p>
									<p>
										<img src="https://user-images.githubusercontent.com/130449582/231386389-df29d0c9-c2dd-4a50-b658-3d2248002db1.png" alt="Wtb playstore" className="img-fluid" />
									</p>
								</div>
							</div>
						</Col>
						<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
							<div style={{ display: isDesktop ? 'block' : 'none' }}>
								<p>
									<img src="https://user-images.githubusercontent.com/130449582/231387916-be7b773b-add6-4c30-85e2-fcdf26aaac9a.png" alt="grow_business" className="img-fluid" />
								</p>
							</div>
						</Col>
					</Row>
				</div>
				<div className="text-center mb-5">
					<Link to={{ pathname: "https://rzp.io/l/whatstool-pro-offer" }} target="_blank" ><button className="btn btn-sm text-white" style={{ background: '#00a82d', borderRadius: '5px', fontSize: isDesktop ? '28px' : '25px', padding: isDesktop ? '10px 360px' : '12px' }}> Get it for your Business &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '30px' }} /></button></Link>
				</div>
			</Container>
		</section >
	)
}

export const Clients = () => {
	return (
		<section>
			<Container fluid>
				<Container className="pt-5 pb-5">
					<h3 className="text-center">Thousands of Business has trusted us in their growth</h3>
					<p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
					<Row>
						<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
							<p className="text-center"><img src={getUrl.imageBaseURL + '/clients.webp'} className="img-fluid w-75" alt='clientImg' /></p>
						</Col>
					</Row>
				</Container>
			</Container>
		</section>
	)
}

export const SocialMedia = () => {
	const socialmedia = [
		{ socialimg: 'https://user-images.githubusercontent.com/130449582/233649217-550c0c3d-2abf-4ca8-8ff9-e4aabf2a4c4a.png' },
		{ socialimg: 'https://user-images.githubusercontent.com/130449582/233649209-9883c949-68e6-4cf3-a3da-54907f2a09df.png' },
		{ socialimg: 'https://user-images.githubusercontent.com/130449582/233649170-8aa4d86b-8368-4df6-b34d-061a8b6d90fc.png' },
	]
	return (
		<section style={{ marginTop: '80px' }}>
			<Container>
				<Row>
					{
						socialmedia?.map((v, i) => {
							return (
								<Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
									<div className="ms-3 me-3 pt-4">
										<img src={v.socialimg} alt="socialimg" className="img-fluid" />
									</div>
								</Col>
							)
						})
					}
				</Row>
			</Container>
		</section>
	)
}

export const WhatsToolYoutubesection = () => {
	return (
		<Container className=" pb-5 pt-5">
			<Row className="mt-5 pt-2 video">
				<Col>
					<h5 className="fw-bold text-center theme mb-2">for Mobile Demo</h5>
					<p className="text-center mb-5"><iframe width="80%" height="280" src="https://www.youtube.com/embed/3h-VDqoUEyg?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
				</Col>
				<Col>
					<h5 className="fw-bold text-center theme mb-2">for Desktop Demo</h5>
					<p className="text-center mb-5"><iframe width="80%" height="280" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
				</Col>
			</Row>
		</Container>
	)
}

export const WhatstoolGrowBusinessSection2 = () => {

	const ClientDescription = [
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231732430-3d7fd131-5595-430e-9604-3e04e28f5122.jpg', Description: 'Whatstool Makes Digital Marketing Productive! As a Digital marketer one of the things I crave is getting more done with less time. Reaching more customers and prospects without struggles. This is exactly what this app has made possible for me. Now we can reach more people with our offers without breaking pocket. Thanks Whatstool for this great product.', clientname: 'Divya Tripati' },
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231730228-b85f8b1c-8e42-487b-9ed9-2e40fff7eafd.jpg', Description: 'Thank you for this app. Does 3 core things for me: 1. Saves statuses 2. Send broadcasts to large contact base 3. Contact sorting. The most important is to view deleted WhatsApp messages. A lot of times I just click the app notification to be sure of what the sender deleted, and then ask questions based on that.', clientname: 'Rajat Gupta' },
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231730230-8fc572c8-ee2c-4fa3-8d12-091f0a8f271d.jpg', Description: "You're in 21st century and you've not heard about whatstool. You must be joking 😅. It gave goosebumps when I found out I could wish all my contacts a happy New year. Yeah. Had to go for premium offer in order to enjoy the full benefits. I really recommend this app to small business owners if you really want to enlarge your business. Couldn't find a find a place for 6 star. ", clientname: 'Neha Agrawal' },
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231732449-55eb05d6-7905-4ea8-a7d9-6ac1219cc9fe.jpg', Description: 'This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. In this application I can add multiple accounts and I recovered delete message and best status saver. I highly recommended to everyone.', clientname: 'Dev Rajput' },
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231730225-4369f383-bec9-4e17-9579-4bdc83f7ea95.jpg', Description: 'This is the best app I have ever come across! It has sky rocketed my business with lots of online whatsapp orders! It has such useful features, I recommend every business owner to make use of this wonderful app if you want to grow your business smartly. It saves alot of my time amd effort to serve my customers online, and has helped me alot through the pandemic times', clientname: 'Abhishek Goyal' },
		{ clientimage: 'https://user-images.githubusercontent.com/130449582/231730237-ed50a7bd-ed02-4ca7-972a-ef38d73472db.jpg', Description: "I have used many apps like this one and to be honest WhatsTools is the best of it's kind. Am using the free version but the benefits are there. Try it and thank the developers later. Easy and straight forward to use. No expert configurations. 5 star rating from me. Keep up the good work guys.", clientname: 'Sakshi Gautam' }
	]

	return (
		<section style={{ background: '#f3f9f9' }}>
			<Container fluid>
				<Container className="pb-3">
					<Row>
						<div className="text-center pt-5 pb-5">
							<p style={{ fontSize: isDesktop ? '32px' : '24px', fontWeight: '500' }}>See what our Customers say</p>
						</div>
						{
							ClientDescription?.map((v, i) => {
								return (
									<Col xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
										<div style={{ margin: '0 12px 65px 12px', minHeight: '600px', background: '#fff', position: 'relative' }} >
											<div>
												<img src={v.clientimage} style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', height: '240px', width: '100%' }} alt="Whatstool" className="img-fluid" />
											</div>
											<div style={{ fontSize: '15px', fontFamily: 'roboto', padding: '24px 24px 0 24px' }}>
												<div>
													<p>{v.Description}</p>
												</div>
												<div style={{ display: 'flex' }}>
													<div style={{ flex: "1", position: 'absolute', left: '6%', bottom: '10%' }}>
														<div><StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /> <StarIcon style={{ color: '#F2B415' }} /><StarHalfIcon style={{ color: '#F2B415' }} /></div>
													</div>
													<span className="fw-semibold" style={{ fontSize: '16px', position: 'absolute', right: '6%', bottom: '10%' }}>{v.clientname}</span>
												</div>
											</div>
										</div>
									</Col>
								)
							})
						}
					</Row>
				</Container>
			</Container>
		</section >
	)
}

export const WhatstoolSent = () => {
	return (
		<section>
			<Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#FBF9EF", }} fluid>
				<div className="text-center mb-5">
					<Link to={{ pathname: "https://rzp.io/l/whatstool-pro-offer" }} target="_blank" ><button className="btn btn-sm text-white" style={{ background: '#00a82d', borderRadius: '5px', fontSize: isDesktop ? '28px' : '16px', padding: isDesktop ? '10px 310px' : '12px' }}> Want to be our customer? Get it now &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '30px' }} /></button></Link>
				</div>
				<h3 className="text-center mb-4 sub-heading-text">Whom you sent</h3>
				<Container>
					<Row>
						<Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk4.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk5.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d1.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
			<Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#D8E8EA", }} fluid>
				<h3 className="text-center mb-4 sub-heading-text">What you sent</h3>
				<Container>
					<Row>
						<Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk6.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk7.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk8.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d2.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
			<Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#F0F4F9", }} fluid>
				<h3 className="text-center mb-4 sub-heading-text">How you send</h3>
				<Container>
					<Row>
						<Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk9.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk10.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
						<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
							<div className="">
								<p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="text-center mb-5">
					<Link to={{ pathname: "https://rzp.io/l/whatstool-pro-offer" }} target="_blank" ><button className="btn btn-sm text-white mt-5" style={{ background: '#00a82d', borderRadius: '5px', fontSize: isDesktop ? '28px' : '16px', padding: isDesktop ? '10px 310px' : '12px' }}> Want to try these features? Do it now &nbsp;&nbsp;<ArrowForwardIcon style={{ fontSize: '30px' }} /></button></Link>
				</div>
			</Container>
		</section>
	)
}

export const WhatstoolGrowBusinessSection4 = () => {

	const [remainingSeconds, setRemainingSeconds] = useState(parseInt(localStorage.getItem('uTimer')))

	useEffect(() => {
		if (remainingSeconds > 0) {
			const timer = setInterval(() => {
				localStorage.setItem('uTimer', JSON.parse(remainingSeconds))
				setRemainingSeconds((prevSeconds) => prevSeconds - 1)
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [remainingSeconds])

	const remainingTime = moment.duration(remainingSeconds, "seconds")

	useEffect(() => {
		if (!localStorage.getItem('uTimer') || localStorage.getItem('uTimer') === 'undefined') {
			localStorage.setItem('uTimer', JSON.parse(28800))
		}
	}, [])

	return (
		<section className="mt-5">
			<Container>
				<Row>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
						<div style={{ background: '#f3f9f9', padding: '40px 22px 8px 22px' }} className="text-center">
							<p className="pb-4 fw-semi-bold" style={{ fontSize: '32px' }}>Check this Special Offer</p>
							<p className="pt-4" style={{ fontSize: '18px' }}>You are getting premium in just</p>
							<div className="BGmargin" style={{ background: '#fff', borderRadius: '5px', margin: '0 75px 40px 75px', boxShadow: '0px 0px 4px 0px #00000040' }}>
								<p className="BgfontSize" style={{ fontSize: '46px', fontWeight: '500' }}>Rs. 749/Year</p>
							</div>
							<h6 style={{ color: 'gray' }}><del>Rs. 999/Year</del></h6>
							<p>
								<img src="https://user-images.githubusercontent.com/130449582/231457420-de839ade-1cdc-4ab7-93f3-0fd25c90902d.png" style={{ height: '385px' }} alt="Plan" className="img-fluid" />
							</p>
						</div>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
						<div style={{ marginTop: '128px' }} className="text-center">
							<p style={{ fontSize: '24px' }} >This offer ends in</p>
							<div style={{ display: 'flex', justifyContent: 'center', fontWeight: '500' }}>
								<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040' }}>
									<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.hours())}</span>
								</div>
								<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
								<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040' }}>
									<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.minutes())}</span>
								</div>
								<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
								<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040' }}>
									<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.seconds())}</span>
								</div>
							</div>
							<p style={{ fontSize: '16px', padding: '12px', color: 'gray' }} className="pt-3">Use this offer and get the premium in less price before it ends</p>
							<div className="mt-5">
								<Link to={{ pathname: "https://rzp.io/l/whatstool-pro-offer" }} target="_blank" ><button className="text-white btn btn-sm" style={{ background: '#128C7E', borderRadius: '5px', fontSize: isDesktop ? '26px' : '24px', padding: isDesktop ? '10px 128px' : '12px' }}>Get the Premium now</button></Link>
							</div>
						</div>
					</Col>
				</Row>
				<div style={{ position: 'fixed', bottom: 50, right: 50, zIndex: 9999, justifyContent: 'center', display: isDesktop ? 'none' : 'block' }}>
					<div style={{ display: 'flex', fontWeight: '500' }}>
						<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040', background: 'rgba(0,0,0,.2)' }}>
							<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.hours())}</span>
						</div>
						<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
						<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040', background: 'rgba(0,0,0,.2)' }}>
							<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.minutes())}</span>
						</div>
						<span style={{ fontSize: '32px', margin: '0 12px' }}>:</span>
						<div style={{ borderRadius: '5px', padding: '0 6px', boxShadow: '0px 0px 4px 0px #00000040', background: 'rgba(0,0,0,.2)' }}>
							<span style={{ fontSize: '36px' }}>{parseInt(remainingTime.seconds())}</span>
						</div>
					</div>
				</div>
			</Container>
		</section>
	)
}


// End of Webpage in WTBGrowthBusiness
