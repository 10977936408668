
class WtUrls {

  constructor() {
    // URLs
    this.bannerimgUrl = "https://storage.googleapis.com/wt_resources/wtb_media/Whatstool%20offer.png"
    this.BaseimageUrl = "https://storage.googleapis.com/wt_resources/images/media"
    this.imageBaseURL = 'https://storage.googleapis.com/wt_resources/images'
    this.WtamediaBaseURL = 'https://storage.googleapis.com/wt_resources/wta_media/'


    this.wtdPaymentUrl = `https://tools.whatstool.business/wtd-payment`

    this.appDirectDownloadUrl = `https://shorturl.at/xER08`
    // this.appDirectDownloadUrl = `https://play.google.com/store/search?q=whatstool&c=apps&hl=en-IN`
    this.appProductionUrl = 'https://app.whatstool.business/auth'

    this.WhatsAppapiURL = '/whatsapp-api'

  }
}
export const getUrl = new WtUrls()
