/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import '../App.css'
import { Container, Row, Col, Form, FloatingLabel, Tab, Nav, Button, Modal } from 'react-bootstrap'
import { ListItemAvatar, Avatar, IconButton } from '@mui/material'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Player } from '@lottiefiles/react-lottie-player'
import { PricingPlan } from './UtilsStatic'
import { HomeLayoutTwo } from './UtilsHome'
import { WtEvent } from '../basetool/Analttics'
import { getAnalyticsKey } from '../constants/Analyticskeys'
import { Homepagepricing } from '../basetool/Dialog'
import { getUrl } from '../constants/Urls'
import CountryList from '../thirdparty/CountryCode.json'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Close } from '@mui/icons-material'
import { isMobile } from 'react-device-detect'
import { getColour } from '../constants/Colour'

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

const f_bulk_msg = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/bulk_msg.png"
const f_caption = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/caption.png"
const f_chat = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/chat.png"
const f_editor = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor.png"
const f_funny = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/text.png"
const f_recover = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png"
const f_reply = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/reply.png"
const f_report = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report.png"
const f_status = "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status.png"

export const FeatureHome = () => {
    const FeatureHome_Data = [
        { "tag_name": "CHAT", "title": "Direct Chat with Unsaved Numbers", "desc": "Direct Chat with an unsaved number: WhatsTool enables users to message on WhatsApp to a new number, search profile in WhatsApp, and check the details of 😀 new/missed call numbers by searching the number directly without saving their contact.", "img_path": f_chat, "bg_color": "#FBF9EF", "url": "/features/direct-chat-with-an-unsaved-number" },
        { "tag_name": "FUNNY", "title": "Send Funny Messages on WhatsApp", "desc": "Check out these funny messages about friends that you use for your status or when you feel like sending your friend’s funny messages about how awesome they are.", "img_path": f_funny, "bg_color": "#E6F6F0", "url": "/features/funny-messages" },
        { "tag_name": "CAPTION", "title": "Send Awesome Caption Messages", "desc": "WhatsApp Status is a great way to express yourself. It is an expression explicitly written and in a precise way to reveal one's views, thoughts, and emotions in a creative style. WhatsApp status displays how uniquely and ingeniously you can put your thoughts in words.", "img_path": f_caption, "bg_color": "#F0F4F9", "url": "/features/best-captions-for-instagram-post-and-whatsapp-status" },
        { "tag_name": "REPLY", "title": "Send Quick Reply to Friends", "desc": "This app lets users enable 👨‍🎤 Quick Reply options that allow you to message on WhatsApp by creating custom 🧑‍🔧 messages and use them as quick replies to save your time.", "img_path": f_reply, "bg_color": "#D8E8EA", "url": "/features/quick-reply" },
        { "tag_name": "BULK MESSAGE", "title": "Automatic WhatsApp Bulk Messaging", "desc": "", "img_path": f_bulk_msg, "bg_color": "#E7F0E4", "url": "/features/bulk-whatsapp-sender-android-app" },
        { "tag_name": "RECOVER", "title": "Recover Deleted WhatsApp Chat", "desc": "Get notified 👀 of deleted messages in WhatsApp chat & recover them with the help 🧒 of WhatsTool.", "img_path": f_recover, "bg_color": "#FBF9EF", "url": "/features/recover-deleted-whatsapp-chat" },
        { "tag_name": "REPORT", "title": "Generate WhatsApp Chat Report", "desc": "", "img_path": f_report, "bg_color": "#E6F6F0", "url": "/features/detailed-whatsapp-chat-report" },
        { "tag_name": "STATUS", "title": "WhatsApp Status Saver", "desc": "This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want.", "img_path": f_status, "bg_color": "#F0F4F9", "url": "/features/whatsapp-images-videos-status-downloader" },
        { "tag_name": "EDITOR", "title": "Video Spliter", "desc": "This inbuilt 😎 tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses.", "img_path": f_editor, "bg_color": "#D8E8EA", "url": "/features/whatsapp-status-video-spliter" }
    ]
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <Container className="features" fluid>
            <Row>
                {
                    FeatureHome_Data.map((v, i) => {
                        return (
                            <>
                                <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} style={{ backgroundColor: v.bg_color }} key={i}>
                                    <Link to={v.url}>
                                        <div className="elements p-5">
                                            <p className="text-center desc-text">{v.tag_name}</p>
                                            <h1 className="text-center heading-text">{v.title}</h1>
                                            <p className="text-center desc-text">{v.desc}</p>
                                            <p className="text-center mt-5"><img src={v.img_path} className="img-fluid" alt={v.title + " - WhatsTool Tech"} /></p>
                                        </div>
                                    </Link>
                                </Col>
                            </>
                        )
                    })
                }
            </Row>
        </Container>
    )
}
export const BulkMessaging = () => {
    const [showO, setShowO] = useState(false);
    const handleCloseO = () => setShowO(false);

    const [openDialog, setOpenDialog] = useState({ open: false, onClose: null })
    const openDialogBox = () => {
        WtEvent(getAnalyticsKey.HomePlanpricingClicked)
        setOpenDialog({ open: true, onClose: () => setOpenDialog(false) })
    }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Container className="features-page bulk-message mb-3 ps-4 pe-4">
                <Row className="features-info">
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-5 pt-5">
                            <h1 className="mt-4 heading-text"><span className='theme'>WhatsApp</span> Bulk Messaging</h1>
                            <h4 className="mt-3 mb-3 theme" style={{ lineHeight: '30px' }}>For Android &amp; Windows Desktop</h4>
                            <h6 className="mt-2 sub-heading-text" style={{ lineHeight: '30px' }}>In WhatsTool Bulk Automatic Messaging you have full control with all features of</h6>
                            <h6 style={{ lineHeight: "2" }}><span className="q_left border ps-2 pe-2 rounded-pill me-2">Whom</span> you <span className="q_right">send</span>,
                                <span className="q_left border ps-2 pe-2 rounded-pill me-2 ms-2">What</span> you <span className="q_right">send</span> &amp;
                                <span className="q_left border ps-2 pe-2 rounded-pill me-2 ms-2">How</span> you <span className="q_right">send</span>.</h6>

                            <p className="mt-5 mb-5">
                                <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.imageBaseURL + "/media/playstore_1.png"} className="img-fluid" width="165px" alt="Download WhatsTool" /></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="hand"><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="img-fluid" width="165px" alt="Download WhatsTool " /></span>
                                <div onClick={() => openDialogBox()}>
                                    <img src={getUrl.bannerimgUrl} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-3 mt-2" />
                                </div>
                            </p>
                            {openDialog.open && <Homepagepricing open={openDialog.open} onClose={openDialog.onClose} />}
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="mt-5 pt-2 video">
                            <p className="fw-bold theme mb-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for Mobile Demo</p>
                            <p className="text-center"><iframe width="60%" height="240" src="https://www.youtube.com/embed/3h-VDqoUEyg?controls=0" title="Bulk Sender" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                            <p className="fw-bold theme mb-2 mt-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;for Desktop Demo</p>
                            <p className="text-center"><iframe width="60%" height="240" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="features-page bulk-message mb-5 pb-5 ps-4 pe-4">
                <Row className="features-info">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="mt-5 pt-2 video">
                            <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'wt_desktop.png'} className="img-fluid rounded-3" alt="WhatsTool Desktop Version" /></p>
                            <p className="text-center mt-5 mb-5">
                                <span className="hand bulk-wbtn pt-3 pb-3 pe-4 ps-4"><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#FBF9EF", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">Whom you sent</h3>
                <Container>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/bulk_msg_page/bulk3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'create_grp.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'importcsv.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'wt_import.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#D8E8EA", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">What you sent</h3>
                <Container>
                    <Row>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'sendimage.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'Quickreply.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'personalmeg.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'wt_add_message.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#F0F4F9", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">How you send</h3>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'choosestart.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'Adddelay.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={getUrl.WtamediaBaseURL + 'startsend.png'} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="premium pb-5 pt-5" fluid>
                <h3 className="text-center mb-4 sub-heading-text">Premium Plan</h3>
                <PricingPlan />
            </Container>
            <Container className="features-page bulk-message pb-4 ps-4 pe-4">
                <Row className="features-how-it-works mt-5">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="mt-4 video">
                            <iframe width="100%" height="610px" src="https://www.youtube.com/embed/videoseries?controls=0&amp;list=PLcFNxAhN6VTDThcMniT4Cws0jqO3QJUfw" title="WhatsTool for Bulk WhatsApp - Bulk Sender" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
            <HomeLayoutTwo />
            <Modal className="modal-info-bulk-msg" show={showO} onHide={handleCloseO} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        {/* <h3 className="theme text-center mt-5 ">Pay ₹899/$10.89 to Get Desktop App</h3> */}
                        {/* <p className="text-center theme mb-5 ">for Premium Users Only</p> */}
                        <p className="theme text-center mb-2  mt-5">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                        <hr />
                        <div style={{ display: "none" }}>
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                            <hr />
                        </div>
                        <Row className="mt-5">
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <h4 style={{ color: "#635BFF" }} className="mt-2 text-center">Pay using Credit/Debit Card</h4>
                                <p className="text-center">
                                    <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/pb_stripe.png"} alt="WhatsTool Tech" width="100px" />
                                </p>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className="text-center">
                                    <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/stripe.png"} alt="WhatsTool Tech" width="200px" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Yearly</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/6oE8wTfpt7ojcww4gh" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Lifetime</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/aEUbJ50uz0ZV0NO5km" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
export const DesktopApp = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showO, setShowO] = useState(false);
    const handleCloseO = () => setShowO(false);
    const handleShowO = () => setShowO(true);

    // const handleOffOn = () => {
    //     setShow(false);
    //     setShowO(true);
    // }

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <Container className="features-page bulk-message mb-3 ps-4 pe-4">
                <Row className="features-info">
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="mt-2 pt-5">
                            <h1 className="mt-1 heading-text"><span className='theme'>WhatsApp</span> Desktop App</h1>
                            <h6 className="mt-2 sub-heading-text" style={{ lineHeight: '30px' }}>In WhatsTool Bulk Automatic Messaging you have full control with all features of</h6>
                            <h6 style={{ lineHeight: "2" }}><span className="q_left border ps-2 pe-2 rounded-pill me-2">Whom</span> you <span className="q_right">send</span>,
                                <span className="q_left border ps-2 pe-2 rounded-pill me-2 ms-2">What</span> you <span className="q_right">send</span> &amp;
                                <span className="q_left border ps-2 pe-2 rounded-pill me-2 ms-2">How</span> you <span className="q_right">send</span>.</h6>

                            <p className="mt-3 mb-5">
                                <div onClick={handleShowO}>
                                    <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/offer_for_app_new.png"} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-3" />
                                </div>
                                <p className="hand" onClick={handleShow}><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="img-fluid mt-2" width="165px" alt="Download WhatsTool" /></p>
                            </p>
                            <Modal className="modal-info-bulk-msg" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                                <Modal.Body>
                                    <Container className="mt-3 mb-3 pt-5 pb-5 ps-3 pe-3">
                                        <h4 className="text-center">If you don't have the License of <span className="theme">WhatsTool Desktop</span>,<br /> then apply for FREE TRIAL</h4><br /><br />
                                        <p className="text-center"><Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSdIvVDtNyEecMC2smVNLncklwHQZCjT2BDT1COdFnL-27dDDQ/viewform?usp=sf_link" }} target="_blank" className="wbtn">Get FREE TRIAL <i className="fas fa-desktop"></i></Link></p><br /><br />
                                        <p className="text-center mb-4"><Link to={{ pathname: "https://whatstool.in/app/DesktopApp/WhatsToolSetup.msi" }} target="_blank" className="bulk-wbtn pt-3 pb-3 pe-4 ps-4" download><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></Link></p>
                                        {/* <p className="text-center" onClick={handleOffOn}><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/offer_for_app_new.png"} width="350px" className="img-fluid rounded-3 mb-4" /></p> */}
                                        <h5 className="text-center mt-5 mb-5">How to Use: <Link to={{ pathname: "https://youtu.be/UIpowWU9A9g" }} target="_blank">Watch Video</Link></h5>
                                        <p style={{ fontSize: "12px" }}><b>*Important:</b> One Email ID for 1 Device (is allowed).</p>
                                        <p style={{ fontSize: "12px" }}><b>*Instruction:</b> Extract the Zip File and Click the Icon to Open it. <br /><b>Preview:</b> How to Open the WhatsTool Desktop.</p>
                                        <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/instruction.png"} alt="WhatsTool Tech" className="img-fluid mt-3 shadow-lg" width="60%" /></p>
                                    </Container>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="mt-5 p-3 video">
                            <p className="text-center"><iframe width="100%" height="420" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="features-page bulk-message mb-5 pb-5 ps-4 pe-4">
                <Row className="features-info">
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="mt-5 pt-2 video">
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop.jpeg" className="img-fluid rounded-3" alt="WhatsTool Desktop Version" /></p>
                            <p className="text-center mt-5 mb-5">
                                <span onClick={handleShow} className="hand bulk-wbtn pt-3 pb-3 pe-4 ps-4"><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#FBF9EF", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">Whom you sent</h3>
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d1.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#D8E8EA", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">What you sent</h3>
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d2.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="features-page bulk-message pb-5 pt-5" style={{ backgroundColor: "#F0F4F9", }} fluid>
                <h3 className="text-center mb-4 sub-heading-text">How you send</h3>
                <Container>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="">
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d3.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" /></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="premium pb-5 pt-5" fluid>
                <h3 className="text-center mb-4 sub-heading-text">Premium Plan</h3>
                <PricingPlan />
            </Container>
            <HomeLayoutTwo />
            <Modal className="modal-info-bulk-msg" show={showO} onHide={handleCloseO} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        <h3 className="theme text-center mt-5 ">Pay ₹799/$10.3 to Get Desktop App</h3>
                        <p className="text-center theme mb-5">for Premium Users Only</p>
                        <p className="theme text-center mb-2">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                        <hr />
                        <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                        <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>

                        <hr />
                        <Row className="mt-5">
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <h4 style={{ color: "#635BFF" }} className="mt-2 text-center">Pay using Credit/Debit Card</h4>
                                <p className="text-center">
                                    <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/pb_stripe.png"} alt="WhatsTool Tech" width="100px" />
                                </p>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <p className="text-center">
                                    <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/stripe.png"} alt="WhatsTool Tech" width="200px" />
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Yearly</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/6oE8wTfpt7ojcww4gh" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Lifetime</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/aEUbJ50uz0ZV0NO5km" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
export const LiveChatSupport = () => {
    return (
        <Container className="f_page">
            <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/coming-soon-anim.json" className="items-lottie" ></Player>
        </Container>
    )
}
export const AIChatBots = () => {
    return (
        <Container className="f_page">
            <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/coming-soon-anim.json" className="items-lottie" ></Player>
        </Container>
    )
}
export const AutomateNotification = () => {
    return (
        <Container className="f_page">
            <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/coming-soon-anim.json" className="items-lottie" ></Player>
        </Container>
    )
}
export const CRM = () => {
    return (
        <Container className="f_page">
            <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/coming-soon-anim.json" className="items-lottie" ></Player>
        </Container>
    )
}



export const DirectChat = () => {

    const [mobilenumber, setMobilenumber] = useState('')
    const [mobilenumberTwo, setMobilenumberTwo] = useState('')
    const handlemobilenumber = (val) => {
        let checkedval = val?.replace(/[^0-9]/ig, '')
        setMobilenumber(checkedval)
    }

    const handleMobilenumberTwo = (val) => {
        let checkval = val?.replace(/[^0-9]/ig, '')
        setMobilenumberTwo(checkval)
    }

    const [countrycodeLs, setCountrycodeLs] = useState(CountryList)

    const [searchLs, setSearchLs] = useState([])
    useEffect(() => { setSearchLs(countrycodeLs) }, [countrycodeLs])
    const SearchItems = (sv) => {
        const filterdata = CountryList.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filterdata)
    }

    const [countrycode, setCountryCode] = useState('+91')
    const handleCountryCode = (code) => {
        setCountryCode(code)
        handleClose()
    }

    const [opencountryDialog, setOpencountryDialog] = useState(false)
    const handleSelectionOpenDialog = () => setOpencountryDialog(true)

    const handleClose = () => setOpencountryDialog(false)

    return (
        <section>
            <Container className="features-page direct-chat p-4 mb-5" fluid>
                <Row className="features-info mb-5">
                    <Col xxl={2} xl={2} lg={2}></Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                        <Player autoplay loop src="https://lottie.host/031d6af6-c747-417d-927e-63cc9a07c377/kklbmKCYRO.json" className="items-lottie" style={{ width: '20%' }} ></Player>
                        <h1 className="text-center heading-text">Direct Chat</h1>
                        <p className="text-center theme" style={{ marginTop: "-10px", fontSize: "14px" }}>with Unsaved Numbers</p>
                        <div style={{ marginTop: '30px' }}>
                            <label style={{ fontSize: '12px' }}>Country Code & Enter WhatsApp No</label>
                            <section style={{ display: 'flex' }}>

                                <div style={{ width: '100%', height: '12mm', outline: 'none', border: '1px solid' + getColour.DescriptionTextColour, borderRadius: '12px', display: 'flex' }}>
                                    <span onClick={() => handleSelectionOpenDialog()} style={{ padding: '10px 7px 0 12px', cursor: 'pointer' }} >{countrycode}&nbsp;|</span>
                                    <input value={mobilenumber} onChange={(e) => handlemobilenumber(e.target.value)} style={{ width: isMobile ? '68%' : '90%', outline: 'none', border: 'none' }} placeholder='WhatsApp Number' type='tel' required maxLength={15} />
                                </div>

                                <Link to={{ pathname: 'https://wa.me/' + countrycode + mobilenumber }} target='_blank'><button className='bg-white' style={{ whiteSpace: 'nowrap', border: '1px solid ' + getColour.BaseColor, borderRadius: '12px', margin: '4px 0 0 14px', padding: '5px 12px 5px 12px', fontSize: '14px' }}>Send Now</button></Link>
                            </section>
                        </div>
                        <div>
                            <p className="text-center mt-4 mb-4 fw-bold">or</p>
                        </div>
                        <div style={{ marginTop: '40px' }}>
                            <label style={{ fontSize: '12px' }}>Enter Country Code with WhatsApp No.</label>
                            <section style={{ display: 'flex' }}>

                                <div style={{ width: '100%', height: '12mm', outline: 'none', border: '1px solid' + getColour.DescriptionTextColour, borderRadius: '12px', display: 'flex', padding: '12px' }}>
                                    <input onChange={(e) => handleMobilenumberTwo(e.target.value)} value={mobilenumberTwo} style={{ width: '100%', outline: 'none', border: 'none' }} placeholder='WhatsApp Number' type='tel' required maxLength={15} />
                                </div>

                                <Link to={{ pathname: 'https://wa.me/' + countrycode + mobilenumberTwo }} target='_blank'><button className='bg-white' style={{ whiteSpace: 'nowrap', border: '1px solid ' + getColour.BaseColor, borderRadius: '12px', margin: '4px 0 0 14px', padding: '5px 12px 5px 12px', fontSize: '14px' }}>Send Now</button></Link>
                            </section>
                        </div>
                    </Col>
                    <Col xxl={2} xl={2} lg={2}></Col>
                </Row>
            </Container>
            <div>
                <Dialog open={opencountryDialog} fullWidth={true} maxWidth={"sm"} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                    <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>Select Country Code <IconButton className='float-end hand' onClick={() => handleClose()}><Close /></IconButton></DialogTitle>
                    <DialogContent>
                        <section style={{ margin: '12px' }}>
                            <input placeholder='Select Country Code' type='search' onChange={(e) => SearchItems(e.target.value)} style={{ width: '100%', outline: 'none', padding: '10px 20px', border: '1px solid ' + getColour.BorderColour, borderRadius: '30px' }} />

                            <div style={{ height: '60vh', overflowY: 'auto' }}>
                                {
                                    searchLs?.map((v, i) => {
                                        return (
                                            <section onClick={() => handleCountryCode(v.dial_code)} style={{ padding: '6px', margin: '10px', border: '1px solid ' + getColour.LineColor, borderRadius: '4px', cursor: 'pointer' }} key={i} >
                                                <div style={{ display: 'flex' }}>
                                                    <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                    <p style={{ margin: '0 10px' }}><span style={{ fontWeight: 'bold' }}>{v.dial_code}</span>&nbsp;&nbsp;{v.name}</p>
                                                </div>
                                            </section>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </DialogContent>
                    <br />
                    <hr />
                    <section className='dialog_footer' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <button style={{ color: getColour.HeadingTextColour, background: getColour.BorderLineColour, padding: '4px 20px' }} onClick={() => handleClose()} className="btn btn-sm">Close</button>
                        </div>
                        <div>&nbsp;</div>
                    </section>
                </Dialog>
            </div>
        </section >
    )
}
export const FunnyMessage = () => {
    const [outputText, setOutput] = useState("");
    const inputEvent = (event) => {
        setOutput(event.target.value)
    }
    $(function () {
        var originalText = [" ", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        var roundText = [" ", "①", "②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨", "Ⓐ", "Ⓑ", "Ⓒ", "Ⓓ", "Ⓔ", "Ⓔ", "Ⓖ", "Ⓗ", "Ⓘ", "Ⓙ", "Ⓚ", "Ⓛ", "Ⓜ", "Ⓝ", "Ⓞ", "Ⓟ", "Ⓠ", "Ⓡ", "Ⓢ", "Ⓣ", "Ⓤ", "Ⓥ", "Ⓦ", "Ⓧ", "Ⓨ", "Ⓩ", "ⓐ", "ⓑ", "ⓒ", "ⓓ", "ⓔ", "ⓕ", "ⓖ", "ⓗ", "ⓘ", "ⓙ", "ⓚ", "ⓛ", "ⓜ", "ⓝ", "ⓞ", "ⓟ", "ⓠ", "ⓡ", "ⓢ", "ⓣ", "ⓤ", "ⓥ", "ⓦ", "ⓧ", "ⓨ", "ⓩ"];
        var upsideDown = [" ", "Ɩ", "ᄅ", "Ɛ", "ㄣ", "ϛ", "9", "ㄥ", "8", "6", "∀", "q", "Ɔ", "p", "Ǝ", "Ⅎ", "פ", "H", "I", "ſ", "ʞ", "˥", "W", "N", "O", "Ԁ", "Q", "ɹ", "S", "┴", "∩", "Λ", "M", "X", "⅄", "Z", "ɐ", "q", "ɔ", "p", "ǝ", "ɟ", "ƃ", "ɥ", "ᴉ", "ɾ", "ʞ", "l", "ɯ", "u", "o", "d", "b", "ɹ", "s", "ʇ", "n", "ʌ", "ʍ", "x", "ʎ", "z"];

        var mapOfRoundedText = new Map();
        var mapOfUpsideDownText = new Map();
        originalText.forEach(it => { mapOfRoundedText.set(it) });
        originalText.forEach(it => { mapOfUpsideDownText.set(it) });

        for (var i = 0; i < originalText.length; i++) {
            mapOfRoundedText.set(originalText[i], roundText[i]);
            mapOfUpsideDownText.set(originalText[i], upsideDown[i]);
        }

        $("#user_input").on("keyup", function (event) {
            var userInput = $(this).val();
            var arrayOfUserInput = userInput.split('');
            var userChoice = $("#text_pattern :selected").val();
            var userOutput = "";
            switch (userChoice) {
                case "round": arrayOfUserInput.forEach(element => { userOutput = userOutput + mapOfRoundedText.get(element) }); break;
                case "upside_down": arrayOfUserInput.forEach(element => { userOutput = userOutput + mapOfUpsideDownText.get(element) }); break;
                default: break;
            }
            $("#output_text").html(userOutput).val();
            $("#op").html(userOutput).val();

        });

        $("#copy_output_text").on("click", function (event) {
            var copyText = document.getElementById("output_text");
            copyText.select();
            navigator.clipboard.writeText(copyText.value);
        });
    })
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>
            <Container className="features-page ps-4 pe-4 pb-4">
                <Row className="features-info">
                    <Col xxl={2} xl={2} lg={2}></Col>
                    <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                        <div>
                            <Player autoplay loop src="https://lottie.host/b3ea1f0b-86e9-4735-b106-8a77bc6a2457/PBfnE9WklJ.json" className="items-lottie" ></Player>
                            <h1 className="text-center heading-text mb-3">Funny Messages</h1>
                            <Form>
                                <Row>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FloatingLabel label="Enter your message" className="mb-2">
                                            <Form.Control as="textarea" id="user_input" className="rounded-2" placeholder="Enter your message" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FloatingLabel label="Select the Funny Pattern..." className="mb-2">
                                            <Form.Select id="text_pattern" className="rounded-2" aria-label="Select the Funny Pattern...">
                                                <option value="0">Please select the Pattern...</option>
                                                <option value="round">Rounded</option>
                                                <option value="upside_down">Upside Down</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <FloatingLabel label="Output will show here..." className="mb-2">
                                            <Form.Control as="textarea" onPointerEnter={inputEvent} id="output_text" className="rounded-2" style={{ height: "100px" }} placeholder="Output will show here..." />
                                        </FloatingLabel>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <p className="text-center">
                                            <Button id="copy_output_text" className="btn btn-sm wbtn mt-2 rounded-0 me-2">Copy</Button>
                                            <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + outputText }} className="btn btn-sm wbtn mt-2 rounded-0">Send</Link>
                                        </p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                    <Col xxl={2} xl={2} lg={2}></Col>
                </Row>
            </Container>
        </>
    )
}
export const CaptionMsg = (props) => {
    const { siteFullName, pageURL } = props
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/captions.json')
        setItems(await response.json())
    }
    useEffect(() => {
        getItems()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <>
            <Helmet
                title={"Best Captions for Instagram Post and WhatsApp Status - " + siteFullName}
                meta={[
                    { "property": "og:title", "content": "Best Captions for Instagram Post and WhatsApp Status - " + siteFullName },
                    { "property": "twitter:title", "content": "Best Captions for Instagram Post and WhatsApp Status - " + siteFullName },
                    { "name": "keywords", "content": "Best Captions for Instagram Post and WhatsApp Status, Best Captions for Instagram Post, Best Captions for WhatsApp Status, best captions for instagram post, best captions for instagram post for boy, best captions for instagram post for girl, best captions for instagram post for girl attitude, best captions for instagram post with friends, best captions for instagram post with brother, best captions for instagram post with girlfriend, some best captions for instagram post, best short captions for instagram post, best friend captions for instagram post, best attitude captions for instagram post, best love captions for instagram post, best funny captions for instagram post, best cool captions for instagram post, caption for whatsapp bio, whatsapp quotes about life, small quotes for whatsapp status, short quotes for whatsapp bio, best captions for whatsapp post, best captions for whatsapp bio, best status captions for whatsapp, best captions for whatsapp, what is a good caption for a best friend" },
                    { "name": "description", "content": "Best Captions for Instagram Post and WhatsApp Status. A great way to express yourself. Instagram post and WhatsApp status displays how uniquely and ingeniously you can put your thoughts in words." },
                    { "property": "og:description", "content": "Best Captions for Instagram Post and WhatsApp Status. A great way to express yourself. Instagram post and WhatsApp status displays how uniquely and ingeniously you can put your thoughts in words." },
                    { "name": "twitter:description", "content": "Best Captions for Instagram Post and WhatsApp Status. A great way to express yourself. Instagram post and WhatsApp status displays how uniquely and ingeniously you can put your thoughts in words." },
                    { "property": "og:url", "content": pageURL },
                    { "name": "twitter:url", "content": pageURL }
                ]}
                link={[{ "rel": "canonical", "href": pageURL }]}
                script={[{
                    "type": "application/ld+json",
                    "innerHTML": `{
                  "@context": "https://schema.org",
                  "@type": "FAQPage",
                  "mainEntity": [
                  ${items.map((el) => `{ "@type": "Question","name": "Best ${el.category} captions for instagram post and whatsapp status","acceptedAnswer": { "@type": "Answer", "text": "${el.caption}" }}`)}
                  ]
              }`
                }]}
            />
            <Container className="caption-status ps-4 pe-4 pb-5 mb-5">
                <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/caption_anim.json" className="items-lottie" ></Player>
                <Tab.Container defaultActiveKey="general">
                    <Row>
                        <Col>
                            <Nav variant="pills" className="justify-content-center">
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="general">&#129409; &nbsp; General</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="best">&#128076; &nbsp; Best</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="motivation">&#127919; &nbsp; Motivation</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="selfie">&#129331; &nbsp; Selfie</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="clever">&#128171; &nbsp; Clever</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="life">&#128519; &nbsp; Life</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="love">&#128525; &nbsp; Love</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="cute">&#128522; &nbsp; Cute</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="cool">&#128521; &nbsp; Cool</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="fitness">&#128692; &nbsp; Fitness</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="funny">&#128518; &nbsp; Funny</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="sad">&#128532; &nbsp; Sad</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link className="heading-text hand" eventKey="savage">&#128541; &nbsp; Savage</Nav.Link></Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tab.Content>
                                <Tab.Pane eventKey="general">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "general") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="best">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "best") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="motivation">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "motivation") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="selfie">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "selfie") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="clever">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "clever") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="life">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "life") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="love">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "love") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="cute">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "cute") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="cool">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "cool") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fitness">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "fitness") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="funny">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "funny") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sad">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "sad") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                                <Tab.Pane eventKey="savage">
                                    <Container>
                                        {
                                            items.map((el, i) => {
                                                return (
                                                    <>
                                                        {
                                                            ((el.slug).trim() === "savage") ? (
                                                                <div className="row" key={i}>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                    <ul className="list-group mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" >
                                                                        <li className="list-group-item">
                                                                            <p>{el.caption}</p><hr />
                                                                            <span className="float-end">
                                                                                <i className="far fa-copy pe-3 arrow" onClick={() => navigator.clipboard.writeText(el.caption)}></i>
                                                                                <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + el.caption }} ><i className="fab fa-whatsapp pe-3 arrow"></i></Link></Link>
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="col-xl-3 col-lg-3 col-md-3"></div>
                                                                </div>
                                                            ) : ("")
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}
export const QuickReply = () => {
    const QuickReplyItems = [
        ["Hi, How are you?", "Where are you?", "When will you reach?", "I have reached home.", "Have you had dinner?", "You are Somebody’s reason to Smile."],
        ["A festival full of sweet memories, A sky full of lights, Mouth full of sweets, And heart full of joy. Wishing you all a Very Happy Diwali.", "As you celebrate Diwali, please note that my wishes are there with you for a safe and Happy Diwali!", "Blessings are with you this festival of lights and always. Happy Diwali.", "I am wishing that the bright lights of Diwali guide you through your way out of every streak of trials in your life. Diwali wishes to you and your family.", "Celebrate life, not just festivals.", "Live every day as if it is a festival. Turn your life into a celebration.", "May this festival be one that brings along with it all that’s best and everything you deserve.", "May this festival be the start of the year that will bring your joy and happiness! Best wishes to you and your family.", "Sending you my cheerful wishes and warmest greetings for a joyous and Festive Season.", "Share our similarities. Celebrate our differences."],
        ["Enjoy the early Diwali sale.", "Get the perfect Diwali gift.", "Spread happiness with others and shop with us.", "Christmas Hot Sale Up to 50% Off.", "Create your memory with our Christmas sale.", "An exclusive 50% OFF in-store! Christmas wishes do come true.", "Buckle up for the mega offer sales.", "Enjoy this holiday with huge discounts.", "Get whatever your heart desires.", "The mega offer sale is here. Where are you?"]
    ]
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <div className="quick-reply container ps-4 pe-4 pb-5 mb-5">
            <h1 className="text-center mt-2 mb-3">Quick Reply</h1>
            <Tab.Container defaultActiveKey="greeting">
                <Row>
                    <Col>
                        <Nav variant="pills" className="justify-content-center">
                            <Nav.Item><Nav.Link className="heading-text hand" eventKey="greeting">Greeting</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="heading-text hand" eventKey="festival">Festival</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="heading-text hand" eventKey="sales">Sales</Nav.Link></Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="greeting">
                                {
                                    QuickReplyItems[0].map((items, index) => {
                                        return (
                                            <ul className="list-group" key={index}>
                                                <li className="list-group-item rounded-0">
                                                    Message: <span className="fw-bold">{items}</span>
                                                    <span className="float-end arrow">
                                                        <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/W.svg" alt="Icon - WhatsTool Tech" className="me-3" width="20px" height="20px" /></Link>
                                                        <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/WB.svg" alt="Icon - WhatsTool Tech" width="20px" height="20px" /></Link>
                                                    </span>
                                                </li>
                                            </ul>
                                        )
                                    })
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="festival">
                                {QuickReplyItems[1].map((items, index) => {
                                    return (
                                        <ul className="list-group">
                                            <li className="list-group-item rounded-0">
                                                Message: <span className="fw-bold">{items}</span>
                                                <span className="float-end arrow">
                                                    <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/W.svg" alt="Icon - WhatsTool Tech" className="me-3" width="20px" height="20px" /></Link>
                                                    <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/WB.svg" alt="Icon - WhatsTool Tech" width="20px" height="20px" /></Link>
                                                </span>
                                            </li>
                                        </ul>
                                    )
                                })}
                            </Tab.Pane>
                            <Tab.Pane eventKey="sales">
                                {QuickReplyItems[2].map((items, index) => {
                                    return (
                                        <ul className="list-group">
                                            <li className="list-group-item rounded-0">
                                                Message: <span className="fw-bold">{items}</span>
                                                <span className="float-end arrow">
                                                    <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/W.svg" alt="Icon - WhatsTool Tech" className="me-3" width="20px" height="20px" /></Link>
                                                    <Link target="_blank" to={{ pathname: "https://api.whatsapp.com/send?text=" + items }} ><img src="https://raw.githubusercontent.com/love-building-app/letsserve/3ae9e33a15dc7476134880703f0fdcb35d0147a1/prod/home/web_img/icons/WB.svg" alt="Icon - WhatsTool Tech" width="20px" height="20px" /></Link>
                                                </span>
                                            </li>
                                        </ul>
                                    )
                                })}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}
export const RecoverChat = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }, [])
    return (
        <Container className="features-page pb-4 ps-4 pe-4">
            <Row className="features-info">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <h1 className="mt-4 heading-text">Recover Deleted Messages</h1>
                        <h5 className="mt-4 sub-heading-text" style={{ lineHeight: '30px' }}>Get notified 👀 of deleted messages in WhatsApp chat &amp; recover them with the help 🧒 of WhatsTool.</h5>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <div>
                        <p className="text-center"><Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/recovery_chat_anim.json" className="items-lottie" ></Player></p>
                    </div>
                </Col>
            </Row>
            <Row className="features-how-it-works mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <h2 className="heading-text">How it works</h2>
                        <ol>
                            <li>Click on the Revover Icon.</li>
                            <li>Allow the permission.</li>
                            <li>And it will show all your deleted messages.</li>
                        </ol>
                    </div>
                </Col>
                <Col xxl={8} xl={8} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <p className="text-center mt-3">
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Recover WhatsApp Chat - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png" className="f_page_img mt-2 ms-2 me-2 mb-2" width="220px" height="350px" alt="Recover WhatsApp Chat - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/recover.png" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Recover WhatsApp Chat - WhatsTool Tech" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const ChatReport = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <Container className="features-page pb-4 ps-4 pe-4">
            <Row className="features-info">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <h1 className="mt-4 heading-text">Generate Chat Report</h1>
                        <h5 className="mt-4 sub-heading-text" style={{ lineHeight: '30px' }}>With this facility, you can analyze 🙋 how many conversations have taken place, which contact is 👨‍ chatting the most.</h5>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <div>
                        <p className="text-center"><Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/chat_report_anim.json" className="items-lottie" ></Player></p>
                    </div>
                </Col>
            </Row>
            <Row className="features-how-it-works mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <h2 className="heading-text">How it works</h2>
                        <ol>
                            <li>Export chat report on WhatsApp.</li>
                            <li>Select WhatsTool app to show the report.</li>
                            <li>Enjoy the generated chat report.</li>
                        </ol>
                    </div>
                </Col>
                <Col xxl={8} xl={8} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <p className="text-center mt-3">
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report_01.png" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Generate WhatsApp Chat Report - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report_02.png" className="f_page_img mt-2 ms-2 me-2 mb-2" width="220px" height="350px" alt="Generate WhatsApp Chat Report - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/report_03.png" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Generate WhatsApp Chat Report - WhatsTool Tech" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const StatusSaver = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <Container className="features-page pb-4 ps-4 pe-4">
            <Row className="features-info">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <h1 className="mt-4 heading-text">Download WhatsApp Status</h1>
                        <h5 className="mt-4 sub-heading-text" style={{ lineHeight: '30px' }}>This amazing feature lets users save and download the videos or images from other user’s WhatsApp status. With the help of inbuilt video and photo browser and editing features, you can create the status in the way you want.</h5>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <div><p className="text-center mt-3"><img src={f_status} className="f_page_img" width="220px" height="350px" alt="Download WhatsApp Status - WhatsTool Tech" /></p></div>
                </Col>
            </Row>
            <Row className="features-how-it-works mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <h2 className="heading-text">How it works</h2>
                        <ol>
                            <li>Open status saver.</li>
                            <li>Simply click on the Download Icon.</li>
                            <li>Same goes for Video Status.</li>
                        </ol>
                    </div>
                </Col>
                <Col xxl={8} xl={8} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <p className="text-center mt-3">
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status_01.jpeg" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Download WhatsApp Status - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status_02.jpeg" className="f_page_img mt-2 ms-2 me-2 mb-2" width="220px" height="350px" alt="Download WhatsApp Status - WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/status_03.jpeg" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Download WhatsApp Status - WhatsTool Tech" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export const VideoSpliter = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <Container className="features-page pb-4 ps-4 pe-4">
            <Row className="features-info">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <h1 className="mt-4 heading-text">Video Spliter</h1>
                        <h5 className="mt-4 sub-heading-text" style={{ lineHeight: '30px' }}>This inbuilt 😎 tool from this app lets users split long videos to the WhatsApp status video time limit provided and post long videos in multiple statuses.</h5>
                    </div>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <div><p className="text-center"><Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/video_spliter_anim.json" className="items-lottie" ></Player></p></div>
                </Col>
            </Row>
            <Row className="features-how-it-works mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <h2 className="heading-text">How it works</h2>
                        <ol>
                            <li>Click on the "Split Video" icon.</li>
                            <li>Select any video to split.</li>
                            <li>Lastly see the saved split videos.</li>
                        </ol>
                    </div>
                </Col>
                <Col xxl={8} xl={8} lg={4} md={4} sm={12} xs={12}>
                    <div className="mt-4">
                        <p className="text-center mt-3">
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor_01.jpeg" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Video Spliter Feature | WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor_02.jpeg" className="f_page_img mt-2 ms-2 me-2 mb-2" width="220px" height="350px" alt="Video Spliter Feature | WhatsTool Tech" />
                            <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/features/editor_03.jpeg" className="f_page_img mt-5 ms-2 me-2 mb-2" width="220px" height="350px" alt="Video Spliter Feature | WhatsTool Tech" />
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}