import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import GetAppIcon from '@mui/icons-material/GetApp';
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { getColour } from "../../constants/Colour";
import { getUrl } from "../../constants/Urls";


export const WtDesktop = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])

    return (
        <section>
            <WtDesktopInfo />
            <WhatsToolYoutubesection />
            <WtDesktopOne />
            <WtDesktopTwo />
            <WtDesktopThree />
            <PremiumWt />
        </section>
    )
}

export const WtDesktopInfo = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <section>
            <Container className="features-page bulk-message mb-3 ps-4 pe-4">
                <Row className="features-info">
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="pt-5">
                            <h1 className="mt-4 heading-text">WhatsTool Desktop</h1>
                            <h4 className="mt-3 mb-3 fw-normal" style={{ lineHeight: '30px', color: '#616874' }}>WhatsApp Bulk Messaging For Android & Windows Desktop</h4>
                            <h5 className="fw-normal" style={{ color: '#9EA4B5', fontSize: isMobile ? '18px' : '' }}>In WhatsTool Bulk Automatic Messaging you have full control with all features of <span style={{ color: '#616874' }}>Whom</span> you send,<span style={{ color: '#616874' }}>What</span> you send & <span style={{ color: '#616874' }}>How</span> you send.</h5>
                            <p className="mt-5 mb-5">
                                <Link to={{ pathname: getUrl.wtdPaymentUrl }} target='_blank'><span className="hand" ><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="img-fluid" width="165px" alt="Download WhatsTool " /></span></Link>
                            </p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className="mt-5 pt-2 video">
                            <img src={getUrl.WtamediaBaseURL + 'wt_desktop.png'} alt="Wt" className="img-fluid" />
                        </div>
                    </Col>
                </Row>
                <div className="text-center mt-5 mb-5">
                    <button onClick={handleShow} className="btn btn-sm text-white" style={{ background: '#128C7E', borderRadius: '5px', fontSize: isMobile ? '20px' : '28px', padding: isMobile ? '18px' : '10px 300px' }}><GetAppIcon style={{ fontSize: '30px' }} />Download Desktop Version</button>
                </div>
                <Modal className="modal-info-bulk-msg" show={show} onHide={handleClose} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <Container className="mt-3 mb-3 pt-5 pb-5 ps-3 pe-3">
                            <h4 className="text-center">If you don't have the License of <span className="theme">WhatsTool Desktop</span>,<br /> then apply for FREE TRIAL</h4><br /><br />
                            <p className="text-center">
                                <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSdIvVDtNyEecMC2smVNLncklwHQZCjT2BDT1COdFnL-27dDDQ/viewform?usp=sf_link" }} target="_blank" className="wbtn">Get FREE TRIAL <i className="fas fa-desktop"></i></Link>&nbsp;&nbsp;&nbsp;
                                <Link to={{ pathname: getUrl.wtdPaymentUrl }} target='_blank' className="wbtn">Buy Now <i className="fas fa-shopping-bag"></i></Link>
                            </p><br /><br />
                            <p className="text-center mb-4"><Link to={{ pathname: "https://whatstool.in/app/DesktopApp/WhatsToolSetup.msi" }} target="_blank" className="bulk-wbtn pt-3 pb-3 pe-4 ps-4" download><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></Link></p>
                            {/* <p className="text-center" onClick={handleOffOn}><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/offer_for_app_new.png"} width="350px" className="img-fluid rounded-3 mb-4" /></p> */}
                            <h5 className="text-center mt-5 mb-5">How to Use: <Link to={{ pathname: "https://youtu.be/UIpowWU9A9g" }} target="_blank">Watch Video</Link></h5>
                            <p style={{ fontSize: "12px" }}><b>*Important:</b> One Email ID for 1 Device (is allowed).</p>
                            <p style={{ fontSize: "12px" }}><b>*Instruction:</b> Extract the Zip File and Click the Icon to Open it. <br /><b>Preview:</b> How to Open the WhatsTool Desktop.</p>
                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/instruction.png"} alt="WhatsTool Tech" className="img-fluid mt-3 shadow-lg" width="60%" /></p>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Container>
        </section >
    )
}

export const WhatsToolYoutubesection = () => {
    return (
        <Container className=" pb-5 pt-5">
            <Row className="mt-5 pt-2 video text-center">
                <Col>
                    <h5 className="fw-bold text-center theme pb-4">for Desktop Demo</h5>
                    <p className="text-center mb-5"><iframe width="60%" height="400" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                </Col>
            </Row>
        </Container>
    )
}

export const WtDesktopOne = () => {
    return (
        <div style={{ background: '#E6F6F0' }} className="pt-5 pb-5">
            <Container fluid>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <img src={getUrl.WtamediaBaseURL + 'wt_import.png'} alt="Wtb tool" className="img-fluid" />
                            </div>
                        </Col>

                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="text-center" style={{ paddingTop: isMobile ? '40px' : '120px' }}>
                                <h6 style={{ fontSize: '30px' }} className="fw-semibold">Whom you Send</h6>
                                <p className="pt-3" style={{ fontSize: isMobile ? '18px' : '24px' }}>Import the contacts with whom you want to send the broadcast</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export const WtDesktopTwo = () => {
    return (
        <div style={{ background: '#138073' }} className="pt-5 pb-5">
            <Container fluid>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="text-center text-white" style={{ paddingTop: isMobile ? '30px' : '85px' }}>
                                <h6 style={{ fontSize: '30px' }} className="fw-semibold">What you Send</h6>
                                <p className="pt-3" style={{ fontSize: isMobile ? '18px' : '24px' }}>Type message or select media that you want to send as a broadcast</p>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <img src={getUrl.WtamediaBaseURL + 'wt_add_message.png'} alt="Wtb tool" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export const WtDesktopThree = () => {
    return (
        <div style={{ background: '#E6F6F0' }} className="pt-5 pb-5">
            <Container fluid>
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div>
                                <img src={getUrl.WtamediaBaseURL + 'wt_contact.png'} alt="Wtb tool" className="img-fluid" />
                            </div>
                        </Col>

                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div className="text-center" style={{ paddingTop: isMobile ? '30px' : '145px' }}>
                                <h6 style={{ fontSize: '30px' }} className="fw-semibold">How you Send</h6>
                                <p className="pt-3" style={{ fontSize: isMobile ? '18px' : '24px' }}>You can send Broadcast message with added delay & Enable Anti-Ban Bulk sending status</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export const PremiumWt = () => {

    let usdVal = 82
    const dYrP_Inr = 950
    const dLtP_Inr = 4499

    const [showM, setShowM] = useState(false);
    const handleCloseM = () => setShowM(false);
    const handleShowM = () => setShowM(true);

    const dYrP_Usd = Math.floor(dYrP_Inr / usdVal)
    const dLtP_Usd = Math.floor(dLtP_Inr / usdVal)

    const selectCurrency = (e) => {
        if (e.target.value === "inr") {
            setDesYrPrice(dYrP_Inr)
            setDesLtPrice(dLtP_Inr)
            setDesCurType("₹")
            setDesCurr("desInr")
        }

        else if (e.target.value === "usd") {
            setDesYrPrice(dYrP_Usd)
            setDesLtPrice(dLtP_Usd)
            setDesCurType("$")
            setDesCurr("desUsd")
        }
    }

    const desktopPlan = (e) => {
        console.log(e.target.value)
        if (e.target.value === "desktop_yearly") {
            setDesPriceType("for one year")
            if (desCurr === "desInr") {
                setDesFinalPrice(dYrP_Inr)
            } else if (desCurr === "desUsd") {
                setDesFinalPrice(dYrP_Usd)
            }

        } else if (e.target.value === "desktop_lifetime") {
            setDesPriceType("for lifetime")
            if (desCurr === "desInr") {
                setDesFinalPrice(dLtP_Inr)
            } else if (desCurr === "desUsd") {
                setDesFinalPrice(dLtP_Usd)
            }
        }
    }

    const [desCurr, setDesCurr] = useState("desInr")
    const [desCurType, setDesCurType] = useState("₹")
    const [desYrPrice, setDesYrPrice] = useState(dYrP_Inr)
    const [desLtPrice, setDesLtPrice] = useState(dLtP_Inr)
    const [desFinalPrice, setDesFinalPrice] = useState(dLtP_Inr)


    const [desPriceType, setDesPriceType] = useState("for one year")

    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <section>
            <Container className="premium pb-5">
                <div>
                    <h4 className="text-center sub-heading-text mt-5">Premium Plan</h4>
                    <div className="mt-5 mb-5">
                        <p className="text-center">
                            <Form.Check inline label="Indian Rupee" name="currency" defaultChecked={true} type="radio" value="inr" id="inr" onChange={(e) => selectCurrency(e)} />
                            <Form.Check inline label="US Dollar" name="currency" type="radio" value="usd" id="usd" onChange={(e) => selectCurrency(e)} />
                        </p>
                    </div>
                    <Form style={{ display: 'flex', cursor: 'pointer' }} className="justify-content-evenly pt-3">
                        <div className="mb-5" style={{ border: '2px solid #128C7E', borderRadius: '8px', padding: '15px' }}>
                            <Form.Check type="radio" id="desktop_yearly" className="mt-3">
                                <Form.Check.Input type="radio" name="desktop_plan" value="desktop_yearly" onChange={(e) => desktopPlan(e)} isValid />
                                <Form.Check.Label>&nbsp;&nbsp;Yearly</Form.Check.Label>
                                <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desCurType}<span style={{ fontSize: "30px", fontWeight: '500' }}>{desYrPrice}</span></Form.Control.Feedback>
                            </Form.Check>
                        </div>
                        <div className="mb-5" style={{ border: '2px solid #128C7E', borderRadius: '8px', padding: '15px' }}>
                            <Form.Check type="radio" id="desktop_lifetime" className="mt-3">
                                <Form.Check.Input type="radio" defaultChecked={true} name="desktop_plan" value="desktop_lifetime" onChange={(e) => desktopPlan(e)} isValid />
                                <Form.Check.Label>&nbsp;&nbsp;Lifetime</Form.Check.Label>
                                <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desCurType}<span style={{ fontSize: "30px", fontWeight: '500' }}>{desLtPrice}</span></Form.Control.Feedback>
                            </Form.Check>
                        </div>
                    </Form>
                    <p className="text-center mt-5"><button style={{ background: '#128C7E', fontSize: '18px', padding: '5px 50px' }} onClick={handleShowM} className='btn btn-sm text-white'>Buy now</button></p>
                    <p className="mt-2 mb-3 text-center" style={{ fontSize: '12px', color: getColour.DescriptionTextColour }}>{desPriceType}</p>
                </div>
            </Container>
            <Modal className="modal-info-bulk-msg" show={showM} onHide={handleCloseM} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        <h3 className="theme text-center mt-5 ">Pay {desCurType}{desFinalPrice} for Desktop through</h3>
                        <p className="text-center theme mb-5">{desPriceType}</p>
                        <p className="theme text-center mb-2">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                        <div>
                            <hr />
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            {/* <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL +"/stripe_icon_full.svg"} width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link> */}
                            <Link to={{ pathname: "/wt-payment-method" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/Stripe_pay.svg"} width="200px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </section>
    )
}

