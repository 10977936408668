import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { isDesktop } from 'react-device-detect';
import { WtEvent } from '../basetool/Analttics';
import { getAnalyticsKey } from '../constants/Analyticskeys';
import { Homepagepricing } from '../basetool/Dialog';
import { getUrl } from '../constants/Urls';
const getStartedLink = "https://whatstool.in/business/signup"

export const Home = () => {
    return (
        <section className="home newHome">
            <Container className="hOne"><HomeOne /></Container>
            <Container className="clients" fluid><Client /></Container>
            <Container className="hTwo homeTwo" fluid><Container><HomeTwo /></Container></Container>
            <Container className="hThree"><HomeThree /></Container>
            <Container className="hFour homeFour" fluid><Container><HomeFour /></Container></Container>
            <Container className="hFive"><HomeFive /></Container>
            <Container className="hSix homeFour" fluid><Container><HomeSix /></Container></Container>
            <Container className="hSeven"><HomeSeven /></Container>
        </section>
    )
}
export const Client = () => {
    return (
        <Container className="pt-5 pb-5">
            <h3 className="text-center mb-4">Thousands of Business has trusted us in their growth</h3>
            <p className="text-center mb-5">Join the journey of Growth on WhatsApp</p>
            <Row>
                <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={12}>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/udaan.png"} width="60px" height="60px" alt="WhatsTool Business by WhatsTool Tech" /></p>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/badabusiness.png"} width="60px" height="60px" alt="WhatsTool Business by WhatsTool Tech" /></p>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/Iskcon.png"} width="60px" height="60px" alt="WhatsTool Business by WhatsTool Tech" /></p>
                        </Col>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/dearbehappy.png"} width="60px" height="60px" alt="WhatsTool Business by WhatsTool Tech" /></p>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}>
                    <h4 className="text-center" style={{ color: "#138073" }}>Join the list now</h4>
                </Col>
            </Row>

        </Container>
    )
}
export const HomeOne = () => {
    return (
        <Container className="pt-3 pb-5" fluid>
            <div className="mt-5">
                <p style={{ fontSize: '14px' }} className="tx1 mb-4">Powered by WhatsApp Official Business Api</p>
                <p style={{ fontSize: '24px' }} className="tx2">WhatsTool <b>solutions can</b><br />grow your business with WhatsApp</p>
            </div>
            <div style={{ marginTop: '-100px' }}>
                <img alt={''} src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_01.png"} className="img-fluid w-100" />
            </div>
        </Container>
    )
}
export const HomeTwo = () => {
    return (
        <Container className="p-5 pTwo" fluid>
            <Row className="pt-5 pTwo2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ fontSize: '30px', color: '#138073' }}>WhatsApp Power for your Business</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>WhatsApp Business API helps reach customers on the most<br />trusted app using WhatsTool platform in web and mobile.</p>
                    <Link to={{ pathname: getStartedLink }} target="_blank" className="btn btn-sm text-white shadow-lg mt-5" style={{ background: "#4C9D80" }}>Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt="WhatsToolX" src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_02.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeThree = () => {
    return (
        <Container className="p-5 pThree" fluid>
            <Row className="pt-5 pThree2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ fontSize: '30px', color: '#138073' }}>Work Together with<br />Multiple Agents on 1 number</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Your customers are on WhatsApp. All the time. So when they’re<br />looking to buy, and they message you, they’re at their moment<br />of highest intent. Engaging these visitors, get their attention<br />and move them along - on 1 number.</p>
                    <Link to={{ pathname: "https://whatstool.in/business/multi-agents" }} target="_blank" className="btn btn-sm text-white shadow-lg mt-5" style={{ background: "#4C9D80" }}>Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt="WhatsToolX" src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_03.png"} className="img-fluid homeRImg shadow-lg rounded-3" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFour = () => {
    return (
        <Container className="p-5 pFour" fluid>
            <Row className="pt-5 pFour2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ fontSize: '30px', color: '#138073' }}>Communicate, at scale<br />Broadcast Messages</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Customers who message you on WhatsApp are your most<br />engaged customers and prospects. Have personalized<br />conversations with them at scale – so you can engage, qualify,<br />and convert them in real time, day or night.</p>
                    <Link to={{ pathname: "https://whatstool.in/business/broadcast-message" }} target="_blank" className="btn btn-sm text-white shadow-lg mt-5" style={{ background: "#4C9D80" }}>Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="text-center"><img alt="WhatsToolX" src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_04.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeFive = () => {
    return (
        <Container className="p-5 pFive" fluid>
            <Row className="pt-5 pFive2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ fontSize: '30px', color: '#138073' }}>Build your Bot within a matter of minutes.<br />No codes required</h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Setup your Chatbot to interact with users just like any agent<br />would. Create, test, and run your no-code Chatbot. Set a<br />standard with the quality of customer support, engagement,<br />and interaction.</p>
                    <Link to={{ pathname: "https://whatstool.in/business/chatbots" }} target="_blank" className="btn btn-sm text-white shadow-lg mt-5" style={{ background: "#4C9D80" }}>Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt="WhatsToolX" src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_05.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeSix = () => {
    return (
        <Container className="p-5 pSix" fluid>
            <Row className="pt-5 pSix2">
                <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <h3 className="mb-4 headingTx" style={{ fontSize: '30px', color: '#138073' }}>Integration with other apps<br />and system of your need </h3>
                    <p className="descTx" style={{ fontSize: '15px', color: '#000' }}>Easily integrate with your existing CRM databases,<br />ecommerce stores, payment &amp; logistic partners.</p>
                    <Link to={{ pathname: getStartedLink }} target="_blank" className="btn btn-sm text-white shadow-lg mt-5" style={{ background: "#4C9D80" }}>Learn More</Link>
                </Col>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <p className="float-end"><img alt="WhatsToolX" src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wtx_website/images/wtx_06.png"} className="img-fluid homeRImg" /></p>
                </Col>
            </Row>
        </Container>
    )
}
export const HomeSeven = () => {
    return (
        <Container fluid>
            <Container className="homeSeven" fluid>
                <Container className="forWhatsApp">
                    <Row className="pt-3">
                        <Col><h5 style={{ color: "#138073" }}>we still have more to offer</h5></Col>
                        <Col><Link to={"/features"}><h5 className="float-end" style={{ color: "#138073" }}>View All<ChevronRightIcon style={{ color: "#138073", fontSize: '28px' }} className='pb-1' /></h5></Link></Col>
                    </Row>
                    <div style={{ display: isDesktop ? 'flex' : 'grid' }} className="pt-3">
                        <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/direct-chat-with-an-unsaved-number"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Direct<br />Chat</h5></div></Link></div>
                        <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/funny-messages"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Funny<br />Message</h5></div></Link></div>
                        <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/whatsapp-images-videos-status-downloader"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Save<br />Status</h5></div></Link></div>
                        <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/bulk-whatsapp-sender-android-app"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Bulk<br />Message</h5></div></Link></div>
                        <div style={{ flex: '1', marginRight: isDesktop ? '' : '14px' }}><Link className="text-dark" to={"/features/best-captions-for-instagram-post-and-whatsapp-status"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Captions<br />&nbsp;</h5></div></Link></div>
                    </div>
                </Container>
                <div className="hSevenB" style={{ padding: '30px 0' }}>
                    <div>
                        <h5>Bulk WhatsApp Messaging on mobile and Desktop</h5>
                        <p style={{ color: "#5B7D72" }}>For individual and small business in very cost effective way</p>
                        <h6 className="mt-4 greenTx" style={{ color: "#138073" }}>Easily send 100-1000 messages/ day using our mobile app</h6>
                        <p style={{ display: isDesktop ? 'flex' : 'grid' }} className='pt-5' >
                            <div className='pe-5'><Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.imageBaseURL + "/media/playstore_1.png"} className="pt-1 shadow-lg" width="165px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link></div>
                            <div><Link to={{ pathname: "https://whatstool.in/app/DesktopApp/WhatsToolSetup.msi" }} target="_blank" download><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="shadow-lg" width="165px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link></div>
                        </p>
                    </div>
                </div>
            </Container>
        </Container>
    )
}

export const Pricing = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    const PriceView = (props) => {
        return (
            <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mb-5" >
                <div>
                    {
                        (props.r === "yes") ? (
                            <div style={{ background: "#BE9257", borderRadius: "10px 10px 0px 0px" }}>
                                <h4 className="text-center text-white pt-2 pb-2">Recommended</h4>
                            </div>
                        ) : (
                            <div style={{ marginTop: "28px" }}>
                                &nbsp;
                            </div>
                        )
                    }
                    <div style={{ border: "1px solid " + props.bdrColor, background: props.bgColor, marginTop: "-10px" }} className={" pt-5 pb-5 rounded-3"}>
                        <h4 className="text-center">{props.pName}</h4>
                        <p className="text-center fw-bold mt-5" style={{ fontSize: "18px" }}>₹{props.price}</p>
                        <p className="text-center" style={{ fontSize: "12px" }}>per month (billed annually)</p>
                        <p className="text-center fw-bold" style={{ color: "#138073", fontSize: "30px" }}>+</p>
                        <p className="text-center fw-bold mt-3" style={{ fontSize: "18px" }}>{props.conversation}</p>
                        <p className="text-center" style={{ fontSize: "12px" }}>unique conversation*</p>
                        <p className="text-center fw-bold" style={{ color: "#138073", fontSize: "30px" }}>+</p>
                        <p className="text-center fw-bold">WhatsApp Charges</p>
                        <p className="text-center" style={{ marginTop: "0px", fontSize: "12px" }}><Link to={{ pathname: "https://developers.facebook.com/docs/whatsapp/pricing" }} target="_blank" style={{ color: "#5DD267" }}>check prices</Link> here(billed monthly) 1000 Free/Month</p>
                        <p className="text-center mt-5 mb-5"><Link to={{ pathname: getStartedLink }} target="_blank" className={"btn btn-md text-white"} style={{ background: "#138073" }}>Get Started</Link></p>
                        <p className="pe-5 ps-5">{props.desc}</p>
                        <p className="border-bottom text-center ms-5 me-5 mb-5">&nbsp;</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Unlimited Message</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Unlimited Agents</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Chat bots</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Marketing Broadcasts</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Quick Replies</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Custom Fields</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Automations</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Web Hooks</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; CRM</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Contact Import</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Canned Responses</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Customer Segmentation</p>
                        <p className="ps-5 pe-5"><i className="fas fa-check" style={{ color: "#5DD267" }}></i>&nbsp; Powerfull Filters</p>
                        <p className="border-bottom text-center ms-5 me-5 mb-5">&nbsp;</p>
                        <h5 className="ps-5 pe-5">Support</h5>
                        <p className="ps-5 pe-5">Call</p>
                        <p className="ps-5 pe-5">WhatsApp</p>
                        <p className="ps-5 pe-5">Email</p>
                    </div>
                </div>
            </Col>
        )
    }
    return (
        <Container className="mt-5 mb-5">
            <h4 style={{ color: "#5B7D72" }}>Pricing that helps grow your Business.</h4>
            <p>We care for you even if you have just started or running a big one. Caring is our culture.</p>
            <div className="mt-5 pt-5">
                <Row>
                    <PriceView bdrColor={"#C4C4C4"} bgColor={"rgba(230, 240, 228, 0.1)"} pName={"STARTER"} price={999} conversation={3000} desc={"Best for small scale businesses to support customers with conversational chatbot with less numbers of users."} />
                    <PriceView bdrColor={"#BE9257"} bgColor={"rgba(230, 240, 228, 0.1)"} pName={"SCALE"} price={2999} conversation={6000} desc={"When you have stated growing and very sure with your growth to handle in the best way."} r={"yes"} />
                    <PriceView bdrColor={"#C4C4C4"} bgColor={"rgba(230, 240, 228, 0.1)"} pName={"GROWTH"} price={5999} conversation={10000} desc={"Your business is running with thousands of customer and queries and you have to handle it at scale."} />
                </Row>
            </div>
            <div className="mt-5 mb-5">
                <h5 className="fw-bold mb-3">Add On</h5>
                <div style={{ lineHeight: "1" }}>
                    <p>₹0.75 paisa per extra additional users per month</p>
                    <p>WhatsApp Conversation Pricing is charged separately &amp; depends on receiver's country.</p>
                    <p>Green Tick Application is free</p>
                </div>
                <h5 className="fw-bold mb-3 mt-5">WhatsApp Conversation Charges</h5>
                <p>For India</p>
                <div className="mt-3">
                    <Row className="ps-3">
                        <Col xxl={3} xl={3} lg={3} md={3} sm={12} xs={12} className="border rounded-3 pt-3 pb-3">
                            <Row className="ps-3 pe-3">
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>Business Initiated Message</Col>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}><div className="float-end">₹0.4843</div></Col>
                            </Row>
                            <hr />
                            <Row className="ps-3 pe-3">
                                <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>User Initiated Message</Col>
                                <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}><div className="float-end">₹0.2906</div></Col>
                            </Row>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export const HomeLayoutOne = (props) => {
    const { GPSIcon_img, whatstoolAppLink, chat_bot, notification, crm, bulk_img, chat_img } = props


    const [showO, setShowO] = useState(false);
    const handleCloseO = () => setShowO(false);
    // const handleShowO = () => setShowO(true);

    const [openDialog, setOpenDialog] = useState({ open: false, onClose: null })
    const openDialogBox = () => {
        WtEvent(getAnalyticsKey.HomePlanpricingClicked)
        setOpenDialog({ open: true, onClose: () => setOpenDialog(false) })
    }
    return (
        <Container className="pt-4 pb-5 C_One" fluid>
            <Row>
                <Col className="left-view" xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                    <div>
                        <div className="main-text">
                            <h1><b>Limitless <br /> Possibilities</b><br /> <p className="for-you-on">for you on</p> <br /> <p className="blink fw-bold">WhatsApp</p></h1>
                        </div>
                        <div className="app-download mt-4">
                            <Link to={{ pathname: whatstoolAppLink }} target="_blank"><img src={GPSIcon_img} width="165px" alt="Download WhatsTool App Now - WhatsTool Tech" /></Link>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link to={{ pathname: getUrl.wtdPaymentUrl }} target='_blank'><span className="hand"><img src={getUrl.imageBaseURL + "/media/window_2.png"} className="img-fluid" width="165px" alt="Download WhatsTool " /></span></Link>
                            <div onClick={() => openDialogBox()}>
                                <img src={getUrl.bannerimgUrl} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-3 mt-2" />
                            </div>
                        </div>
                        {openDialog.open && <Homepagepricing open={openDialog.open} onClose={openDialog.onClose} />}
                    </div>
                </Col>
                <Col className="right-view" xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                    <div className="for-business mt-4">
                        <h5>Grow Your Business On WhatsApp</h5>
                        <Row className="mt-3">
                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2">
                                <Link to="/features/bulk-whatsapp-sender-android-app">
                                    <div className="container-fluid">
                                        <div className="items row">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Bullk WhatsApp Messages</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={bulk_img} alt="Bulk WhasApp Messages Feature - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2">
                                <Link to={{ pathname: "https://whatstool.in/business/" }} target="_blank">
                                    <div className="container-fluid">
                                        <div className="items row">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Live Chat Support</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={chat_img} alt="Live Chat - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2">
                                <Link to={{ pathname: "https://whatstool.in/business/" }} target="_blank">
                                    <div className="container-fluid">
                                        <div className="items row">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">A.I. Chatbots</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={chat_bot} alt="A.I. Chatbots - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2">
                                <Link to={{ pathname: "https://whatstool.in/business/" }} target="_blank">
                                    <div className="container-fluid">
                                        <div className="items row">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Automate Notification</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={notification} alt="Automate Notification - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6} className="mb-2">
                                <Link to={{ pathname: "https://whatstool.in/business/" }} target="_blank">
                                    <div className="container-fluid">
                                        <div className="items row">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">In-Built CRM</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={crm} alt="In-Built CRM - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={4} xl={4} lg={4} md={4} className="mb-2">
                                <div className="mt-4">
                                    <p className="text-center"><Link to={{ pathname: "https://whatstool.in/business/" }} target="_blank" className="btn btn-sm wbtn rounded-0">view more <i className="fas fa-angle-double-right"></i></Link></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Modal className="modal-info-bulk-msg" show={showO} onHide={handleCloseO} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        {/* <h3 className="theme text-center mt-5 ">Pay ₹899/$10.89 to Get Desktop App</h3> */}
                        {/* <p className="text-center theme mb-5">for Premium Users Only</p> */}
                        <p className="theme text-center mb-2 mt-5">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                        <hr />
                        <div style={{ display: "none" }}>
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                            <hr />
                        </div>


                        <Row className="mt-5">
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                <h4 style={{ color: "#635BFF" }} className="mt-2 text-center">Pay using Credit/Debit Card</h4>
                                <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/pb_stripe.png"} alt="WhatsTool Tech" width="100px" /></p>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}><p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/stripe.png"} alt="WhatsTool Tech" width="200px" /></p></Col>
                        </Row>
                        <Row className="mt-5">
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Yearly</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/6oE8wTfpt7ojcww4gh" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                            <Col>
                                <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                                    <h4 className="fw-bold">WhatsTool Premium</h4>
                                    <h6 className="fw-bold text-muted">Lifetime</h6>
                                    <Link to={{ pathname: "https://buy.stripe.com/aEUbJ50uz0ZV0NO5km" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Container>
    )
}
export const HomeLayoutTwo = (props) => {
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/clients.json')
        setItems(await response.json())
    }
    useEffect(() => { getItems() }, [])
    return (
        <section className="C_02 pb-5 mb-5 mt-5" fluid>
            <Container className="box shadow-lg p-5">
                <h3 className="text-center heading-text">Trusted by 2M+ customers big and small</h3>
                <p className="text-center sub-heading-text">We are on Mission to make Limitless Possibilities for you on WhatsApp &amp; Business</p>
                <Row className="mt-5">
                    {
                        items.map((el, i) => {
                            return (
                                <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="client-list mb-2" key={i}>
                                    <Link to={{ pathname: el.url }} target="_blank">
                                        <div className="client-list">
                                            <p className="text-center"><img src={el.image} alt={el.title + " - Clients - WhatsTool Tech"} /></p>
                                            <h6 className="text-center mt-2 desc-text">{el.title}</h6>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
            <Container className="mt-5 pb-5 pt-5">
                <Row className="mt-5 pt-2 video">
                    <Col>
                        <h5 className="fw-bold text-center theme mb-2">for Mobile Demo</h5>
                        <p className="text-center mb-5"><iframe width="80%" height="280" src="https://www.youtube.com/embed/3h-VDqoUEyg?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                    </Col>
                    <Col>
                        <h5 className="fw-bold text-center theme mb-2">for Desktop Demo</h5>
                        <p className="text-center mb-5"><iframe width="80%" height="280" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export const HomeLayoutThree = (props) => {
    const { business_sales_anim, download_img, funny_img, caption_img, chat_img, reply_img } = props
    return (
        <>
            <section className="C_01 pt-4 pb-5 C_One bg-personal-sec">
                <Container className="right-view pt-5 pb-5">
                    <div className="for-personal">
                        <h4>For Personal</h4>
                        <Row>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} className="mb-2">
                                <Link to="/features/direct-chat-with-an-unsaved-number">
                                    <div className="container-fluid">
                                        <div className="items row bg-white">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Direct Chat</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={chat_img} alt="Direct Chat Feature - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} className="mb-2">
                                <Link to="/features/whatsapp-images-videos-status-downloader" >
                                    <div className="container-fluid">
                                        <div className="items row bg-white">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Status Saver</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={download_img} alt="Status Saver - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} className="mb-2">
                                <Link to="/features/funny-messages">
                                    <div className="container-fluid">
                                        <div className="items row bg-white">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Funny Messages</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={funny_img} alt="Funny Message Feature - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} className="mb-2">
                                <Link to="/features/best-captions-for-instagram-post-and-whatsapp-status">
                                    <div className="container-fluid">
                                        <div className="items row bg-white">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Captions Status</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={caption_img} alt="Captions Status Feature - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={2} sm={6} xs={6} className="mb-2">
                                <Link to="/features/quick-reply">
                                    <div className="container-fluid">
                                        <div className="items row bg-white">
                                            <h6 className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">Quick Reply</h6>
                                            <p className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-center"><img src={reply_img} alt="Quick Reply Feature - WhatsTool Tech" /></p>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xxl={2} xl={2} lg={2} md={2} className="mb-2">
                                <div className="mt-4">
                                    <p className="text-center"><Link to="/features" className="btn btn-sm wbtn rounded-0">view more <i className="fas fa-angle-double-right"></i></Link></p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="C_03 pb-5 mb-5 pt-5">
                <Container fluid>
                    <Row>
                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                            <div className="info mt-5 mb-5">
                                <h3>
                                    Get down to<br />
                                    business and grow<br />
                                    Sales
                                </h3>
                                <p className="mt-4">
                                    Engage your customers and boost your business with WhatsTool's advanced features, yet easy‑to‑use marketing platform.
                                </p>
                                <p className="mt-5">
                                    <Link to="/features" className="btn btn-sm wbtn"><i className="far fa-compass"></i> Explore</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link to="/premium" className="text-warning">Compare Plan</Link>
                                </p>
                            </div>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                            <div className="anim">
                                <p className="text-center"><Player autoplay loop src={business_sales_anim} className="items-lottie" ></Player></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export const HomeLayoutFour = () => {
    return (
        <section className="C_04 pt-5 pb-5" style={{ backgroundColor: "#FBF9EF", }}>
            <Container fluid>
                <Row>
                    {
                        [...Array(4)].map((v, i) => {
                            i++
                            return (
                                <>
                                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4" key={i}>
                                        <div className="">
                                            <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/app_screenshot/" + i + ".png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" />
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }

                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <div className="">
                            <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d1.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" />
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <div className="">
                            <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d2.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" />
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <div className="">
                            <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/desktop/d3_opt.png"} className="img-fluid" alt="Download WhatsTool App - WhatsTool Tech - Limitless Possibilities" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export const HomeLayoutFive = (props) => {
    const { idea_anim } = props
    return (
        <section className="C_05 pb-5 mb-5">
            <Container className="box shadow-lg ps-5 pe-5 pt-3 pb-3">
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="info mt-5">
                            <h3>Let's do this</h3>
                            <p className="mt-4">
                                Every big idea with small step forward.<br />
                                "Nobody has the power to take two steps together, you can make only one step at a time."
                            </p>
                            <p className="mt-5">
                                <Link to="/premium" className="btn btn-sm pbtn"><i className="fas fa-crown"></i> Pick a Plan</Link>
                            </p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="anim">
                            <p className="text-center">
                                <Player autoplay loop src={idea_anim} className="items-lottie" ></Player>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export const OtherAppsInfo = (props) => {
    const UtilityApps_Data = [
        {
            "title": "WhatsSave",
            "url": "https://play.google.com/store/apps/details?id=com.whatstool.whatssave.exportcontact.autosave",
            "app_info": "https://whatstool.in/whatssave/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wsave_site/whatssave.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/whatssave_bg.png",
            "desc": "WhatsSave will solve your problem by automatically saving new customer 💾 , organise them and utilise them to grow your business.💹 💰",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
        {
            "title": "FREE GST Invoice!",
            "url": "https://play.google.com/store/apps/details?id=in.everybill.business",
            "app_info": "/ebill",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/site/bill.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/ebill_bg.jpeg",
            "desc": "EveryBill helps business win by gaining customers and payment on time with the complete solution of invoice, estimate and billing management! FREE !",
            "rating": "4.2",
            "install": "50K+",
            "bg_color": "#F0F4F9"
        },
        {
            "title": "Im. for Instagram",
            "url": "https://play.google.com/store/apps/details?id=igtool.toolsforinstagram.instagramphotodownloader.instagramvideodownloader.repost",
            "app_info": "/tools-for-instagram",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/gboxee.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/insta_bg.png",
            "desc": "Download REEL, Photo & Video Downloader for Instagram and Repost Instagram app can help you save Instagram photos and videos in simple steps. It is a collection of tools for Instagram with more than 10 features. It is super fast 🚀 and 100% free.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#D8E8EA"
        },
    ]
    const FinanceApps_Data = [
        {
            "title": "SIP Calculator",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.sipcalculator",
            "app_info": "/sip-calculator",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_bg.png",
            "desc": "This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#FBF9EF"
        },
        {
            "title": "EMI Calculator",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.emicalculator",
            "app_info": "/emi-calculator/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_bg.png",
            "desc": "EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
        {
            "title": "Retirement PLan",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.retirementplan",
            "app_info": "/retirement-plan/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/RetirementPlan_App/images/retirement_plan_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/RetirementPlan_App/images/retirement_plan_bg.png",
            "desc": "Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
    ]
    return (
        <section className="other_app_info pb-5 mb-5">
            <Container className="pt-3 pb-3" fluid>
                <Row>
                    <Col><h3 className="mb-4">Utility apps</h3></Col>
                    <Col><Link to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank" className="float-end btn btn-sm wbtn rounded-0 pt-0 pb-0 pe-2 ps-2">view more <i className="fas fa-angle-double-right"></i></Link></Col>
                </Row>
                <Row>
                    {
                        UtilityApps_Data.map((v, i) => {
                            return (
                                <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={6} className="mb-3">
                                    <Link to={{ pathname: v.url }} target="_blank">
                                        <div className="shadow-lg p-4">
                                            <p className="text-center"><img src={v.logo} width={100} className="img-fluid" alt={v.title} /></p>
                                            <p className="text-center heading-text mt-2">{v.title}</p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>

                <Row className="mt-5">
                    <Col><h3 className="mb-4">Finance apps</h3></Col>
                    <Col><Link to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank" className="float-end btn btn-sm wbtn rounded-0 pt-0 pb-0 pe-2 ps-2">view more <i className="fas fa-angle-double-right"></i></Link></Col>
                </Row>
                <Row>
                    {
                        FinanceApps_Data.map((v, i) => {
                            return (
                                <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={6} className="mb-3">
                                    <Link to={{ pathname: v.url }} target="_blank">
                                        <div className="shadow-lg p-4">
                                            <p className="text-center"><img src={v.logo} width={100} className="img-fluid" alt={v.title} /></p>
                                            <p className="text-center heading-text mt-2">{v.title}</p>
                                        </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

export const HomeLayoutSix = (props) => {
    const { business_anim, chatting_anim } = props
    return (
        <section className="C_04 pb-5 mb-5">
            <Container fluid>
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="">
                            <h3 className="mt-5">Businesses uses WhatsTool <br />to connect with customers.</h3>
                            <ul>
                                <li>Reach new customers in bulk</li>
                                <li>Target specific customer with  personalization</li>
                                <li>Auto save new phone call, new WhatsApp message</li>
                            </ul>
                            <p className="text-center mt-5 mb-5"><Link to="/features/bulk-whatsapp-sender-android-app/" className="btn btn-sm wbtn"><i className="far fa-compass"></i> Explore</Link></p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="items"><p className="text-center"><Player autoplay loop src={business_anim} className="items-lottie" ></Player></p></div>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5" fluid>
                <Row>
                    <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                        <div className="">
                            <h3 className="mt-4">WhatsApp users rely <br />for Status, Chats and Fun.</h3>
                            <ul>
                                <li>Save WhatsApp Status</li>
                                <li>Recover deleted messages</li>
                                <li>WhatsWeb on mobile</li>
                                <li>Split long video for WhatsApp status</li>
                            </ul>
                            <p className="text-center mt-5"><Link to="/features" className="btn btn-sm wbtn"><i className="far fa-compass"></i> Explore</Link></p>
                        </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                        <div className="items"><p className="text-center"><Player autoplay loop src={chatting_anim} className="items-lottie" ></Player></p></div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
