/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import { getUrl } from "../../constants/Urls";
import { getColour } from "../../constants/Colour";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Search, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, ListItemAvatar, Avatar } from '@mui/material'
import { Link } from "react-router-dom"
import CountryList from '../../thirdparty/CountryCode.json'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Close } from '@mui/icons-material'
import { getAnalyticsKey } from "../../constants/Analyticskeys";
import { WtEvent } from "../../basetool/Analttics";
import { WhatsAppAPIDialog } from "../../basetool/Dialog";
import { ShowSnackbar } from "../../basetool/BaseTool";

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} /> })

export const WhatsappAPI = () => {
    return (
        <section>
            <WhatsAppAPIInfo />
            {/* <Clients /> */}
            <WhatsappAPIOne />
            <WhatsappAPITwo />
            <WhatsappCustomers />
            <WhatsappAPIThree />
            <WhatsappAPIFAQ />
            <WhatsAppFooter />
        </section>
    )
}

export const WhatsAppAPIInfo = () => {

    const [mobilephone, setMobile] = useState('')
    const handleMobileNumber = (e) => {
        let checkedval = e?.replace(/[^0-9]/ig, '')
        setMobile(checkedval)
    }

    const [countrycodeLs, setCountrycodeLs] = useState(CountryList)

    const [searchLs, setSearchLs] = useState([])
    useEffect(() => { setSearchLs(countrycodeLs) }, [countrycodeLs])
    const SearchItems = (sv) => {
        const filterdata = CountryList.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filterdata)
    }

    const [countrycode, setCountryCode] = useState('+91')
    const handleCountryCode = (code) => {
        setCountryCode(code)
        handleClose()
    }

    // Snackbar Code
    const [errorMsg, setErrorMsg] = useState("")
    const [errorMsgBg, setErrorMsgBg] = useState(getColour.RedColor)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const SnackbarShow = (errorMsg, errorMsgBg) => {
        setErrorMsg(errorMsg)
        setErrorMsgBg(errorMsgBg)
        setOpenSnackbar(true)
    }
    const SnackbarClose = () => setOpenSnackbar(false)
    // End of Snackbar Code

    const [openReqDialog, setOpenReqDialog] = useState({ open: false, onClose: null })
    const openReqDialogBox = () => {
        setOpenReqDialog({
            open: true, onClose: () => {
                setOpenReqDialog(false)
            }
        })
    }

    const [opencountryDialog, setOpencountryDialog] = useState(false)
    const handleSelectionOpenDialog = () => setOpencountryDialog(true)
    const handleClose = () => setOpencountryDialog(false)
    const [name, setName] = useState('')
    const handleName = (val) => setName(val)
    const [companyName, setCompanyName] = useState('')
    const handleCompanyName = (val) => setCompanyName(val)

    const [email, setEmail] = useState('')
    const handleEmail = (e) => setEmail(e)
    const [website, setWebite] = useState('')
    const handleWebsite = (val) => setWebite(val)
    const [companySize, setCompanySize] = useState('')
    const handleCompanySize = (val) => setCompanySize(val)
    const [message, setMessage] = useState('')
    const handleMessage = (val) => setMessage(val)

    const [formStatus, setFormStatus] = useState(true)
    const validateForm = () => {
        if (name?.length !== 0 && companyName?.length !== 0 && mobilephone?.length !== 0 && email?.length !== 0 && website?.length !== 0 && companySize?.length !== 0) {
            setFormStatus(false)
        } else {
            setFormStatus(true)
        }
    }
    useEffect(() => {
        validateForm()
    }, [name, companyName, email, website, companySize, mobilephone])

    const handleFormSubmit = async () => {
        try {
            let data = `https://script.google.com/macros/s/AKfycby9pK-JB0AvLlg0ZyDcDLf0M2ZhdaKMXqTFnJE0q5ArynPacnRrKCbxePet26Ioy_4/exec?name=${name}&companyName=${companyName}&mobile=${countrycode}${mobilephone}&website=${website}&email=${email}&companySize=${companySize}&message=${message}`
            const response = await fetch(data)
            if (response) {
                WtEvent(getAnalyticsKey.WhatsAppAPIClicked)
                handleName('')
                handleCompanyName('')
                handleMobileNumber('')
                handleWebsite('')
                handleEmail('')
                handleCompanySize('')
                handleMessage('')
                setMobile('')
                openReqDialogBox()
                SnackbarShow('Request Submitted Successfully', getColour.BaseColor)
            }
        } catch (error) { }
    }

    return (
        < section >
            <Container>
                <Row>
                    <div className="text-center mt-5 mb-5">
                        <p style={{ fontSize: '38px' }} className="fw-bold">Automate <span style={{ color: getColour.BaseColor }}>conversational</span><br /><span style={{ color: getColour.BaseColor }}>engagement</span> on WhatsApp Business</p>
                    </div>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <p className="text-center"><LazyLoadImage src={getUrl.imageBaseURL + '/con_01.webp'} style={{ padding: isMobile ? '0' : '50px' }} alt="WhatsApp API" className="img-fluid" /></p>
                        </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div style={{ marginTop: isMobile ? '50px' : '' }}>
                            <input value={name} onChange={(e) => handleName(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px' }} type='text' placeholder="Name" />
                            <input value={companyName} onChange={(e) => handleCompanyName(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px' }} type='text' placeholder="Company Name" />

                            <div style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid' + getColour.DescriptionTextColour, borderRadius: '5px', display: 'flex' }}>
                                <span onClick={() => handleSelectionOpenDialog()} style={{ padding: '10px 7px 0 12px', cursor: 'pointer' }} >{countrycode}&nbsp;|</span>
                                <input value={mobilephone} onChange={(e) => handleMobileNumber(e.target.value)} style={{ width: isMobile ? '68%' : '86%', outline: 'none', border: 'none' }} placeholder='WhatsApp Number' type='tel' required maxLength={15} />
                            </div>

                            <input value={email} onChange={(e) => handleEmail(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px' }} type='email' placeholder="Email Address" />
                            <input value={website} onChange={(e) => handleWebsite(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '0 15px' }} type='text' placeholder="Website" />

                            <Form.Select value={companySize} onChange={(e) => handleCompanySize(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '12mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', color: 'gray' }} >
                                <option value=''>Company Size</option>
                                <option value="1 - 10 employees">1 - 10 employees</option>
                                <option value="11 - 50 employees">11 - 50 employees</option>
                                <option value="51 - 200 employees">51 - 200 employees</option>
                                <option value="201 - 500 employees">201-500 employees</option>
                            </Form.Select>

                            <textarea value={message} onChange={(e) => handleMessage(e.target.value)} style={{ width: isMobile ? '100%' : '80%', height: '24mm', outline: 'none', marginTop: '17px', border: '1px solid gray', borderRadius: '5px', padding: '10px 15px' }} type='text' placeholder="Message (Optional)">message</textarea>

                            <button onClick={() => handleFormSubmit()} disabled={formStatus ? true : false} className="text-white btn btn-sm ps-4 pe-4 pt-2 pb-2 fw-semibold rounded-3" style={{ background: getColour.BaseColor, marginTop: '17px' }}>Request a Callback</button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div>
                <Dialog open={opencountryDialog} fullWidth={true} maxWidth={"sm"} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
                    <DialogTitle style={{ background: getColour.BorderLineColour, color: getColour.DescriptionTextColour }}>Select Country Code <IconButton className='float-end hand' onClick={() => handleClose()}><Close /></IconButton></DialogTitle>
                    <DialogContent>
                        <section style={{ margin: '12px' }}>
                            <input placeholder='Select Country Code' type='search' onChange={(e) => SearchItems(e.target.value)} style={{ width: '100%', outline: 'none', padding: '10px 20px', border: '1px solid ' + getColour.BorderColour, borderRadius: '30px' }} />

                            <div style={{ height: '60vh', overflowY: 'auto' }}>
                                {
                                    searchLs?.map((v, i) => {
                                        return (
                                            <section onClick={() => handleCountryCode(v.dial_code)} style={{ padding: '6px', margin: '10px', border: '1px solid ' + getColour.LineColor, borderRadius: '4px', cursor: 'pointer' }} key={i} >
                                                <div style={{ display: 'flex' }}>
                                                    <ListItemAvatar><Avatar>{v.flag}</Avatar></ListItemAvatar>
                                                    <p style={{ margin: '0 10px' }}><span style={{ fontWeight: 'bold' }}>{v.dial_code}</span>&nbsp;&nbsp;{v.name}</p>
                                                </div>
                                            </section>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </DialogContent>
                    <br />
                    <hr />
                    <section className='dialog_footer' style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <button style={{ color: getColour.HeadingTextColour, background: getColour.BorderLineColour, padding: '4px 20px' }} onClick={() => handleClose()} className="btn btn-sm">Close</button>
                        </div>
                        <div>&nbsp;</div>
                    </section>
                </Dialog>
            </div>

            <ShowSnackbar open={openSnackbar} errorMsg={errorMsg} errorMsgBg={errorMsgBg} close={SnackbarClose} />
            {openReqDialog.open && <WhatsAppAPIDialog open={openReqDialog.open} onClose={openReqDialog.onClose} />}

        </section >
    )
}
export const WhatsappAPIOne = () => {
    return (
        <div>
            <Container>
                <div style={{ marginTop: '100px' }}>
                    <Row>
                        <div style={{ marginBottom: '40px' }}>
                            <p style={{ fontSize: '38px' }} className="text-center fw-bold">How to get WhatsApp Business<br /> API for <span style={{ color: getColour.BaseColor }}>FREE</span> with WhatsTool?</p>
                        </div>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} style={{ marginTop: isMobile ? "25px" : '98px', marginBottom: isMobile ? "40px" : '' }}  >
                            <div className="p-2" style={{ cursor: 'pointer', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
                                <p className=" ">
                                    <LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/08/www-img.png" alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pt-2 ms-3">A BUSINESS WEBSITE
                                    <p className="pt-2" style={{ color: getColour.DescriptionTextColour, fontSize: '14px' }}>A Business Website is a must to get access to the WhatsApp Business API</p></h6>
                            </div>
                            <div className="p-2" style={{ cursor: 'pointer', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
                                <p className=" ">
                                    <LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/08/faceboook-logo.webp" alt="Wtb" style={{ width: '70px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pt-2 ms-3">APPROVED FACEBOOK BUSINESS MANAGER
                                    <p className="pt-2" style={{ color: getColour.DescriptionTextColour, fontSize: '14px' }}>You should have your Facebook Business Manager Account Verified to get complete access to WhatsApp Business API!</p></h6>
                            </div>
                            <div className="p-2" style={{ cursor: 'pointer', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
                                <p className=" ">
                                    <LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/08/whatsapplogo-2036x2048.webp" alt="Wtb" style={{ width: '70px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pt-2 ms-2">FRESH PHONE NUMBER
                                    <p className="pt-2" style={{ color: getColour.DescriptionTextColour, fontSize: '14px' }}>You need an active phone number that isn't currently registered with any of the WhatsApp accounts.</p></h6>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div>
                                <LazyLoadImage src={getUrl.imageBaseURL + '/con_02.webp'} alt="Whatstool Business" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
export const WhatsappAPITwo = () => {

    return (
        <div>
            <Container>
                <div style={{ marginTop: '100px' }}>

                    <div style={{ marginBottom: '40px' }}>
                        <p style={{ fontSize: '38px' }} className="text-center fw-bold">WhatsTool WhatsApp<br /> Business <span style={{ color: getColour.BaseColor }}>API</span></p>
                    </div>
                    <Row style={{ marginTop: '90px' }}>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <div onClick={() => window.open('https://whatstool.business/marketing', '_self')} className="p-2" style={{ border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', display: 'flex', alignItems: 'baseline' }}>
                                <p className=" ">
                                    <LazyLoadImage src='https://whatstool.business/wp-content/uploads/2023/09/Group-4076.png' alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pe-2 fw-bold">WhatsApp Marketing Tool</h6>
                            </div>
                            <div onClick={() => window.open('https://whatstool.business/whatsapp-crm', '_self')} className="p-2 mt-2 mb-2" style={{ border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', display: 'flex', alignItems: 'baseline' }}>
                                <p className=" ">
                                    <LazyLoadImage src='https://whatstool.business/wp-content/uploads/2023/09/Group-4087.png' alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pe-2 fw-bold">WhatsApp-based CRM</h6>
                            </div>
                            <div onClick={() => window.open('https://blog.whatstool.business/whatsapp-automation/', '_blank')} className="p-2 mt-2 mb-2" style={{ border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', display: 'flex', alignItems: 'baseline' }}>
                                <p className=" ">
                                    <LazyLoadImage src='https://whatstool.business/wp-content/uploads/2023/09/Group-4077.png' alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pe-2 fw-bold">WhatsApp Automation</h6>
                            </div>
                            <div onClick={() => window.open('https://whatstool.business/chatbots', '_self')} className="p-2 mt-2 mb-2" style={{ border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', display: 'flex', alignItems: 'baseline' }}>
                                <p>
                                    <LazyLoadImage src='https://whatstool.business/wp-content/uploads/2023/09/Group-4079.png' alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pe-2 fw-bold">Chatbot Flow</h6>
                            </div>
                            <div onClick={() => window.open('https://whatstool.business/whatsapp-multi-agent', '_self')} className="p-2" style={{ border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', display: 'flex', alignItems: 'baseline' }}>
                                <p className=" ">
                                    <LazyLoadImage src='https://whatstool.business/wp-content/uploads/2023/09/Group-4084.png' alt="Wtb" style={{ width: '50px', height: 'auto' }} className='img-fluid' />
                                </p>
                                <h6 className="ps-2 pe-2 fw-bold">Multi-Agent Team Inbox</h6>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} >
                            <div style={{ marginTop: isMobile ? "40px" : '' }}>
                                <LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/09/lead-Generation-1.png" alt="Whatstool Business" className="img-fluid" style={{ padding: isMobile ? '0' : '50px' }} />
                            </div>
                        </Col>
                    </Row>
                    <p className="text-center" style={{ marginTop: '95px' }}>
                        <Link to={{ pathname: getUrl.appProductionUrl }} target='_blank'><button className="btn btn-sm fw-semibold" style={{ color: '#fff', padding: '6px 50px ', fontSize: '18px', background: getColour.BaseColor, border: '1px solid' + getColour.BaseColor }}>Start 15 Day trial</button></Link>
                    </p>
                </div>
            </Container >
        </div >
    )
}
export const WhatsappAPIThree = () => {
    const PlatformManyIndustries = [
        { image: getUrl.imageBaseURL + '/con_20.webp', info: 'E-commerce', title: 'Promote Your products, share catalogs, receive orders online and provide customer support 24/7' },
        { image: getUrl.imageBaseURL + '/con_21.webp', info: 'Education Institutions', title: 'From Admit, Onboard, Engage, Give Updates, Solve Queries to Customer Support it helps Educational Institutions improve Admission Rates' },
        { image: getUrl.imageBaseURL + '/con_22.webp', info: 'Healthcare & Medicals', title: 'Go the extra mile by providing your patients and customers the ability to reach out to you 24/7 and at every step of their journey' },
        { image: getUrl.imageBaseURL + '/con_23.webp', info: 'Food and Restaurants', title: 'Revolutionize your restaurant operations with WhatsApp based ordering, meal and add-ons, payments and real-time delivery updates.' },
        { image: getUrl.imageBaseURL + '/con_24.webp', info: 'Tours & Travels', title: 'Assist, Coordinate, Recommend & Plan for your customers journey and make it a perfect trip by providing customer support ' },
        { image: 'https://whatstool.business/wp-content/uploads/2023/09/Banking-comp.jpeg', info: 'Banking & Finance', title: "Explore the world of banking & finance with us! From managing your money to making smart investments, we're here to guide you." }
    ]
    return (
        <div>
            <Container fluid>
                <Container>
                    <div style={{ marginTop: '65px' }} className='ps-3 pe-3 '>
                        <Row>
                            <div style={{ marginBottom: '40px' }}>
                                <p style={{ fontSize: '38px' }} className="text-center fw-bold"><span style={{ color: '#00A82D' }}>One</span> Platform – <span style={{ color: '#00A82D' }}>Many</span> Industries</p>
                            </div>
                            {
                                PlatformManyIndustries.map((v, i) => {
                                    return (
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                                            <div className="text-center mb-3 p-4" style={{ marginTop: isMobile ? '0' : '70px', border: '1px solid #21cd5b', cursor: 'pointer', borderRadius: '12px', boxShadow: '0 0 4px 2px rgb(0 0 0 / 25%)', minHeight: isMobile ? '420px' : '375px', maxHeight: isMobile ? '' : '375px' }} key={i}>
                                                <p >
                                                    <LazyLoadImage src={v.image} alt="Break Language" style={{ width: 'auto', height: '130px' }} />
                                                </p>
                                                <div>
                                                    <p style={{ fontSize: '26px' }} className='fw-bold mb-4'>{v.info}</p>
                                                    <p style={{ fontSize: '18px', fontWeight: '400', fontStyle: 'normal' }} >{v.title}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Container>
            </Container>

        </div>
    )
}
export const WhatsappAPIFAQ = () => {

    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const pricingAccordian = [
        { t: 'What is WhatsApp Business API', d: "WhatsApp Business API is a communication platform offered by WhatsApp, a popular messaging application owned by Facebook (now Meta Platforms, Inc.), that allows businesses to interact with their customers on the WhatsApp platform at scale. It is designed to enable medium and large businesses to send and receive messages with customers, automate responses, and integrate WhatsApp into their customer support and communication workflows." },
        { t: 'How Can I Get WhatsAPP Business API', d: "To get WhatsApp Business Programming interface, you really want to interface with a WhatsApp Accomplice like WhatsTool. With WhatsTool, you can make a Free WhatsApp Business Programming interface Record and attempt it in a 15-Days Free trial." },
        { t: 'Can I Use WhatsApp Apps & WhatsApp Business API on the Same Number', d: "No, you can't! The number which is dynamic on WhatsApp Business Programming interface can't be enrolled on WhatsApp Applications. Likewise, on the off chance that a number is as of now enlisted on WhatsApp Applications, you should erase that WhatsApp Record to enroll for WhatsApp Business Programming interface." },
        { t: 'Can I send automated message using WhatsApp Business API', d: "You can robotize WhatsApp messages to your clients like invite messages, request/installment affirmations, deserted truck notices, application drop-off notices and more by associating your CRM/Business Programming with WhatsTool." },
        { t: 'Which one is better:WhatsApp Business or WhatsApp Business API', d: "While WhatsApp Business is custom fitted for independent ventures and individual proprietors, WhatsApp Business Programming interface is worked for medium to enormous organizations with huge message volumes. In any case, in the event that you need to: Use WhatsApp on numerous gadgets, Send WhatsApp broadcasts to north of 1,00,000 clients in one go, Computerize WhatsApp Chatbot, and Send interactive buttons with messages, then, at that point, pick WhatsApp Business Programming interface." },
    ]

    // Search Handling

    const [searchLs, setSearchLs] = useState(pricingAccordian)
    const handleSearchLs = (sv) => {
        const filteredData = pricingAccordian?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Search Handling
    return (
        <section style={{ margin: '80px 0' }}>
            <Container>
                <div>
                    <div className="text-center">
                        {/* <p className="fw-semibold" style={{ fontSize: '32px', color: getColour.HeadingTextColour }}>FAQs</p> */}
                        <div style={{ marginBottom: '40px' }}>
                            <p style={{ fontSize: '38px' }} className="text-center fw-bold">Frequently <span style={{ color: getColour.BaseColor }}>asked questions</span></p>
                        </div>
                        <p>Here are some frequently asked questions</p>
                    </div>
                    <center>
                        <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '55px 0' }}>
                            <span style={{ padding: '8px' }}><Search /></span>
                            <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
                        </div>
                    </center>
                    {
                        searchLs?.map((v, i) => {
                            return (
                                <Accordion style={{ border: "none", boxShadow: "none" }} expanded={expanded === 'panel-l-' + i} onChange={handleChange('panel-l-' + i)} key={i}>
                                    <AccordionSummary expandIcon={<ExpandMore className="bRound" style={{ color: getColour.BaseColor, background: getColour.BorderLineColour, }} />}>
                                        <Typography className="fw-bold" sx={{ fontSize: "17px", width: '90%' }}>{v.t}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography dangerouslySetInnerHTML={{ __html: v.d }} style={{ fontSize: '18px', color: getColour.DescriptionTextColour }}></Typography>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </Container>
        </section >
    )
}
export const WhatsappCustomers = () => {
    return (
        <section>
            <Container>
                <Row>
                    <div className="text-center mt-5 mb-5">
                        <p style={{ fontSize: '38px' }} className="fw-bold">Customers <span style={{ color: getColour.BaseColor }}>loves Us</span></p>
                    </div>
                    <div>
                        <Link to={{ pathname: 'https://www.g2.com/products/whatstool-business/reviews' }} target='_blank'><p className="text-center"><LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/09/images-1-1.png" style={{ height: '45px' }} alt="WhatsApp API" className="img-fluid" /></p></Link>
                    </div>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div>
                            <p className="text-center"><LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/09/Group-4154.png" style={{ paddingTop: isMobile ? '0' : '45px', borderRadius: '12px' }} alt="WhatsApp API" className="img-fluid" /></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
export const WhatsAppFooter = () => {

    const myStyle = { backgroundImage: "url('https://whatstool.business/wp-content/uploads/2023/09/Api_celebration.png')", height: '100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginBottom: '50px' }
    return (
        <section style={myStyle}>
            <Container fluid>
                <Container style={{ padding: '120px 0' }}>
                    <div className="text-center text-white">
                        <h1> You can apply & get FREE <br />WhatsApp API with WhatsTool in just 10 minutes </h1>
                    </div>
                    <div style={{ padding: '50px 0' }}>
                        <Link to={{ pathname: getUrl.appProductionUrl }} target='_blank'><p className="text-center"><LazyLoadImage src="https://whatstool.business/wp-content/uploads/2023/09/solutions.png" style={{ height: '45px' }} alt="WhatsApp API" className="img-fluid" /></p></Link>
                    </div>
                    <p className="text-center" >
                        <Link to={{ pathname: getUrl.appProductionUrl }} target='_blank'><button className="btn btn-sm fw-semibold" style={{ color: '#fff', padding: '6px 50px ', fontSize: '18px', background: getColour.BaseColor, border: '1px solid' + getColour.BaseColor }}>Start 15 Day trial</button></Link>
                    </p>
                </Container>
            </Container>
        </section>
    )
}