import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useMediaQuery } from 'react-responsive'
import { Player } from '@lottiefiles/react-lottie-player'
import { getUrl } from '../constants/Urls'

export const MoreTools = (props) => {
  const { atr_val } = props
  const [items, setItems] = useState([]);
  const getItems = async () => {
    const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/more_tools.json')
    setItems(await response.json())
  }
  useEffect(() => { getItems() }, [])
  return (
    <Container className="more-tools pb-5 mb-5 mt-5">
      <h3 className="text-center heading-text mb-4">More Tools</h3>
      <Row>
        {
          items.map((v, i) => {
            return (
              <>
                {
                  ((v.atr).trim() === atr_val) ? ("") : (
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-3" key={i}>
                      <Link to={v.url}>
                        <div className="tools-box p-3" style={{ border: "1px solid #128c7e" }}>
                          <p className="text-center">
                            <img src={v.image} width="40px" className="img-fluid" alt="World Best Free Premium Tools Offered by WhatsTool Tech for WhatsApp" />
                          </p>
                          <h6 className="text-center mt-3 sub-heading-text">{v.title}</h6>
                        </div>
                      </Link>
                    </Col>
                  )
                }
              </>
            )
          })
        }
      </Row>
    </Container>
  )
}

// export const Testimonials = () => {
//   var settings = {
//     className: "center",
//     centerMode: true,
//     autoplay: true,
//     pauseOnHover: true,
//     speed: 500,
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           className: "center",
//           autoplay: true,
//           centerMode: true,
//           pauseOnHover: true,
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           infinite: true,
//         }
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           className: "center",
//           autoplay: true,
//           centerMode: true,
//           pauseOnHover: true,
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           className: "center",
//           autoplay: true,
//           centerMode: true,
//           pauseOnHover: true,
//           slidesToShow: 1,
//           slidesToScroll: 1
//         }
//       }
//     ]
//   };
//   return (
//     <div className="testimonials">
//       <Slider {...settings}>
//         {
//           [...Array(15)].map((v, i) => {
//             i++
//             return (
//               <div key={i}>
//                 <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/user_rating/rating" + i + ".png"} className="img-fluid border border-dark" width="250px" alt="User Rating - WhatsTool Tech" /></p>
//               </div>

//             )
//           })
//         }
//       </Slider>
//     </div>
//   )
// }

export const OfferModal = (props) => {
  const { siteName } = props
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-0">
        <Container className="p-0" fluid>
          <p className="text-center"><Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/festive_sales.png" className="img-fluid" height="auto" alt={siteName} /></Link></p>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export const BulkMsgModal = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 850px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {
        isDesktopOrLaptop &&
        <>
          <p className="text-center mt-5 mb-5">
            <button onClick={handleShow} className="bulk-wbtn pt-3 pb-3 pe-4 ps-4">Start Bulk Messaging <i className="fab fa-whatsapp"></i></button>
          </p>
          <Modal className="modal-info-bulk-msg" show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
              <Container>
                <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/not_found_anim.json" className="items-lottie" ></Player>
                <h4 className="text-center">Desktop mode is coming soon...</h4>
                <p className="text-center">
                  If you want you get information whenever we launch bulk messaging tool for desktop mode.
                </p>
                <p className="text-center mt-5 mb-5"><Link to={{ pathname: "https://api.whatsapp.com/send?phone=+919031239260&text=Hi WhatsTool Team, Please let me know whenever the desktop version gets available for bulk messaging on WhatsApp." }} target="_blank" className="bulk-wbtn pe-4 ps-4">Please Contact us <i className="fab fa-whatsapp"></i></Link></p>
                <hr />
                <p className="app-download text-center mt-5 mb-5">
                  <h6 className="text-center mb-3">You can send Bulk Messages on WhatsApp by using Our <span className="theme"><b>"WhatsTool"</b></span> App, Get it on PlayStore</h6>
                  <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.imageBaseURL + "/media/playstore_1.png"} width="165px" alt="Download WhatsTool App Now - WhatsTool Tech" /></Link>
                </p>
              </Container>
            </Modal.Body>
          </Modal>
        </>
      }
      {
        isTabletOrMobile &&
        <p className="text-center mt-5 mb-5">
          <Link to={{ pathname: "https://whatstool.in/features/bulk-whatsapp-sender-android-app" }} target="_blank" className="bulk-wbtn pt-4 pb-4 pe-4 ps-4">Start Bulk Messaging <i className="fab fa-whatsapp"></i></Link>
        </p>
      }
    </>
  )
}

export const ResellerBtn = () => {
  return (
    <p className="text-center mt-5 mb-5">
      <button className="bulk-wbtn pt-3 pb-3 pe-4 ps-4">Become a Reseller</button>
    </p>
  )
}
