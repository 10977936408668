import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Carousel, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { isDesktop, isMobile } from "react-device-detect"
import { Clients } from "../../UtilsBox/GrowthBusiness";
import { ChevronRight } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Accordion from 'react-bootstrap/Accordion';
import { faqSchema } from "../../thirdparty/Schema";
import { getColour } from "../../constants/Colour";
import { getUrl } from "../../constants/Urls";
import { Tooltip } from "@mui/material";

export const HomeApp = () => {

    const uref = useRef(null);
    const handleClick = () => {
        uref?.current?.scrollIntoView({ bottom: 0, left: 0, behavior: 'smooth' });
    };
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <section>
            <HomeOne />
            <HomeTwo handleClick={handleClick} />
            <Clients />
            <section className="home newHome hSeven"><HomeThree uref={uref} /></section>
            <WhatsToolYoutubesection />
            <HomeFive />
            <HomeSix />
            <WhatsToolYoutubesectionTwo />
            <Container><BlogViewHome /></Container>
            <Container><Testimonials /></Container>
            <Faq atr_val={'all'} />
        </section>
    )
}

export const HomeOne = () => {

    return (
        <section style={{ background: '#128C7E', height: isMobile ? '360px' : '335px' }}>
            <Container fluid>
                <div className="text-white text-center" style={{ paddingTop: '70px' }}>
                    <h1>Limitless Possibilities for you on WhatsApp</h1>
                    <p style={{ fontSize: '18px' }}>Get Whatstool now and Grow your business to next Level</p>
                    {/* <div className="app-download hand">
                        <Link className="text-dark" to={"/features/bulk-whatsapp-sender-android-app"} target="_blank">
                            <img src={getUrl.bannerimgUrl} width="700px" alt="WhatsTool Tech" className="img-fluid rounded-2 mt-3 pt-2" />
                        </Link>
                    </div> */}
                </div>
            </Container>
        </section>
    )
}
export const HomeTwo = ({ handleClick }) => {

    const Possibilities = [
        { images: getUrl.WtamediaBaseURL + 'whatstool_logo.png', title: 'WhatsTool', description: 'WhatsApp Bulk messaging, Extract WhatsApp group number', Url1: '/wthome-App', btnone: 'Explore', btntwo: 'Download' },
        { images: getUrl.imageBaseURL + '/wtb_green_round_images.webp', title: 'WhatsTool Business', description: 'WhatsApp marketing with official WhatsApp API', Url1: 'https://whatstool.business/', btnone: 'Explore' },
        { images: 'https://user-images.githubusercontent.com/130449582/234252220-8b389bcd-db75-4e9d-adb1-911e363638c7.png', title: 'WhatsTool Desktop', description: 'Send WhatsApp bulk messaging on window desktop', Url1: '/desktop-App', Url2: 'https://storage.googleapis.com/wt_resources/wtb_media/WhatsToolSetup.msi', btnone: 'Explore', btntwo: 'Download', target: '_parent' },
        // { images: getUrl.WtamediaBaseURL + 'notify.png', title: 'Notify Me', description: 'Get Notified on WhatsApp. Never miss important update', Url1: 'https://2notify.me/', btnone: 'Explore' }
    ]
    return (
        <section style={{ marginBottom: '105px', padding: isMobile ? '' : '0 80px' }}>
            <Container fluid>
                <Row>
                    {
                        Possibilities?.map((v, i) => {
                            return (
                                <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                                    <div style={{ boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)", padding: '25px', borderRadius: '8px', margin: '-88px 4px 0 4px', width: isDesktop ? '380px' : '330px', minHeight: '350px', background: '#FFF' }} className="text-center Hometwomargin Homemobmargin">
                                        <p className="pb-4">
                                            <img src={v.images} style={{ height: '50px' }} alt='wtb' className="img-fluid" />
                                        </p>
                                        <h5>{v.title}</h5>
                                        <p className="pt-2" style={{ textAlign: 'initial', color: '#646464', fontWeight: '500' }} dangerouslySetInnerHTML={{ __html: v.description }}></p>
                                        <div className="pt-5 pb-2" style={{ display: v.btntwo ? 'flex' : '', justifyContent: 'space-evenly' }}>
                                            <p className="fw-semibold">
                                                <Link to={{ pathname: v.Url1 }} target="_parent">
                                                    <button className="rounded-3 btn btn-sm fw-semibold ps-4 pe-4 pt-2 pb-2" style={{ fontSize: '16px', whiteSpace: 'nowrap', background: "#EFFFED", color: getColour.BaseColor }}>{v.btnone}</button>
                                                </Link>
                                            </p>
                                            <p className="fw-semibold" >
                                                <Link to={{ pathname: v.Url2 }} target={v.target} >
                                                    {v.btntwo ? <button className="rounded-3 btn btn-sm fw-semibold text-white ps-4 pe-4 pt-2 pb-2" onClick={() => handleClick()} style={{ background: getColour.BaseColor, fontSize: '16px', whiteSpace: 'nowrap' }}>{v.btntwo}</button> : ''}
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section >
    )
}
export const HomeThree = ({ uref }) => {
    const [appDetails, setappDetails] = useState('')
    const [playstore, setplaystore] = useState('')

    async function getappDetails() {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": 'GET'

            }
        };

        fetch("https://storage.googleapis.com/wt_resources/wtb_media/updatedetails.json", requestOptions)
            .then(response => response.json())
            .then(result => {
                setappDetails(result.appDetails)
                setplaystore(result?.playstoreDetails)
            })
            .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getappDetails()
    })

    return (
        <section>
            <Container className="homeSeven" fluid>
                <Container>
                    <div style={{ display: 'flex', alignItems: 'end', margin: '55px 0' }}>
                        <p>
                            <img src={getUrl.WtamediaBaseURL + 'whatstool_logo.png'} style={{ height: '50px' }} alt="Whatstool " className="img-fluid me-4" />
                        </p>
                        <h3 style={{ color: '#646464' }}>WhatsTool</h3>
                    </div>
                    <div className="forWhatsApp">
                        <Row className="pt-3">
                            <Col><h5 style={{ color: "#138073" }}>we still have more to offer</h5></Col>
                            <Col><Link to={"/features"}><h5 className="float-end" style={{ color: "#138073" }}>View All<ChevronRight style={{ color: "#138073", fontSize: '28px' }} className='pb-1' /></h5></Link></Col>
                        </Row>
                        <div style={{ display: isDesktop ? 'flex' : 'grid' }} className="pt-3">
                            <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/bulk-whatsapp-sender-android-app"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Bulk<br />Message</h5></div></Link></div>
                            <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/direct-chat-with-an-unsaved-number"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Direct<br />Chat</h5></div></Link></div>
                            <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/funny-messages"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Funny<br />Message</h5></div></Link></div>
                            <div style={{ flex: '1', marginRight: '14px' }}><Link className="text-dark" to={"/features/whatsapp-images-videos-status-downloader"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Save<br />Status</h5></div></Link></div>
                            <div style={{ flex: '1', marginRight: isDesktop ? '' : '14px' }}><Link className="text-dark" to={"/features/best-captions-for-instagram-post-and-whatsapp-status"}><div className="boxBg"><h5 className="ps-5 pt-4 pb-4">Captions<br />&nbsp;</h5></div></Link></div>
                        </div>
                    </div>
                    <div className="hSevenB ms-3" style={{ padding: '30px 0' }}>
                        <div>
                            <h5 ref={uref}>Bulk WhatsApp Messaging on mobile and Desktop</h5>
                            <p style={{ color: "#5B7D72" }}>For individual and small business in very cost effective way</p>
                            <h6 className="mt-4 greenTx" style={{ color: getColour.DescribeColour }}>Easily send 100-1000 messages/ day using our mobile app</h6>
                            <div style={{ display: 'flex', alignItems: 'baseline', cursor: 'pointer', justifyContent: 'end', flexWrap: 'wrap' }} className="justify">
                                <h5 style={{ color: getColour.DescribeColour }}>Download from</h5>
                                <p style={{ margin: '12px 22px' }}>
                                    <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.BaseimageUrl + '/playstore_1.png'} className="mb-2 shadow-lg dImg" width="145px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link><br />
                                    <Tooltip title={playstore} placement="bottom"><span className="ms-3">Version 3.15.0</span></Tooltip>
                                </p>
                                <p>
                                    <Link to={{ pathname: getUrl.appDirectDownloadUrl }} target="_blank"><img src={getUrl.BaseimageUrl + '/apk_download_01.png'} style={{ height: '45px', borderRadius: '10px' }} className="mb-2 shadow-lg dImg" width="145px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link><br />
                                    <Tooltip title={appDetails} placement="bottom"><span className="ms-2">Version 5.2.0</span></Tooltip>
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <div className="pt-5" style={{ borderBottom: '2px solid' + getColour.BorderLineColour }}></div>
        </section>
    )
}
export const WhatsToolYoutubesection = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <section>
            <Container className=" pb-5 pt-5">
                <div style={{ display: 'flex', alignItems: 'end', marginBottom: '75px' }}>
                    <p>
                        <img src="https://user-images.githubusercontent.com/130449582/234252220-8b389bcd-db75-4e9d-adb1-911e363638c7.png" style={{ height: '50px' }} alt="Whatstool " className="img-fluid me-4" />
                    </p>
                    <h3 style={{ color: '#646464' }}>WhatsTool Desktop</h3>
                </div>
                <Row className="pt-2 video text-center">
                    <Col>
                        <h5 className="fw-bold text-center mb-5">Watch Demo Video to understand WhatsTool Desktop</h5>
                        <p className="text-center mb-5"><iframe width="60%" height="400" src="https://www.youtube.com/embed/UIpowWU9A9g?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                    </Col>
                </Row>
                <p className="pb-5">
                    <Link onClick={handleShow}><img src={getUrl.BaseimageUrl + '/window_2.png'} className="mb-2 shadow-lg dImg float-end" width="145px" alt="WhatsToolX - Powered by WhatsTool Tech" /></Link>
                </p>
            </Container>
            <Modal className="modal-info-bulk-msg" show={show} onHide={handleClose} size="xs" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mt-3 mb-3 pt-5 pb-5 ps-3 pe-3">
                        <h4 className="text-center">If you don't have the License of <span className="theme">WhatsTool Desktop</span>,<br /> then apply for FREE TRIAL</h4><br /><br />
                        <p className="text-center">
                            <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSdIvVDtNyEecMC2smVNLncklwHQZCjT2BDT1COdFnL-27dDDQ/viewform?usp=sf_link" }} target="_blank" className="wbtn">Get FREE TRIAL <i className="fas fa-desktop"></i></Link>&nbsp;&nbsp;&nbsp;
                            <Link to={"/premium"} className="wbtn">Buy Now <i className="fas fa-shopping-bag"></i></Link>
                        </p><br /><br />
                        <p className="text-center mb-4"><Link to={{ pathname: "https://storage.googleapis.com/wt_resources/wtb_media/WhatsToolSetup.msi" }} target="_blank" className="bulk-wbtn pt-3 pb-3 pe-4 ps-4" download><i className="fas fa-download"></i> Download Desktop Version <i className="fas fa-desktop"></i></Link></p>
                        {/* <p className="text-center" onClick={handleOffOn}><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/offer_for_app_new.png"} width="350px" className="img-fluid rounded-3 mb-4" /></p> */}
                        <h5 className="text-center mt-5 mb-5">How to Use: <Link to={{ pathname: "https://youtu.be/UIpowWU9A9g" }} target="_blank">Watch Video</Link></h5>
                        <p style={{ fontSize: "12px" }}><b>*Important:</b> One Email ID for 1 Device (is allowed).</p>
                        <p style={{ fontSize: "12px" }}><b>*Instruction:</b> Extract the Zip File and Click the Icon to Open it. <br /><b>Preview:</b> How to Open the WhatsTool Desktop.</p>
                        <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/instruction.png"} alt="WhatsTool Tech" className="img-fluid mt-3 shadow-lg" width="60%" /></p>
                    </Container>
                </Modal.Body>
            </Modal>
            <p>
                <hr style={{ height: '2px' }} />
            </p>
        </section >
    )
}
export const HomeFive = () => {

    const Products = [
        { image: getUrl.WtamediaBaseURL + 'whatstool_logo.png', prodname: 'WhatsTool', title: 'Starts with 950/Yearly', Url: getUrl.appDirectDownloadUrl, describe: 'Suitable for WhatsApp Users and Individual/Small Business running business on WhatsApp ', btn: 'Get WhatsTool Now' },
        { image: getUrl.imageBaseURL + '/wtb_green_round_images.webp', prodname: 'WhatsTool Business', title: 'Starts with 999/Month', Url: 'https://play.google.com/store/apps/details?id=com.whatstool.business', describe: 'Suitable for Registered Business who wants to scale their sales and support on WhatsApp ', btn: 'Get WhatsTool Business Now' },
        { image: 'https://user-images.githubusercontent.com/130449582/234252220-8b389bcd-db75-4e9d-adb1-911e363638c7.png', prodname: 'WhatsTool Desktop', title: 'Starts with 950/Yearly', Url: 'https://storage.googleapis.com/wt_resources/wtb_media/WhatsToolSetup.msi', describe: 'Suitable for Registered Business who wants to scale their sales and support on WhatsApp ', btn: 'Get WhatsTool Desktop Now' },
    ]
    return (
        <section>
            <Container>
                <div>
                    <h3 className="mt-5 mb-5" style={{ color: '#646464' }}>Check out the Features of our Products</h3>
                </div>
                <Row className="mb-3">
                    {
                        Products?.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} key={i}>
                                    <div>
                                        <p style={{ background: '#00a82d', height: '11px' }}></p>
                                        <div style={{ padding: '28px' }}>
                                            <div style={{ display: 'flex', alignItems: 'end' }} className="pb-4">
                                                <p>
                                                    <img src={v.image} style={{ height: '50px' }} alt="Whatstool " className="img-fluid me-3" />
                                                </p>
                                                <h3>{v.prodname}</h3>
                                            </div>
                                            <div style={{ border: '1px solid #F0F2F8', borderRadius: '8px' }} className="p-3">
                                                <p className="fw-semibold pb-4" style={{ fontSize: '20px' }}>{v.title}</p>
                                                <p className="fw-semibold" style={{ color: '#616874', fontSize: '14px' }}>{v.describe}</p>
                                            </div>
                                            <p className="text-center pt-4">
                                                <Link to={{ pathname: v.Url }} target='_blank'>
                                                    <u className="WtHomeHover" style={{ color: 'gray' }}><h6 className="WtHomeHover" role="button">{v.btn}</h6></u>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}
export const HomeSix = () => {
    return (
        <section className="Blogs">
            <Container>
                <Row className="mb-2">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">Direct Chat without saving number in App</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px', color: 'gray' }} className="fw-bold">Direct Chat without saving number in App</p>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#2BBB51', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Bulk Broadcast: <br />WhatsApp message using WhatsApp Android App</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#149E39', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Broadcast using WhatsApp Api: <br />Send to more then 100000 messages in a click</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#066F22', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Broadcast using WhatsApp Web: <br />Send broadcast message using WhatsApp web</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px', minHeight: '111px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">WhatsApp Status Saver: <br />Save WhatsApp Status </p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px', minHeight: '111px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px', color: 'gray' }} className="fw-bold">Multi Agent Chat Support: <br />Multiple people are able to chat with customers on a simple WhatsApp number</p>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#2BBB51', padding: '40px', minHeight: '111px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">WhatsWeb on Android App: <br />Run multiple WhatsApp account</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#149E39', padding: '40px', minHeight: '111px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Chat bot: <br />Build chat bot to automatically reply relevant answer to users</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px', minHeight: '90px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">Split Video</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px', minHeight: '90px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px', color: 'gray' }} className="fw-bold">Integrations: <br />with many other apps and channels </p>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#2BBB51', padding: '40px', minHeight: '90px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Quick Reply</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#149E39', padding: '40px', minHeight: '90px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Quick Reply: <br />Use in multi agent chat support</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">WhatsApp Chat Report </p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px', color: 'gray' }} className="fw-bold">CRM</p>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#2BBB51', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Captions for Status </p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#149E39', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Web Hooks</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">Funny Messages </p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4 margin">
                            <p style={{ fontSize: '14px', color: 'gray' }} className="fw-bold">Multi Channel Support </p>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#2BBB51', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold text-white">Recover Deleted Message</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div style={{ background: '#F0F2F8', padding: '40px' }} className="pt-4 pb-4">
                            <p style={{ fontSize: '14px' }} className="fw-bold">Create Status </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export const WhatsToolYoutubesectionTwo = () => {
    return (
        <section>
            <Container className=" pb-5 pt-5">
                <Row className="pt-2 video text-center">
                    <Col>
                        <h5 className="fw-bold text-center mb-5">Watch videos about Whatstool </h5>
                        <p className="text-center mb-5"><iframe width="60%" height="400" src="https://www.youtube.com/embed/3h-VDqoUEyg?controls=0" title="Bulk Sender" className="shadow-lg rounded-3" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export const BlogViewHome = () => {

    var blogJSON_two = [
        { "blogName": "Why choose WhatsApp Broadcast Message instead of Email/SMS", "blogstitle": "WhatsApp Broadcast Messages, Email, and SMS are all communication tools that businesses and individuals can use to reach their target audience", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/business_blog.png", "blogUrl": "https://blog.whatstool.business/why-choose-whatsapp-broadcast-message-instead-of-email-sms/" },
        { "blogName": "How to send Interactive WhatsApp Messages with Action Button", "blogstitle": "Template Messages are pre-approved transactional messages that allow you to initiate conversations with your customers, or continue a user-initiated conversation", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/Blog_02.png", "blogUrl": "https://blog.whatstool.business/how-to-send-interactive-whatsapp-messages-with-action-button/" },
        { "blogName": "Why All Direct to Consumer D2C Businesses Should Have a WhatsApp Number.", "blogstitle": "The D2C market has seen a revival in the current age, especially during the COVID pandemic", "blogThumbnail": "https://storage.googleapis.com/wt_resources/wtb_media/Blogs_01.png", "blogUrl": "https://blog.whatstool.business/why-all-direct-to-consumer-d2c-businesses-should-have-a-whatsapp-number/" }
    ]
    return (
        <div className="blogView p-5 mb-5 text-center">
            <p style={{ color: getColour.DescriptionTextColour, fontSize: "20px", fontWeight: "500" }} className='pb-4'>Read blogs to know more about Whatstool Business</p>
            <div className="blogList">
                <Row>
                    {
                        blogJSON_two.map((v, i) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={12} key={i}>
                                    <div className="p-2">
                                        <Link to={{ pathname: v.blogUrl }} target="_blank">
                                            <div style={{ border: '1px solid #ccc', borderRadius: '8px', minHeight: '500px' }}>
                                                <img src={v.blogThumbnail} style={{ width: "100%", height: "225px", borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }} alt='wtb' className="img-fluid" />
                                                <div className='p-3' style={{ textAlign: 'initial' }}>
                                                    <p className="mt-3" style={{ color: '#273041', fontSize: "18px", fontWeight: "500" }}>{v.blogName}</p>
                                                    <p className="mt-3" style={{ color: '#616874', fontSize: "16px", fontWeight: "500" }}>{v.blogstitle}<b>...</b></p>
                                                    <div style={{ display: 'flex' }} className="mt-3">
                                                        <p className="mt-2" style={{ color: '#B8BDCC', fontSize: "14px", fontWeight: "500", flex: '1' }}>2 min read</p>
                                                        <p className="mt-2" style={{ color: getColour.BaseColor, fontSize: "14px", fontWeight: "500", flex: '1', textAlign: 'end' }}>Read More</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row >
            </div >
        </div >
    )
}
export const Testimonials = () => {

    const testimonialSchema = [
        { feedback: "Kapila is in cattle feeding business from 25 years and we wanted some solid solution for our 2 Millions farmers. So we approached WhatsTool. <br/>We recently implemented WhatsTool Business's WhatsApp Marketing solution and have been very pleased with the results. We've seen an increase in customer engagement, response times, and overall satisfaction with our communications.", name: 'Saurabh Shivhare', designation: 'Chief Executive', planInfo: 'Plan: Enterprise', category: 'Cattle Feed Industry, India', banner: getUrl.imageBaseURL + '/tes_ind_kapilagro_banner.png', logo: getUrl.imageBaseURL + "/tes_ind_kapilagro.png" },
        { feedback: "Our company were operating all manually. We wanted to go with WhatsApp for automating our booking for our cleaning services. WhatsTool team not only made us automate they made our success by integrating with our existing system and increased our booking by 12%. We are very happy so far.", name: 'Nishant Prasad', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Cleaning Service, India', banner: getUrl.imageBaseURL + '/tes_ind_cleanfanatic_banner.png', logo: getUrl.imageBaseURL + "/tes_ind_cleanfanatic.png" },
        { feedback: "Whatstool is definitely one of the most affordable & intuitive platform. We could go live within a day and the team helped us in building the chatbot ! I definitely recommend the solution to startups !", name: 'Tharun Sai E', designation: 'Founder', planInfo: 'Plan: Enterprise', category: 'Mental Health Support, India', banner: getUrl.imageBaseURL + '/tes_ind_findhope_banner.png', logo: getUrl.imageBaseURL + "/tes_ind_findhope.png" },
        { feedback: "We wanted solution for our kids who are learning through our app. We got amazed with WhatsApp API solution provided by WhatsTool. Their team support is amazing ,they always make sure to deliver best for us. We wanted chatbot to handle our learning segment and the flexibility in their chatbot functionality helps deliver what we needed. Highly recommended.", name: 'Gaurav Chowdhary', designation: 'Co-founder', planInfo: 'Plan: Growth', category: 'EdTech Company, India', banner: getUrl.imageBaseURL + '/tes_ind_kico_banner.png', logo: getUrl.imageBaseURL + "/tes_ind_kico.png" },
    ]

    return (
        <Container className="mt-5 mb-5 pb-5">
            <h3 className="text-center mb-5" style={{ color: '#29bc64' }}>There's Word <span className="fw-bold" style={{ color: '#00A82D' }}>About Us</span> all Around</h3>
            <div className="testimonials">
                <Carousel variant="dark">
                    {
                        testimonialSchema?.map((v, i) => {
                            return (
                                <Carousel.Item key={i}>
                                    <Row className="bg-white">
                                        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4}><img className="mainSideImg shadow-lg float-end" src={v.banner} style={{ height: "500px", width: '80%', borderRadius: '8px', boxShadow: '3px 6px 24px rgba(0,0,0,.11)' }} alt='clients' /></Col>
                                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8}>
                                            <div className="tesBody ps-5 pt-4">
                                                <p style={{ width: "80%", fontSize: "16px", color: '#616874' }} dangerouslySetInnerHTML={{ __html: v.feedback }}></p>
                                                <div style={{ display: 'flex', marginTop: '40px' }}>
                                                    <div><img src={v.logo} style={{ width: 'auto', height: '60px' }} className="img-fluid logo" alt='clients' /></div>
                                                    <div className="ms-3">
                                                        <h3 className="fw-bold" style={{ fontSize: "24px", color: '#3F4A5F' }}>{v.name}</h3>
                                                        <div style={{ lineHeight: '40px' }} className="lineheight">
                                                            <p style={{ fontSize: "18px", color: '#3F4A5F' }}>{v.designation}</p>
                                                            <p style={{ fontSize: "15px", color: '#3F4A5F', marginTop: '-11px' }}>{v.planInfo}</p>
                                                            <p style={{ fontSize: "15px", color: '#3F4A5F', marginTop: '-11px' }}>{v.category}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </Container>
    )
}
export const Faq = (props) => {
    const { atr_val } = props

    // Search Handling
    const [SearchLs, setSearchLs] = useState(faqSchema)
    const handleSearchLs = (sv) => {
        const filteredData = faqSchema?.filter((item) => { return Object.values(item).join('').toLowerCase().includes(sv.toLowerCase()) })
        setSearchLs(filteredData)
    }
    // End of Search Handling

    return (
        <section className="mt-5 mb-5">
            <Container>
                <div className="text-center">
                    <p className="fw-semibold" style={{ fontSize: '32px' }}>FAQ</p>
                    <p>Here are some frequently asked questions</p>
                </div>
                <center>
                    <div style={{ display: 'flex', border: '1px solid #B8BDCC', borderRadius: '5px', padding: '0 15px', width: isDesktop ? '65%' : 'auto', height: '12mm', margin: '50px 0' }}>
                        <span style={{ padding: '8px' }}><SearchIcon /></span>
                        <input type="search" onChange={(e) => handleSearchLs(e.target.value)} placeholder="Type your question here" style={{ border: 'none', outline: 'none', width: '100%' }} />
                    </div>
                    {SearchLs.length === 0 && <p className="alert alert-danger" role="alert">No result found</p>}
                </center>
                <Accordion defaultActiveKey="01">
                    {
                        SearchLs?.map((el, i) => {
                            return (
                                <>
                                    {
                                        (atr_val === el.tag || atr_val === "all") ? (
                                            <Accordion.Item eventKey={el.id}>
                                                <Accordion.Header>
                                                    <Container fluid>
                                                        <Row>
                                                            <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>{i + 1}. {el.question}</Col>
                                                            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}><div className="float-end"><Link to={{ pathname: el.url }} target="_blank" style={{ whiteSpace: 'nowrap' }} className="btn btn-sm btn-info text-white pt-0 pb-0 ps-2 pe-2">read more</Link></div></Col>
                                                        </Row>
                                                    </Container>
                                                </Accordion.Header>
                                                <Accordion.Body dangerouslySetInnerHTML={{ __html: el.answer }}></Accordion.Body>
                                            </Accordion.Item>
                                        ) : ('')
                                    }
                                </>
                            )
                        })
                    }
                </Accordion>
            </Container>
        </section>
    )
}

