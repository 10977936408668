class WtColors {
    colourFunc() {
        this.BaseColor = '#00A82D'

        this.MainBgColour = '#EFFFED'
        this.BorderLineColour = '#F0F2F8'

        this.HeadingTextColour = '#3F4A5F'
        this.DescriptionTextColour = '#616874'
        this.TextColour = '#273041'
        this.LightTextColour = '#9EA4B5'
        this.LightbackColour = '#F7F7F7'
        this.ConnectColour = '#128C7E'
        this.DescribeColour = '#138073'

    }
}
export const getColour = new WtColors()
getColour.colourFunc()