import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Carousel, Accordion, Button, Form, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Player } from '@lottiefiles/react-lottie-player'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { getUrl } from '../constants/Urls'

export const About = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <div className="about container pt-5 pb-5 mt-5 mb-5">
            <h5 className="text-center desc-text">We are on Mission</h5>
            <p className="text-center desc-text">to make</p>
            <h1 className="text-center heading-text tcolor">Limitless Possibilities</h1>
            <h6 className="text-center desc-text">for you on</h6>
            <h4 className="text-center desc-text">WhatsApp</h4>
            <p className="text-center desc-text">&amp;</p>
            <h4 className="text-center desc-text">Business</h4>
        </div>
    )
}
export const Clients = () => {
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/clients.json')
        setItems(await response.json())
    }
    useEffect(() => {
        getItems()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <div className="clients container pb-5 mb-5">
            <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/client_tree.json" className="items-lottie" ></Player>
            <h1 className="text-center heading-text">Trusted by 1.5M+ customers big and small</h1>
            <p className="text-center mb-5 sub-heading-text">We are on Mission to make Limitless Possibilities for you on WhatsApp &amp; Business</p>
            <div className="row mb-5 pb-5">
                {
                    items.map((el) => {
                        return (
                            <>
                                <Link to={{ pathname: el.url }} target="_blank" className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                                    <div className="client-list">
                                        <p className="text-center"><img src={el.image} alt={el.title + " - WhatsTool Tech"} /></p>
                                        <h6 className="text-center mt-2 desc-text">{el.title}</h6>
                                    </div>
                                </Link>
                            </>
                        )
                    })
                }
            </div>

            <Carousel className="clientTestimonials">
                <Carousel.Item>
                    <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/tournick_rating.png" className="img-fluid" alt="Ratings and Reviews - WhatsTool Tech" /></p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/dearbehappy_rating.png" className="img-fluid" alt="Ratings and Reviews - WhatsTool Tech" /></p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/cantademia_rating.png" className="img-fluid" alt="Ratings and Reviews - WhatsTool Tech" /></p>
                </Carousel.Item>
                <Carousel.Item>
                    <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/clients/tacoschonchos_rating.png" className="img-fluid" alt="Ratings and Reviews - WhatsTool Tech" /></p>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
export const FAQ = (props) => {
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/faq.json')
        setItems(await response.json())
    }
    useEffect(() => {
        getItems()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <>
            <Helmet
                title={"FAQs - " + props.siteFullName}
                meta={[
                    { "property": "og:title", "content": "FAQs - " + props.siteFullName },
                    { "property": "twitter:title", "content": "FAQs - " + props.siteFullName },
                    { "name": "keywords", "content": "faq of whatstool tech, faq of whatstool, faqs pf whasttool, question and answer of whatstool tech, " + props.commonKeyword },
                    { "name": "description", "content": "Find answers to all your questions related to our app on WhatsTool Tech. Know everything about Registering, User Experience, Pricing, Offers &amp; all other purchase related services to grow your business using Premium plan of WhatsTool Tech." },
                    { "property": "og:description", "content": "Find answers to all your questions related to our app on WhatsTool Tech. Know everything about Registering, User Experience, Pricing, Offers &amp; all other purchase related services to grow your business using Premium plan of WhatsTool Tech." },
                    { "name": "twitter:description", "content": "Find answers to all your questions related to our app on WhatsTool Tech. Know everything about Registering, User Experience, Pricing, Offers &amp; all other purchase related services to grow your business using Premium plan of WhatsTool Tech." },
                    { "property": "og:url", "content": props.pageURL },
                    { "name": "twitter:url", "content": props.pageURL }
                ]}
                link={[{ "rel": "canonical", "href": props.pageURL }]}
                script={[{
                    "type": "application/ld+json",
                    "innerHTML": `{
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [
                ${items.map((el) => `{ 
                    "@type": "Question","name": "${el.question}",
                    "acceptedAnswer": { "@type": "Answer", "text": "${el.answer}" }}`
                    )}
                ]
            }`
                }]}
            />
            <div className="faq container pt-4 pb-4">
                <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/faq_anim.json" className="items-lottie" ></Player>
                <Faq atr_val="all" />
            </div>
        </>
    )
}
export const Faq = (props) => {
    const { atr_val } = props
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/faq.json')
        setItems(await response.json())
    }
    useEffect(() => { getItems() }, [])
    return (
        <Accordion defaultActiveKey="01">
            {
                items.map((el, i) => {
                    return (
                        <>
                            {
                                (atr_val === el.tag || atr_val === "all") ? (
                                    <Accordion.Item eventKey={el.id}>
                                        <Accordion.Header>
                                            <Container fluid>
                                                <Row>
                                                    <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>{i + 1}. {el.question}</Col>
                                                    <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}><div className="float-end"><Link to={{ pathname: el.url }} target="_blank" className="btn btn-sm btn-info text-white pt-0 pb-0 ps-2 pe-2">read more</Link></div></Col>
                                                </Row>
                                            </Container>
                                        </Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: el.answer }}></Accordion.Body>
                                    </Accordion.Item>
                                ) : ('')
                            }
                        </>
                    )
                })
            }
        </Accordion>
    )
}
export const Gallery = () => {
    const [items, setItems] = useState([]);
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/yt_video.json')
        setItems(await response.json())
    }
    useEffect(() => {
        getItems()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <div className="gallery pb-5 mb-5">
            <h1 className="text-center mt-3 mb-5 heading-text">Our Gallery</h1>
            <Container>
                <Row>
                    {
                        items.map((el) => {
                            return (
                                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mb-3">
                                    <div className="video-grid">
                                        <p className="text-center"><iframe width="auto" height="auto" src={el.on_app_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}
export const OurApps = () => {
    const OurApps_Data = [
        {
            "title": "WhatsTool: Toolkit for WhatsApp & Business",
            "url": getUrl.appDirectDownloadUrl,
            "app_info": "https://whatstool.in",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/site/navlogo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/whatstool_bg.png",
            "desc": "We are on Mission to make Limitless Possibilities for you on WhatsApp & Business. WhatsTool App acts as a perfect tool 🔍  for WhatsApp, by providing top 👨‍🔧 features to enhance 👤 user experience with improved security 🔒 & more interactive messaging 📧  experience.",
            "rating": "4.5",
            "install": "2M+",
            "bg_color": "#FBF9EF"
        },
        {
            "title": "WhatsSave: Auto Save Number, Export WhatsApp Cont.",
            "url": "https://play.google.com/store/apps/details?id=com.whatstool.whatssave.exportcontact.autosave",
            "app_info": "https://whatstool.in/whatssave/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/wsave_site/whatssave.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/whatssave_bg.png",
            "desc": "WhatsSave will solve your problem by automatically saving new customer 💾 , organise them and utilise them to grow your business.💹 💰",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
        {
            "title": "FREE GST Invoice! Estimate, Account, Inventory App",
            "url": "https://play.google.com/store/apps/details?id=in.everybill.business",
            "app_info": "/ebill",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/site/bill.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/ebill_bg.jpeg",
            "desc": "EveryBill helps business win by gaining customers and payment on time with the complete solution of invoice, estimate and billing management! FREE !",
            "rating": "4.2",
            "install": "50K+",
            "bg_color": "#F0F4F9"
        },
        {
            "title": "WhatsTool Im for Instagram",
            "url": "https://play.google.com/store/apps/details?id=igtool.toolsforinstagram.instagramphotodownloader.instagramvideodownloader.repost",
            "app_info": "/tools-for-instagram",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/gboxee.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/our_apps_page/insta_bg.png",
            "desc": "Download REEL, Photo & Video Downloader for Instagram and Repost Instagram app can help you save Instagram photos and videos in simple steps. It is a collection of tools for Instagram with more than 10 features. It is super fast 🚀 and 100% free.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#D8E8EA"
        },
        {
            "title": "WhatsTool SIP Calculator",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.sipcalculator",
            "app_info": "/sip-calculator",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_bg.png",
            "desc": "This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#FBF9EF"
        },
        {
            "title": "WhatsTool EMI Calculator",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.emicalculator",
            "app_info": "/emi-calculator/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_bg.png",
            "desc": "EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
        {
            "title": "Retirement PLan",
            "url": "https://play.google.com/store/apps/details?id=com.invest.whatstool.retirementplan",
            "app_info": "/retirement-plan/",
            "logo": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/RetirementPlan_App/images/retirement_plan_logo.png",
            "bg_img": "https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/RetirementPlan_App/images/retirement_plan_bg.png",
            "desc": "Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you.",
            "rating": "4.7",
            "install": "10K+",
            "bg_color": "#E6F6F0"
        },
    ]
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            <div className="products">
                <h1 className="text-center mt-3 heading-text">WhatsTool App Store</h1>
                {
                    OurApps_Data.map((v, i) => {
                        return (
                            <>
                                <Container className="pt-5 pb-5" style={{}} key={i} fluid>
                                    <Row className="pt-5 pb-5 ms-3 me-3 shadow-lg" style={{ backgroundColor: v.bg_color, borderRadius: "15px" }}>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div>
                                                <img src={v.bg_img} alt={v.title} className="img-fluid w-100" />
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <Row className="mt-2">
                                                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                                                    <div>
                                                        <img src={v.logo} alt={v.title} className="img-fluid" width="150px" />
                                                    </div>
                                                </Col>
                                                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                                                    <div>
                                                        <h4>{v.title}</h4>
                                                        <p style={{ marginTop: "-6px", fontSize: "12px" }}><Link className="theme" to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank">WhatsTool Tech: Toolkit for Business &amp; WhatsAppTools</Link></p>
                                                        <span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i> {v.rating}</span>
                                                        <p className="theme-light" style={{ fontSize: "14px" }}>Installs: {v.install}</p>
                                                    </div>
                                                    <Row className="mt-2">
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                            <Link to={{ pathname: v.app_info }} target="_blank" className="btn btn-sm btn-white border-success text-success float-start">Read More</Link>
                                                        </Col>
                                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                            <Link to={{ pathname: v.url }} target="_blank" className="btn btn-sm btn-success float-end me-3">Install</Link>
                                                        </Col>
                                                    </Row>
                                                    <div className="clearfix"></div>
                                                </Col>
                                                <div className="mt-4 ps-3 pe-5">
                                                    <p className="desc-text" style={{ textAlign: "justify" }}>
                                                        {v.desc}
                                                    </p>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}
export const Premium = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <Container className="premium pb-5" fluid>
            <p className="text-center text-warning mt-5">
                <Player autoplay src="https://assets3.lottiefiles.com/packages/lf20_qzexyedo.json" className="items-lottie"></Player>
            </p>
            <h1 className="text-center mt-2 heading-text">WHATSTOOL PRO PRICING</h1>
            <h4 className="text-center sub-heading-text">Trusted by 2M+ users</h4>

            <PricingPlan />
            <hr />
            <div className="customer-support ps-5 pe-5">
                <h2 className="sub-heading-text"><i className="fas fa-check"></i> Customer Support on WhatsApp</h2>
                <p className="desc-text">All premium features are available for free trial.</p>
            </div>
            <hr />
            <Row className="chat-with-us ps-5 pe-5">
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                    <p className="desc-text">Facing problems in payment? Paid but not upgraded? Want to buy for more then 2 phones?</p>
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Link className="text-success float-end" to={{ pathname: "https://api.whatsapp.com/send?phone=919844344170&text=Hi !" }} target="_blank">CHAT WITH US</Link>
                </Col>
            </Row>
            <hr />
            <Row className="satisfaction ps-5 pe-5">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h2 className="sub-heading-text">Satisfaction &amp; Guarantee</h2>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}>
                    <img src={getUrl.imageBaseURL + "/media/100moneyback.png"} alt="Satisfaction and Guarantee - WhatsTool Tech" />
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={12} xs={12}>
                    <img src={getUrl.imageBaseURL + "/media/100satisfaction.png"} alt="Satisfaction and Guarantee - WhatsTool Tech" />
                </Col>
            </Row>
            <hr />
            <Row className="payment-help ps-5 pe-5">
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}><h2 className="sub-heading-text">Need any help in payment?</h2></Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={4} xs={4}>
                    <Link to="/faq/" className="btn btn-sm rounded-0 text-dark btn-success bg-white border-success">Read FAQs</Link>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={4} xs={4}>
                    <Link to={{ pathname: "https://api.whatsapp.com/send?phone=919844344170&text=Hi !" }} target="_blank" className="btn btn-sm rounded-0 text-dark btn-success bg-white">Chat with Us</Link>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={2} sm={4} xs={4}>
                    <Link to={{ pathname: "mailto:whatstoolhelp@gmail.com" }} target="_top" className="btn btn-sm rounded-0 text-dark btn-success bg-white">Email Us</Link>
                </Col>
            </Row>
        </Container>
    )
}
export const PricingPlan = () => {
    let usdVal = 82
    const mYrP_Inr = 1120
    const mLtP_Inr = 4599
    const cYrP_Inr = 2122
    const cLtP_Inr = 6488
    const dYrP_Inr = mYrP_Inr
    const dLtP_Inr = mLtP_Inr

    const mYrP_Usd = Math.floor(2000 / usdVal)
    const mLtP_Usd = Math.floor(9999 / usdVal)
    const cYrP_Usd = Math.floor(12000 / usdVal)
    const cLtP_Usd = Math.floor(12000 / usdVal)
    const dYrP_Usd = Math.floor(2000 / usdVal)
    const dLtP_Usd = Math.floor(9999 / usdVal)

    const selectCurrency = (e) => {
        if (e.target.value === "inr") {
            setMobYrPrice(mYrP_Inr)
            setMobLtPrice(mLtP_Inr)
            setMobCurType("₹")
            setMobCurr("mobInr")

            setComYrPrice(cYrP_Inr)
            setComLtPrice(cLtP_Inr)
            setComCurType("₹")
            setComCurr("comInr")

            setDesYrPrice(dYrP_Inr)
            setDesLtPrice(dLtP_Inr)
            setDesCurType("₹")
            setDesCurr("desInr")

            if (mobPlan === "mYr") {
                setMobFinalPrice(mYrP_Inr)
            }
            if (mobPlan === "mLt") {
                setMobFinalPrice(mLtP_Inr)
            }
            if (comPlan === "cYr") {
                setComFinalPrice(cYrP_Inr)
            }
            if (comPlan === "cLt") {
                setComFinalPrice(cLtP_Inr)
            }
            if (desPlan === "dYr") {
                setDesFinalPrice(dYrP_Inr)
            }
            if (desPlan === "dLt") {
                setDesFinalPrice(dLtP_Inr)
            }
        } else if (e.target.value === "usd") {
            setMobYrPrice(mYrP_Usd)
            setMobLtPrice(mLtP_Usd)
            setMobCurType("$")
            setMobCurr("mobUsd")

            setComYrPrice(cYrP_Usd)
            setComLtPrice(cLtP_Usd)
            setComCurType("$")
            setComCurr("comUsd")

            setDesYrPrice(dYrP_Usd)
            setDesLtPrice(dLtP_Usd)
            setDesCurType("$")
            setDesCurr("desUsd")

            if (mobPlan === "mYr") {
                setMobFinalPrice(mYrP_Usd)
            }
            if (mobPlan === "mLt") {
                setMobFinalPrice(mLtP_Usd)
            }
            if (comPlan === "cYr") {
                setComFinalPrice(cYrP_Usd)
            }
            if (comPlan === "cLt") {
                setComFinalPrice(cLtP_Usd)
            }
            if (desPlan === "dYr") {
                setDesFinalPrice(dYrP_Usd)
            }
            if (desPlan === "dLt") {
                setDesFinalPrice(dLtP_Usd)
            }
        }
    }

    const mobilePlan = (e) => {
        // alert(e.target.value)
        if (e.target.value === "mobile_yearly") {
            setMobPriceType("for one year")
            setMobPlan("mYr")
            if (mobCurr === "mobInr") {
                setMobFinalPrice(mYrP_Inr)
            } else if (mobCurr === "mobUsd") {
                setMobFinalPrice(mYrP_Usd)
            }
        } else if (e.target.value === "mobile_lifetime") {
            setMobPriceType("for lifetime")
            setMobPlan("mLt")
            if (mobCurr === "mobInr") {
                setMobFinalPrice(mLtP_Inr)
            } else if (mobCurr === "mobUsd") {
                setMobFinalPrice(mLtP_Usd)
            }
        }
    }
    const [mobCurr, setMobCurr] = useState("mobInr")
    const [mobPlan, setMobPlan] = useState("mYr")
    const [mobCurType, setMobCurType] = useState("₹")
    const [mobYrPrice, setMobYrPrice] = useState(mYrP_Inr)
    const [mobLtPrice, setMobLtPrice] = useState(mLtP_Inr)
    const [mobFinalPrice, setMobFinalPrice] = useState(mLtP_Inr)
    const [mobPriceType, setMobPriceType] = useState("for one year")

    const comboPlan = (e) => {
        // alert(e.target.value)
        if (e.target.value === "combo_yearly") {
            setComPriceType("for one year")
            setComPlan("cYr")
            if (comCurr === "comInr") {
                setComFinalPrice(cYrP_Inr)
            } else if (comCurr === "comUsd") {
                setComFinalPrice(cYrP_Usd)
            }
        } else if (e.target.value === "combo_lifetime") {
            setComPriceType("for lifetime ")
            setComPlan("cLt")
            if (comCurr === "comInr") {
                setComFinalPrice(cLtP_Inr)
            } else if (comCurr === "comUsd") {
                setComFinalPrice(cLtP_Usd)
            }
        }
    }
    const [comCurr, setComCurr] = useState("comInr")
    const [comPlan, setComPlan] = useState("cYr")
    const [comCurType, setComCurType] = useState("₹")
    const [comYrPrice, setComYrPrice] = useState(cYrP_Inr)
    const [comLtPrice, setComLtPrice] = useState(cLtP_Inr)
    const [comFinalPrice, setComFinalPrice] = useState(cLtP_Inr)
    const [comPriceType, setComPriceType] = useState("for one year")

    const desktopPlan = (e) => {
        // alert(e.target.value)
        if (e.target.value === "desktop_yearly") {
            setDesPriceType("for one year")
            setDesPlan("dYr")
            if (desCurr === "desInr") {
                setDesFinalPrice(dYrP_Inr)
            } else if (desCurr === "desUsd") {
                setDesFinalPrice(dYrP_Usd)
            }
        } else if (e.target.value === "desktop_lifetime") {
            setDesPriceType("for lifetime")
            setDesPlan("dLt")
            if (desCurr === "desInr") {
                setDesFinalPrice(dLtP_Inr)
            } else if (desCurr === "desUsd") {
                setDesFinalPrice(dLtP_Usd)
            }
        }
    }
    const [desCurr, setDesCurr] = useState("desInr")
    const [desPlan, setDesPlan] = useState("dYr")
    const [desCurType, setDesCurType] = useState("₹")
    const [desYrPrice, setDesYrPrice] = useState(dYrP_Inr)
    const [desLtPrice, setDesLtPrice] = useState(dLtP_Inr)
    const [desFinalPrice, setDesFinalPrice] = useState(dLtP_Inr)
    const [desPriceType, setDesPriceType] = useState("for one year")


    const [showM, setShowM] = useState(false);
    const handleCloseM = () => setShowM(false);
    const handleShowM = () => setShowM(true);

    const [showC, setShowC] = useState(false);
    const handleCloseC = () => setShowC(false);
    // const handleShowC = () => setShowC(true);

    const [showD, setShowD] = useState(false);
    const handleCloseD = () => setShowD(false);
    // const handleShowD = () => setShowD(true);
    return (
        <>
            <Container>
                <div className="mt-5 mb-5">
                    <p className="text-center">
                        <Form.Check inline label="Indian Rupee" name="currency" type="radio" defaultChecked={true} value="inr" id="inr" onChange={(e) => selectCurrency(e)} />
                        <Form.Check inline label="US Dollar" name="currency" type="radio" value="usd" id="usd" onChange={(e) => selectCurrency(e)} />
                    </p>
                </div>
                <Row className="pricing-plan ps-3 pe-3">
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mt-3">
                        <div className="essential-plan plan-grey">
                            <div className="mb-5 fw-bold">&nbsp;</div>
                            <h4 className="plan_name">MOBILE</h4>
                            <hr className="plan_name_line" />
                            <Form className="">
                                <div className="mb-5">
                                    <Form.Check type="radio" id="mobile_yearly" className="mt-3" >
                                        <Form.Check.Input type="radio" name="mobile_plan" value="mobile_yearly" onChange={(e) => mobilePlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Yearly</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mobCurType}<span style={{ fontSize: "30px" }}> {mobYrPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                    <Form.Check type="radio" id="mobile_lifetime" className="mt-3" >
                                        <Form.Check.Input type="radio" name="mobile_plan" defaultChecked={true} value="mobile_lifetime" onChange={(e) => mobilePlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Lifetime</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{mobCurType}<span style={{ fontSize: "30px" }}> {mobLtPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                </div>
                            </Form>
                            <hr className="plan_name_line" />
                            <div className="theme mt-3">Price: {mobCurType}<span style={{ fontSize: "40px" }}> {mobFinalPrice}</span></div>
                            <p className="text-center mt-4"><Button onClick={handleShowM} className="buyNowBtn btn btn-sm ps-5 pe-5 pt-2 pb-2 border-white">Buy now</Button></p>
                            <p className="mt-2 mb-3 text-center" style={{ fontSize: "10px" }}>{mobPriceType}</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mt-3">
                        <div className="premium-plan plan">
                            <div>
                                <Row className="">
                                    <Col className="offer-text2 mb-5 fw-bold">MEGA OFFER - 50% OFF</Col>
                                    <Col><span className="float-end theme recBg fw-bold pt-0 pb-0 pe-2 ps-2 rounded-pill" style={{ fontSize: "12px" }}>RECOMMENDED</span></Col>
                                </Row>
                            </div>
                            <h4 className="plan_name">COMBO: Mobile + Desktop</h4>
                            <hr className="plan_name_line" />
                            <Form className="">
                                <div className="mb-5">
                                    <Form.Check type="radio" id="combo_yearly" className="mt-3">
                                        <Form.Check.Input type="radio" name="combo_plan" value="combo_yearly" onChange={(e) => comboPlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Yearly</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{comCurType}<span style={{ fontSize: "30px" }}> {comYrPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                    <Form.Check type="radio" id="combo_lifetime" className="mt-3">
                                        <Form.Check.Input type="radio" name="combo_plan" defaultChecked={true} value="combo_lifetime" onChange={(e) => comboPlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Lifetime</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{comCurType}<span style={{ fontSize: "30px" }}> {comLtPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                </div>
                            </Form>
                            <hr className="plan_name_line" />
                            <div className="theme mt-3">Price: {comCurType}<span style={{ fontSize: "40px" }}> {comFinalPrice}</span></div>
                            <p className="text-center mt-4"><Link to={{ pathname: getUrl.wtdPaymentUrl }} target='_blank' className="buyNowBtn btn btn-sm ps-5 pe-5 pt-2 pb-2 border-white">Buy now</Link></p>
                            <p className="mb-3 mt-2 text-center" style={{ fontSize: "10px" }}>{comPriceType}</p>
                        </div>
                    </Col>
                    <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="mt-3">
                        <div className="essential-plan plan-grey">
                            <div className="mb-5 fw-bold">&nbsp;</div>
                            <h4 className="plan_name">DESKTOP</h4>
                            <hr className="plan_name_line" />
                            <Form className="">
                                <div className="mb-5">
                                    <Form.Check type="radio" id="desktop_yearly" className="mt-3">
                                        <Form.Check.Input type="radio" name="desktop_plan" value="desktop_yearly" onChange={(e) => desktopPlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Yearly</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desCurType}<span style={{ fontSize: "30px" }}> {desYrPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                    <Form.Check type="radio" id="desktop_lifetime" className="mt-3">
                                        <Form.Check.Input type="radio" name="desktop_plan" defaultChecked={true} value="desktop_lifetime" onChange={(e) => desktopPlan(e)} isValid />
                                        <Form.Check.Label>&nbsp;&nbsp;Lifetime</Form.Check.Label>
                                        <Form.Control.Feedback type="valid">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{desCurType}<span style={{ fontSize: "30px" }}> {desLtPrice}</span></Form.Control.Feedback>
                                    </Form.Check>
                                </div>
                            </Form>
                            <hr className="plan_name_line" />
                            <div className="theme mt-3">Price: {desCurType}<span style={{ fontSize: "40px" }}> {desFinalPrice}</span></div>
                            <p className="text-center mt-4"><Link to={{ pathname: getUrl.wtdPaymentUrl }} target='_blank' className="buyNowBtn btn btn-sm ps-5 pe-5 pt-2 pb-2 border-white">Buy now</Link></p>
                            <p className="mb-3 mt-2 text-center" style={{ fontSize: "10px" }}>{desPriceType}</p>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    {/* <p className="text-center" onClick={handleShowO}><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/offer_for_app_new.png"} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-3 mt-2" /></p> */}
                    {/* <p className="text-center" onClick={() => openDialogBox()}><img src={getUrl.bannerimgUrl} width="350px" alt="WhatsTool Tech" className="img-fluid rounded-3 mt-2" /></p> */}
                </div>
                <div className="mt-5 mb-5 pb-5">
                    <p className="text-center">
                        <Link className="wbtn" to="/how-to-get-activate-whatstool-desktop">How to activate get WhatsTool Desktop</Link>
                    </p>
                </div>
            </Container>
            <Modal className="modal-info-bulk-msg" show={showM} onHide={handleCloseM} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        <h3 className="theme text-center mt-5 ">Pay {mobCurType}{mobFinalPrice} for Mobile through</h3>
                        <p className="text-center theme mb-5">{mobPriceType}</p>
                        <p className="theme text-center mb-2">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>
                        {/* <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL+"/stripe_icon_full.svg"} width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link> */}
                        <div>
                            <hr />
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            {/* <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL+"/stripe_icon_full.svg"} width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link> */}
                            <Link to={{ pathname: "/wt-payment-method" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/Stripe_pay.svg"} width="200px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal className="modal-info-bulk-msg" show={showC} onHide={handleCloseC} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        <h3 className="theme text-center mt-5 ">Pay {comCurType}{comFinalPrice} for Combo through</h3>
                        <p className="text-center theme mb-5">{comPriceType}</p>
                        <p className="theme text-center mb-2">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>

                        <div style={{ display: "none" }}>
                            <hr />
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal className="modal-info-bulk-msg" show={showD} onHide={handleCloseD} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <Container className="mb-5 pb-5">
                        <h3 className="theme text-center mt-5 ">Pay {desCurType}{desFinalPrice} for Desktop through</h3>
                        <p className="text-center theme mb-5">{desPriceType}</p>
                        <p className="theme text-center mb-2">for Indian Users</p>
                        <Link to={{ pathname: "https://pages.razorpay.com/whatstool-pro" }} target="_blank"><p className="text-center"><img src={getUrl.imageBaseURL + "/media/rozarpay_09.png"} width="250px" className="img-fluid shadow-lg rounded-2" alt="Razorpay - WhatsTool Tech" /></p></Link>

                        <div style={{ display: "none" }}>
                            <hr />
                            <p className="theme text-center mb-2 mt-5">for Other Countries</p>
                            <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank"><p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/paypal.png" width="250px" className="img-fluid shadow-lg rounded-2" alt="PayPal - WhatsTool Tech" /></p></Link>
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
export const DesktopLicenseKey = () => {
    return (
        <Container className="premium pb-5">
            <p className="text-center text-warning mt-2">
                <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/key.json" className="items-lottie" ></Player>
            </p>
            <h1 className='heading-text'>How to Get activate WhatsTool Desktop?</h1>
            <ul className="desc-text">
                <li>Firstly you've to make Payment.</li>
                <li>Send payment proof to our Customer Care: via <span className="q_left border ps-2 pe-2 rounded-pill me-2 fw-bold hand"><Link className="theme" to={{ pathname: "https://api.whatsapp.com/send?phone=+919031239260" }} target="_blank">WhatsApp</Link></span> or <span className="q_left border ps-2 pe-2 rounded-pill me-2 hand fw-bold"><Link to={{ pathname: "mailto:whatstoolsapp@gmail.com" }} target="_blank" className="emailColor">Email</Link></span></li>
                <li>And we'll activate it on your email.</li>
            </ul>
        </Container>
    )
}
export const PaymentSuccessful = () => {
    return (
        <Container className="premium pb-5 mb-5 mt-5">
            <p className="text-center text-warning mt-5">
                <Player autoplay loop src="https://assets10.lottiefiles.com/packages/lf20_jbrw3hcz.json" className="items-lottie" ></Player>
            </p>
            <h1 className="heading-text text-center theme">Payment Successful</h1>
            <p className="desc-text mt-5 text-center" style={{ lineHeight: "2" }}>Contact our Customer Care for activation: via <br /> <span className="q_left border ps-2 pe-2 pb-1 pt-1 rounded-pill me-2 fw-bold hand"><Link className="theme" to={{ pathname: "https://api.whatsapp.com/send?phone=+919031239260" }} target="_blank">WhatsApp</Link></span> or &nbsp; <span className="q_left border ps-2 pe-2 pb-1 pt-1 rounded-pill me-2 hand fw-bold"><Link to={{ pathname: "mailto:whatstoolsapp@gmail.com" }} target="_blank" className="emailColor">Email</Link></span></p>
        </Container>
    )
}
export const PrivacyPolicy = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div className="privacy-policy container-fluid p-4">
            <h1 className="text-center heading-text mt-2 mb-3">Privacy Policy</h1>
            <h4>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h4>
            When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.<br />
            When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.<br />
            Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.<br />
            <br /><br />
            <h4>SECTION 2 - CONSENT</h4>
            How do you get my consent?<br />
            When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.<br />
            If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.<br />
            <br /><br />
            <h4>How do I withdraw my consent?</h4>
            If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at whatstoolsapp@gmail.com or mailing us at: C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
            <br /><br />
            <h4>SECTION 3 - DISCLOSURE</h4>
            We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.<br />
            <br /><br />
            <h4>SECTION 4 - PAYMENT</h4>
            We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.<br />
            Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.<br />
            PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.<br />
            For more insight, you may also want to read terms and conditions of razorpay on https://razorpay.com<br />
            <br /><br />
            <h4>SECTION 5 - THIRD-PARTY SERVICES</h4>
            In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.<br />
            However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.<br />
            For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.<br />
            In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.<br />
            Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service. <br />
            <br /><br />
            <h5>Links</h5>
            When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.<br />
            <br /><br />
            <h4>SECTION 6 - SECURITY</h4>
            To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.<br />
            <br /><br />
            <h4>SECTION 7 - COOKIES</h4>
            We use cookies to maintain session of your user. It is not used to personally identify you on other websites.<br />
            <br /><br />
            <h4>SECTION 8 - AGE OF CONSENT</h4>
            By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br />
            <br /><br />
            <h4>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h4>
            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.<br />
            If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.<br />
            <br /><br />
            <h4>QUESTIONS AND CONTACT INFORMATION</h4>
            If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at whatstoolsapp@gmail.com or by mail at C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India.<br />
            <br /><br />
            [Re: Privacy Compliance Officer]<br />
            [C Cross Rd, Avalappa Layout, Muniyappa Layout, Murgesh Pallya, Bengaluru, Karnataka, India. 560017]<br />
            <br /><br />
        </div>
    )
}
export const TermsNCondition = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div className="privacy-policy container-fluid p-4">
            <h1 className="text-center heading-text mt-2 mb-3">Terms &amp; Conditions</h1>
            <h4>OVERVIEW</h4>
            This website is operated by WhatsTool Tech. Throughout the site, the terms “we”, “us” and “our” refer to WhatsTool Tech. WhatsTool Tech offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.<br /><br />
            By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply  to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.<br /><br />
            Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.<br /><br />
            Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.<br /><br />
            <br /><br />
            <h4>SECTION 1 - ONLINE STORE TERMS</h4>
            By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.<br />
            You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).<br />
            You must not transmit any worms or viruses or any code of a destructive nature.<br />
            A breach or violation of any of the Terms will result in an immediate termination of your Services.<br />
            <br /><br />
            <h4>SECTION 2 - GENERAL CONDITIONS</h4>
            We reserve the right to refuse service to anyone for any reason at any time.<br />
            You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.<br />
            You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.<br />
            The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.<br />
            <br /><br />
            <h4>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h4>
            We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.<br />
            This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.<br />
            <br /><br />
            <h4>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h4>
            Prices for our products are subject to change without notice.<br />
            We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.<br />
            We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.<br />
            <br /><br />
            <h4>SECTION 5 - PRODUCTS OR SERVICES</h4>
            Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.<br />
            We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.<br />
            We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.<br />
            We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.<br />
            <br /><br />
            <h4>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h4>
            We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.<br />
            You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.<br />
            For more detail, please review our Returns Policy.<br />
            <br /><br />
            <h4>SECTION 7 - OPTIONAL TOOLS</h4>
            We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.<br />
            You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.<br />
            Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).<br />
            We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.<br />
            <br /><br />
            <h4>SECTION 8 - THIRD-PARTY LINKS</h4>
            Certain content, products and services available via our Service may include materials from third-parties.<br />
            Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.<br />
            We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.<br />
            <br /><br />
            <h4>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h4>
            If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.<br />
            We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.<br />
            You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.<br />
            <br /><br />
            <h4>SECTION 10 - PERSONAL INFORMATION</h4>
            Your submission of personal information through the store is governed by our Privacy Policy. <br />
            <br /><br />
            <h4>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h4>
            Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).<br />
            We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.<br />
            <br /><br />
            <h4>SECTION 12 - PROHIBITED USES</h4>
            In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.<br />
            <br /><br />
            <h4>SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h4>
            We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.<br />
            We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.<br />
            You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.<br />
            You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.<br />
            In no case shall WhatsTool Tech, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.<br />
            <br /><br />
            <h4>SECTION 14 - INDEMNIFICATION</h4>
            You agree to indemnify, defend and hold harmless WhatsTool Tech and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.<br />
            <br /><br />
            <h4>SECTION 15 - SEVERABILITY</h4>
            In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.<br />
            <br /><br />
            <h4>SECTION 16 - TERMINATION</h4>
            The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.<br />
            These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.<br />
            If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).<br />
            <br /><br />
            <h4>SECTION 17 - ENTIRE AGREEMENT</h4>
            The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.<br />
            These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).<br />
            Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.<br />
            <br /><br />
            <h4>SECTION 18 - GOVERNING LAW</h4>
            These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of India and jurisdiction of Jaipur, Rajasthan<br />
            <br /><br />
            <h4>SECTION 19 - CHANGES TO TERMS OF SERVICE</h4>
            You can review the most current version of the Terms of Service at any time at this page.<br />
            We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.<br />
            <br /><br />
            <h4>SECTION 20 - CONTACT INFORMATION</h4>
            Questions about the Terms of Service should be sent to us at whatstoolsapp@gmail.com.<br />
            <br /><br />
        </div>
    )
}
export const RefundPolicy = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <div className="privacy-policy container-fluid p-4">
            <h1 className="text-center heading-text mt-2 mb-3">Refund Policy</h1>
            <h3>Returns</h3>
            Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.<br />
            To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.<br />
            Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.<br />
            <br /><br />
            <h4>Additional non-returnable items:</h4>
            <ul>
                <li>Gift cards</li>
                <li>Downloadable software products</li>
                <li>Some health and personal care items</li>
            </ul>
            <br />
            To complete your return, we require a receipt or proof of purchase.<br />
            There are certain situations where only partial refunds are granted: (if applicable)<br />
            Any item that is returned more than 30 days after delivery<br />
            <br /><br />
            <h4>Refunds (if applicable)</h4>
            Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.<br />
            If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days. <br />
            <br /><br />
            <h4>Late or missing refunds (if applicable)</h4>
            If you haven’t received a refund yet, first check your bank account again.<br />
            Then contact your credit card company, it may take some time before your refund is officially posted.<br />
            Next contact your bank. There is often some processing time before a refund is posted.<br />
            If you’ve done all of this and you still have not received your refund yet, please contact us at whatstoolsapp@gmail.com.<br />
            <br /><br />
            <h4>Sale items (if applicable)</h4>
            Only regular priced items may be refunded, unfortunately sale items cannot be refunded.<br />
            <br /><br />
        </div>
    )
}
export const Team = () => {
    const [item, setItem] = useState([])
    const getItems = async () => {
        const response = await fetch('https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/team.json')
        setItem(await response.json())
    }
    useEffect(() => {
        getItems()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <div className="team container-fluid mb-5 pb-5">
            <div className="bg_image">
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/team/bg_team.jpeg" alt="Core Team - WhatsTool Tech" />
            </div>
            <div className="t_heading">
                <h1>Our Team</h1>
            </div>
            <div className="ceo_image">
                <div className="profile-img">
                    <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/team/ProfileSujeet.jpeg" alt="CEO/Founder of WhatsTool Tech" /></p>
                </div>
                <div className="profile-name mt-2">
                    <p className="text-center">Founder, CEO</p>
                    <h5 className="text-center">Sujeet Kr. Mehta</h5>
                </div>
                <div className="profile-social-link">
                    <p className="text-center">
                        <Link to={{ pathname: "https://twitter.com/Sujeet05462752" }} target="_blank"><i className="fab fa-twitter p-1 m-1"></i></Link>
                        <Link to={{ pathname: "https://www.linkedin.com/in/sujeetkumarmehta/" }} target="_blank"><i className="fab fa-linkedin p-1 m-1"></i></Link>
                    </p>
                </div>
            </div>

            <div className="clearfix"></div>

            <div className="row team_mates mt-5">
                {
                    item.map((el) => {
                        return (
                            <div className="mb-5 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <div className="profile-img">
                                    <p className="text-center"><img src={el.image_url} alt="WhatsTool Team" /></p>
                                </div>
                                <div className="profile-name mt-2">
                                    <p className="text-center">{el.designation}</p>
                                    <h5 className="text-center">{el.name}</h5>
                                </div>
                                <div className="profile-social-link">
                                    <p className="text-center">
                                        {(el.sm_facebook.trim() === "") ? ("") : (<Link to={{ pathname: el.sm_facebook }} target="_blank"><i className="fab fa-facebook-square p-1 m-1"></i></Link>)}
                                        {(el.sm_instagram.trim() === "") ? ("") : (<Link to={{ pathname: el.sm_instagram }} target="_blank"><i className="fab fa-instagram p-1 m-1"></i></Link>)}
                                        {(el.sm_twitter.trim() === "") ? ("") : (<Link to={{ pathname: el.sm_twitter }} target="_blank"><i className="fab fa-twitter p-1 m-1"></i></Link>)}
                                        {(el.sm_linkedln.trim() === "") ? ("") : (<Link to={{ pathname: el.sm_linkedln }} target="_blank"><i className="fab fa-linkedin p-1 m-1"></i></Link>)}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="images-gallery container">
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/gallery/01.jpeg" alt="Office Images of WhatsTool Tech" />
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/gallery/02.jpeg" alt="Office Images of WhatsTool Tech" />
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/gallery/03.jpeg" alt="Office Images of WhatsTool Tech" />
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/gallery/04.jpeg" alt="Office Images of WhatsTool Tech" />
                <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/gallery/05.jpeg" alt="Office Images of WhatsTool Tech" />
            </div>
        </div>
    )
}
export const ResellerProgram = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 850px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 800px)' })
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <>
            {
                isDesktopOrLaptop &&
                <>
                    <Container className="mb-5 pb-5 pe-0 ps-0" fluid>
                        <div className="">
                            <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_01_d.png"} className="w-100 img-fluid" alt="Reseller Program - WhatsTool Tech" />
                        </div>
                    </Container>
                    <Container className="pb-5" fluid>

                        <Container className="mb-5 pb-5">
                            <Row>
                                <Col>
                                    <div>
                                        <p style={{ fontSize: "20px" }} className="mt-3">
                                            You just need to find a potential buyer of WhatsTool Premium. If they buy a Premium Plan, you can earn straight commission from it.
                                        </p>
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_02.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                                            <p className="text-center fw-bold mt-3" style={{ color: "#3FA379", fontSize: "20px" }}>Work from<br />phone</p>
                                        </Col>
                                        <Col>
                                            <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_03.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                                            <p className="text-center fw-bold mt-3" style={{ color: "#3FA379", fontSize: "20px" }}>Upto ₹30,000<br />per month</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <Row>
                                <Col>
                                    <div>
                                        <p className="text-center mt-5 mb-5"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_04.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="pt-3">
                                        <h2 style={{ fontSize: "51px", color: "#89BE7B" }} className="text-center mt-5 pt-5">Live Your Dreams!<br />Be Bold &amp; Independent</h2>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <Row>
                                <Col><h5 className="text-center mt-4 mb-4" style={{ color: "#3FA391" }}>Start earning Today</h5></Col>
                                <Col><p className="text-center mt-3 mb-3"><h5 className="p-3 rounded-pill text-black" style={{ backgroundColor: "#A2E3B5", border: "1px solid #A2E3B5" }}>Without any <b>Investment</b></h5></p></Col>
                                <Col><p className="text-center mt-3 mb-3"><h5 className="p-3 rounded-pill text-white" style={{ backgroundColor: "#21432B", border: "1px solid #21432B" }}>Without any Limit</h5></p></Col>
                            </Row>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <p className="text-center mt-4 video">
                                <iframe width="60%" height="375px" src="https://www.youtube-nocookie.com/embed/mFXZCHxe4yA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </p>
                            <h5 className="text-center mb-5" style={{ fontSize: "20px", color: "#000", opacity: "0.8" }}>Learn about reseller benefits</h5>
                        </Container>
                    </Container>
                </>
            }
            {
                isTabletOrMobile &&
                <>
                    <Container className="pb-5 pe-0 ps-0" fluid>
                        <Container className="m-0 p-0" fluid>
                            <div className="">
                                <img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_01.png"} className="w-100 img-fluid" alt="Reseller Program - WhatsTool Tech" />
                            </div>
                        </Container>
                        <Container className="mt-5 mb-5 pb-5 ps-4 pe-4">
                            <div>
                                <h2>How does reselling work?</h2>
                                <p style={{ fontSize: "20px" }} className="mt-3">
                                    You just need to find a potential buyer of WhatsTool Premium. If they buy a Premium Plan, you can earn straight commission from it.
                                </p>
                            </div>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <Row>
                                <Col>
                                    <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_02.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                                    <p className="text-center fw-bold mt-3" style={{ color: "#3FA379", fontSize: "20px" }}>Work from<br />phone</p>
                                </Col>
                                <Col>
                                    <p className="text-center"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_03.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                                    <p className="text-center fw-bold mt-3" style={{ color: "#3FA379", fontSize: "20px" }}>Upto ₹30,000<br />per month</p>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="ps-4 pe-4">
                            <div>
                                <h2>Growing small businesses across India</h2>
                                <p style={{ fontSize: "20px" }} className="mt-3">
                                    Our top Resellers come from all walks of life, from cities and towns.
                                </p>
                            </div>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <div>
                                <p className="text-center mt-5"><img src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/reseller/reseller_04.png"} className="img-fluid" alt="Reseller Program - WhatsTool Tech" /></p>
                            </div>
                            <div className="">
                                <h2 style={{ fontSize: "24px", color: "#89BE7B" }} className="text-center">Come follow your dreams!<br />Join us with just a click</h2>
                            </div>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <div><h5 className="text-center mt-4" style={{ color: "#3FA391" }}>Start earning Today</h5></div>
                            <div><p className="text-center mt-3"><h5 className="p-3 rounded-pill text-black" style={{ backgroundColor: "#A2E3B5", border: "1px solid #A2E3B5" }}>Without any <b>Investment</b></h5></p></div>
                            <div><p className="text-center mt-3 mb-3"><h5 className="p-3 rounded-pill text-white" style={{ backgroundColor: "#21432B", border: "1px solid #21432B" }}>Without any Limit</h5></p></div>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <p className="text-center mt-4 video">
                                <iframe width="60%" height="340px" src="https://www.youtube-nocookie.com/embed/mFXZCHxe4yA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </p>
                            <h5 className="text-center mt-2" style={{ fontSize: "20px", color: "#000", opacity: "0.8" }}>Learn about reseller benefits</h5>
                        </Container>
                        <Container className="mb-5 pb-5">
                            <div><h5 className="text-center mt-4" style={{ color: "#3FA391" }}>Get Paid Instantly</h5></div>
                            <div className="ps-5 pe-5"><p className="text-center mt-3"><h5 className="p-3 rounded-pill text-white" style={{ backgroundColor: "#72D670", border: "1px solid #A2E3B5" }}><b>Join Now</b></h5></p></div>
                        </Container>
                    </Container>
                </>
            }
        </>
    )
}
export const UpgradeInfo = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <div className="upgrade">
            <div className="bg_03 container-fluid pt-4 pb-4">
                <h1 className="text-center heading-text">New Update v3 of WhatsTool </h1>
                <Player autoplay loop src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/lottie/upgrade.json" className="items-lottie" ></Player>
            </div>

            <div className="bg_01 container-fluid pb-5">
                <h2 className="p-4">Faster and lighter app.</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            {/* <p className="text-center"><img src={} alt="Faster and lighter app | Upgrade Information | WhatsTool Tech" /></p> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_02 container-fluid pb-5">
                <h2 className="p-4">Personalised Home Screen</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_home.png" alt="Personalised Home Screen | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_03 container-fluid pb-5">
                <h2 className="p-4">Full Status in Home Screen</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_status.png" alt="Full Status in Home Screen | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_04 container-fluid pb-5">
                <h2 className="p-4">Search of the tools</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_search.jpeg" alt="Search of the tools | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_05 container-fluid pb-5">
                <h2 className="p-4">Login Mandatory for Premium Content and Buying Premium</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            {/* <p className="text-center"><img src={} alt="Login Mandatory for Premium Content and Buying Premium | Upgrade Information | WhatsTool Tech" /></p> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_06 container-fluid pb-5">
                <h2 className="p-4">Profile Setup for Business</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_business.jpeg" alt="Profile Setup for Business | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_07 container-fluid pb-5">
                <h2 className="p-4">Profile Page and control</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_profile_page_control.png" alt="Profile Page and control | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_08 container-fluid pb-5">
                <h2 className="p-4">Security Upgrade</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            {/* <p className="text-center"><img src={} alt="Security Upgrade | Upgrade Information | WhatsTool Tech" /></p> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_09 container-fluid pb-5">
                <h2 className="p-4">New Onboarding flow</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_onboarding.png" alt="New Onboarding flow | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_10 container-fluid pb-5">
                <h2 className="p-4">Manage contacts, groups and imports</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_manage_contact.jpeg" alt="Manage contacts, groups and imports | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_11 container-fluid pb-5">
                <h2 className="p-4">Selection of Contacts Changes</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            {/* <p className="text-center"><img src={} alt="Selection of Contacts Changes | Upgrade Information | WhatsTool Tech" /></p> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_12 container-fluid pb-5">
                <h2 className="p-4">Add any contact number in Select Contact Screen</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_select_contact.jpeg" alt="Add any contact number in Select Contact Screen | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_13 container-fluid pb-5">
                <h2 className="p-4">Template</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_template.jpeg" alt="Template | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_14 container-fluid pb-5">
                <h2 className="p-4">Contextual CTA in Home Screen</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            {/* <p className="text-center"><img src={} alt="Contextual CTA in Home Screen | Upgrade Information | WhatsTool Tech" /></p> */}
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg_15 container-fluid pb-5">
                <h2 className="p-4">Learn Video Section</h2>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div>
                            <p className="text-center"><img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/web_img/upgrade-info/new_video.png" alt="Learn Video Section | Upgrade Information | WhatsTool Tech" /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const PaymentMethod = () => {
    useEffect(() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) }, [])
    return (
        <Container className="premium pb-5">
            <h1 className="mt-5" style={{ color: "#138073" }}>Payment for WhatsTool Premium Plan </h1>
            <p>Now you can pay us through</p>
            <ol className="fw-bold">
                <li>Credit/Debit card</li>
                <li>PayPal</li>
            </ol>
            <Row className="mt-5">
                <Col xxl={4} xl={4} lg={4} md={4} sm={6} xs={6}>
                    <h4 style={{ color: "#635BFF" }} className="mt-2">Pay using Credit/Debit Card</h4>
                    <img alt={''} src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/pb_stripe.png"} width="100px" />
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={6} xs={6}><img alt={''} src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/stripe.png"} width="200px" /></Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                        <h4 className="fw-bold">WhatsTool Premium</h4>
                        <h6 className="fw-bold text-muted">Yearly</h6>
                        <Link to={{ pathname: "https://buy.stripe.com/6oE8wTfpt7ojcww4gh" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                    </div>
                </Col>
                <Col>
                    <div className="rounded-3 p-3" style={{ border: "1px solid #635BFF" }}>
                        <h4 className="fw-bold">WhatsTool Premium</h4>
                        <h6 className="fw-bold text-muted">Lifetime</h6>
                        <Link to={{ pathname: "https://buy.stripe.com/aEUbJ50uz0ZV0NO5km" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#635BFF", border: "1px solid #635BFF" }}>Buy Now</Link>
                    </div>
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>

            <div className="mt-5">
                <h4 className="fw-bold" style={{ color: "#263A80" }}>Pay using PayPal</h4>
                <img alt={''} src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/paypal.png"} width="80px" />
            </div>
            <Row className="mt-5">
                <Col>
                    <div className="rounded-3 p-3" style={{ border: "1px solid #263A80" }}>
                        <h4 className="fw-bold">WhatsTool Premium</h4>
                        <h6 className="fw-bold text-muted">Yearly</h6>
                        <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#263A80", border: "1px solid #263A80" }}>Buy Now</Link>
                    </div>
                </Col>
                <Col>
                    <div className="rounded-3 p-3" style={{ border: "1px solid #263A80" }}>
                        <h4 className="fw-bold">WhatsTool Premium</h4>
                        <h6 className="fw-bold text-muted">Lifetime</h6>
                        <Link to={{ pathname: "https://www.paypal.com/paypalme/WhatsToolTech" }} target="_blank" className="btn btn-md text-white text-center fw-bold" style={{ backgroundColor: "#263A80", border: "1px solid #263A80" }}>Buy Now</Link>
                    </div>
                </Col>
                <Col></Col>
                <Col></Col>
            </Row>

            <h4 className="mt-3 mb-5" style={{ fontSize: "18px", color: "#4190EF" }}>Please make US Dollar equivalent payment as follows: (Gold Plan: $66, Premium Plan: $13.19)</h4>
            <br />
            <br />
            <h4 className="fw-bold mt-5 " style={{ color: "#138073" }}>How to activate WhatsTool Premium?</h4>
            <h5 style={{ fontSize: "18px" }}>Send us the screenshot of the payment and email id at <Link to={{ pathname: "mailto:whatstoolsapp@gmail.com" }}>whatstoolsapp@gmail.com</Link> or WhatsApp us at <Link to={{ pathname: "https://api.whatsapp.com/send?phone=+919031239260&text=Hi, WhatsTool Tech Team !" }}>919031239260</Link>. We will verify and upgrade your account to the plan you purchased.</h5>

            <img alt={''} src={"https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/whatstool/trust.png"} className="img-fluid mt-5 mb-5" width="90%" />
        </Container>
    )
}



// Apps Pages
export const InstagramTool = () => {
    return (
        <>
            WhatsTool Im. for Instagram.
        </>
    )
}
export const SipCalc = () => {
    return (
        <Container className="pb-5 mb-5">
            <Row className="mt-2">
                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                    <div>
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_logo.png" alt="WhatsTool SIP Calculator" className="img-fluid" width="150px" />
                    </div>
                </Col>
                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div>
                        <h4>WhatsTool SIP Calculator</h4>
                        <p style={{ marginTop: "-6px", fontSize: "12px" }}><Link className="theme" to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank">WhatsTool Tech: Toolkit for Business &amp; WhatsAppTools</Link></p>
                        <span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i> 4.6</span>
                        <p className="theme-light" style={{ fontSize: "14px" }}>Installs: 10k</p>
                    </div>
                    <Row className="mt-2">
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.invest.whatstool.sipcalculator" }} target="_blank" className="btn btn-sm btn-success me-3">Install</Link>
                        </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Col>
                <div className="mt-4 ps-3 pe-5 pb-5">
                    <p className="desc-text" style={{ textAlign: "justify" }}>
                        This easy SIP calculator helps you plan your SIP investments. With SIP calculator app you can see estimated gain across different mutual fund categories. You can see both SIP returns as well as one-time (lump sum) returns.
                    </p>
                </div>
                <Row>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_01.png" className="img-fluid" alt="WhatsTool SIP Calculator" />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_02.png" className="img-fluid" alt="WhatsTool SIP Calculator" />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_03.png" className="img-fluid" alt="WhatsTool SIP Calculator" />
                    </Col>
                    <Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6} className="mb-4">
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/sip_calc/sip_04.png" className="img-fluid" alt="WhatsTool SIP Calculator" />
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}
export const EmiCalc = () => {
    return (
        <Container className="pb-5 mb-5">
            <Row className="mt-2">
                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                    <div>
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_logo.png" alt="WhatsTool EMI Calculator" className="img-fluid" width="150px" />
                    </div>
                </Col>
                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div>
                        <h4>WhatsTool EMI Calculator</h4>
                        <p style={{ marginTop: "-6px", fontSize: "12px" }}><Link className="theme" to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank">WhatsTool Tech: Toolkit for Business &amp; WhatsAppTools</Link></p>
                        <span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i> 4.6</span>
                        <p className="theme-light" style={{ fontSize: "14px" }}>Installs: 10k</p>
                    </div>
                    <Row className="mt-2">
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.invest.whatstool.emicalculator" }} target="_blank" className="btn btn-sm btn-success me-3">Install</Link>
                        </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Col>
                <div className="mt-4 ps-3 pe-5 pb-5">
                    <p className="desc-text" style={{ textAlign: "justify" }}>
                        EMI Calculator is a simple loan calculation tool that helps the user to quickly calculate EMI and view payment schedules. Use this Loan EMI Calculator to calculate your EMI (Equated Monthly Instalment), plan your loan repayment in an effective way. You can also easy to compare two loans by using Loan EMI Calculator.
                    </p>
                </div>
                <div className="mb-4">
                    <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_screenshots.png" className="img-fluid" alt="WhatsTool SIP Calculator" />
                </div>
            </Row>
        </Container>
    )
}
export const RetirmentPlan = () => {
    return (
        <Container className="pb-5 mb-5">
            <Row className="mt-2">
                <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>
                    <div>
                        <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/RetirementPlan_App/images/retirement_plan_logo.png" alt="Retirement Plan" className="img-fluid" width="150px" />
                    </div>
                </Col>
                <Col xxl={10} xl={10} lg={10} md={10} sm={10} xs={10}>
                    <div>
                        <h4>Retirement Plan</h4>
                        <p style={{ marginTop: "-6px", fontSize: "12px" }}><Link className="theme" to={{ pathname: "https://play.google.com/store/apps/developer?id=WhatsTool+Tech:+Toolkit+for+Business+%26+WhatsApp" }} target="_blank">WhatsTool Tech: Toolkit for Business &amp; WhatsAppTools</Link></p>
                        <span className="text-warning"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i> 4.6</span>
                        <p className="theme-light" style={{ fontSize: "14px" }}>Installs: 10k</p>
                    </div>
                    <Row className="mt-2">
                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                            <Link to={{ pathname: "https://play.google.com/store/apps/details?id=com.invest.whatstool.emicalculator" }} target="_blank" className="btn btn-sm btn-success me-3">Install</Link>
                        </Col>
                    </Row>
                    <div className="clearfix"></div>
                </Col>
                <div className="mt-4 ps-3 pe-5 pb-5">
                    <p className="desc-text" style={{ textAlign: "justify" }}>
                        Want to Retire before 30? Retirement is when you don't have to work for money. So many people often ask me how they can retire, specifically before 30. But retirement may not be what you think it is! The way we see it, retirement is more about mindset. We'll look at monthly expenses for now and the future, how to account for your needs and your wants, what the inflation-adjusted rate would be, and how you can grow your investments! When you work on these with discipline, I can’t wait to see what the future holds for you.
                    </p>
                </div>
                <div className="mb-4 video">
                    {/* <img src="https://raw.githubusercontent.com/love-building-app/letsserve/master/prod/home/react-native-app/emi_calc/emi_screenshots.png" className="img-fluid" alt="Retirement Plan" /> */}
                    <iframe width="100%" height="700" src="https://www.youtube-nocookie.com/embed/1gWwlIgua2g?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Row>
        </Container>
    )
}
// End of Apps Pages



// FAQ Pages
export const Faq11 = () => {
    return (
        <>
            <Container className="faq pt-4 pb-4">
                <h1>How to enable Accessibility Service for WhatsTool to start sending Automatically?</h1>
                <div className="mt-3">
                    Follow the below steps to enable Accessibility Service for WhatsTool to start sending Automatically:<ul><li>Go to Setting of the phone</li><li>Search for “Accessibility Service”</li><li>You will see a screen like below</li><li>Under the Downloaded Service, you will see the WhatsTool app icon</li><li>Click on that and you will see the below screen where you have to turn it on</li><li>Now you need to turn on both the WhatsTool Service.</li></ul><p>Now your Accessibility services are enabled</p>
                </div>
            </Container>
        </>
    )
}
// End of FAQ Pages